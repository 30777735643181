import { createSlice } from "@reduxjs/toolkit";
import reduxApis from "../apis";
import { toast } from "react-toastify";


const userSlice = createSlice({
    name: "user",
    initialState: {
        loading: false,
        user: null,
        error: null,
        user_analytics: null,
        notifications: [],
        referral: [],
        totalUnseenNotifications: 0,
        referralTotal:0,
        userDropDown:false
    },
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload
        },
        logout: (state, action) => {
            state.user = null
            state.error = null
            state.user_analytics = null
            state.loading = false
            state.notifications = []
            state.totalUnseenNotifications = 0
            state.userDropDown =false
            state.referralTotal =0
            state.referral = []
        },
        setUserType: (state, action) => {
            state.user.type = action.payload
        },
        setUserInvestment: (state, action) => {
            state.user_analytics = action.payload
        },
        setUserReferrals: (state, action) => {
            state.referral = action.payload
        },
        setTotalReferrals: (state, action) => {
            state.referralTotal = action.payload
        },
        
        setUserNotifications: (state, action) => {
            state.notifications = action.payload
        },
        setTotalUnseenNotifications: (state, action) => {
            state.totalUnseenNotifications = action.payload
        },
        setUserShowDropdown : (state, action) => {
            state.userDropDown = action.payload
        },
    },
    extraReducers: {
        [reduxApis.userLogin.pending]: (state) => {
            state.loading = true;
        },
        [reduxApis.userLogin.fulfilled]: (state, action) => {
            const message = action.payload.data.message;
            action.payload.data.status
                ? toast.success(message)
                : toast.error(message)

            state.loading = false;
            state.user = action.payload.data.user;
            state.user_analytics = action.payload.data.user_analytics;
        },
        [reduxApis.userLogin.rejected]: (state, action) => {
            toast.error(action.error.message);
            state.loading = false;
            state.error = action.error.message;
        },
    },
});


export const { setTotalReferrals,logout, setUser, setUserType, setUserInvestment, setUserNotifications, setTotalUnseenNotifications ,setUserShowDropdown ,setUserReferrals} = userSlice.actions;
export default userSlice.reducer;
