
import { useTranslation } from "react-i18next";
import moment from "moment/moment";

const DividendTable = (transactions = []) => {
    const { language, setLanguage, t } = useTranslation();
   
    const columns = [
        {field: 'id', headerName: 'No', width: 200, hide: false,},
        {field: 'startDate', headerName: t("fields.startDate"), width: 200},
        {field: 'endDate', headerName: t("fields.endDate"), width: 200},
        {field: 'dividend', headerName: t("fields.dividends"), width: 200},
        {field: 'totalUser', headerName: t("fields.totalUser"), width: 200}, 
    ];

    const rows = transactions.length === 0
        ? []
        : [
            ...transactions.map((transaction,index) => ({
                id: index+1,
                startDate: moment(transaction['startDate']).format('MMMM Do YYYY'),
                endDate: moment(transaction['endDate']).format('MMMM Do YYYY'),
                dividend: transaction['dividend'],
                totalUser: transaction['totalUser'],
               
            }))
        ];


    return {rows, columns}
}

export default DividendTable
