/**
 @param stores
 @param methods
 */

import { useTranslation } from "react-i18next";
import moment from "moment";

const RequestWithDrawlTable = (withDrawl = []) => {

  const {t } = useTranslation();

  const columns = [
    { field: "id", headerName: t("fields.Sno"),    width: 300,
  },
  
    { field: "type", headerName: t("fields.type"),    width: 300,
  },

  { field: "amount", headerName: t("fields.amount"),    width: 300,
},
  {
      field: "createdAt",
      headerName: t("fields.createdAt"),
      width: 300,
    }
  ];

  const rows =
  withDrawl.length === 0
      ? []
      : [
          ...withDrawl.map((item, index) => ({
            id: index+1,
            type: item.type,
            amount: item.amount,
            createdAt: moment(item.createdAt).format("MMMM Do YYYY HH:mm"),
          })),
        ];

  return { columns, rows };
};

export default RequestWithDrawlTable;
