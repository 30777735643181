import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import apis from "services";
import { DataGrid } from "@mui/x-data-grid";
import AdminNewSidebar from "components/admin/AdminNewSidebar";
import { Header } from "components";
import { useTranslation } from "react-i18next";
import { Form, Col } from "react-bootstrap";
import ReferralTable from "components/datagrid/ReferralTable";

const Referral = () => {

  const [referral, setReferral] = useState([]);
  const { t } = useTranslation();
 
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredReferral, setFilteredReferral] = useState([]);


  const fetchReferral = async () => {
    const { data } = await apis.getAdminReferral();
    if (data?.status) {
      setReferral(data?.referral);
    }
  };




  useEffect(() => {
    (async () => {
      await fetchReferral();
    })();
  }, []);

  

  let tables;
  if (searchQuery) {
    tables = ReferralTable(filteredReferral);
  }
  else {
    tables = ReferralTable(referral);
  }


  
  const filterReferral = () => {
    const filtered = referral?.filter((ref) => {
      const search = `${ref.from_referral_userId?.name} ${ref.to_referral_userId?.name}`.toLowerCase();
      return search?.includes(searchQuery.toLowerCase()) ;
    });
    setFilteredReferral(filtered);
  };
  
  return (
    <Box className="dashboard-main">
      <AdminNewSidebar />
      <div className="dashboard-section-main">
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
          className="custom-scroll"
        >
          <Header />
          <Container maxWidth="lg" sx={{ mt: 0, mb: 4 }}>
            <div className="flex-with-title">
                <h3 className="dashboard-title">{t("titles.referral")}</h3>
                 
              </div>
              <Col xs={12} md={6} lg={4} className="mt-3 p-0">
                <Form.Group className="my-2">
                  <Form.Label>Search by Referral Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter referral name To or referree name"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyUp={filterReferral}
                  />
                </Form.Group>
              </Col>

            <Grid
              item
              container
              className="mt-4"
              justifyContent="flex-end"
              sx={{ height: "600px" }}
            >
              <DataGrid
                rows={tables?.rows}
                columns={tables?.columns}
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                }
              />
            </Grid>
          </Container>
        </Box>
      </div>
      
    </Box>
  );
};

export default Referral;
