import {useLocation, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import utils from "../utils";
import {LANGUAGES} from "../assets/constants";
import Loader from "../components/Loader";

export default function useLocationNavigation() {
    const navigate = useNavigate();
    const {pathname, state} = useLocation();
    const {language} = useSelector(store => store.language)
    const [loader, setLoader] = useState(true);
    
  useEffect(() => {
        if (pathname === "/") {
            setLoader(false);
            navigate(`/${language}`, {state})
        } else {
            /*if the pathname does not contain language id*/
            if (!utils.hasLanguageInPathname(pathname, LANGUAGES)) {
                setLoader(false);
                navigate(`/${language}${pathname}`, {state});
            }
        }
    }, [pathname]);

    return loader
}