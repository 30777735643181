import React, {Fragment} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";

const AddBlogForm = ({formData, submitHandler, onChangeHandler, heading , update}) => {

    const submit = (e) => {
        e.preventDefault();
        submitHandler(formData);
        e.currentTarget.reset();
    }

    return (
        <Fragment>
            <Form onSubmit={submit} className='my-5'>
                
                <Row className='d-flex justify-content-center align-items-center'>
                    <Col xs={12} md={6} lg={3}>
                        <Form.Group className="mb-3" controlId="title">
                            <Form.Label>Title</Form.Label>
                            <Form.Control required type="text" name='title' placeholder='Blog title'
                                          onChange={onChangeHandler} value={formData.title}/>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={3}>
                        <Form.Group className="mb-3" controlId="author">
                            <Form.Label>Author</Form.Label>
                            <Form.Control required type="text" name='author' placeholder='Author'
                                          onChange={onChangeHandler} value={formData.author}/>
                        </Form.Group>
                    </Col>
                   
                    <Col xs={12} md={6} lg={3}>
                        <Form.Group className="mb-3" controlId="postalCode">
                            <Form.Label>Author Image</Form.Label>
                            <Form.Control accept="image/png, image/gif, image/jpeg" type="file" name='authorImage' placeholder='Image'
                                          onChange={onChangeHandler}/>
                        </Form.Group>
                    </Col>
                  
                    
                    <Col xs={12} md={6} lg={3}>
                        <Form.Group className="mb-3" controlId="postalCode">
                            <Form.Label>Blog Image</Form.Label>
                            <Form.Control accept="image/png, image/gif, image/jpeg" type="file" name='image' placeholder='Image'
                                          onChange={onChangeHandler}/>
                        </Form.Group>
                    </Col>
                     
                    <Col xs={12} md={6} lg={12}>
                        <Form.Group className="mb-3" controlId="address">
                            <Form.Label>Description</Form.Label>
                            <Form.Control required as="textarea" rows={5} name='description' placeholder='Description'
                                          onChange={onChangeHandler} value={formData.description}/>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <Button className='w-100' variant="outline-danger" type='submit'> {heading} Blog </Button>
                    </Col>
                </Row>
            </Form>
        </Fragment>
    )
}

export default AddBlogForm
