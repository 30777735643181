import axios from "axios";


const createBackendServer = (baseURL) => {

    const api = axios.create({
      baseURL: `${baseURL}`,
      headers: {
        Accept: "application/json"
    },
      timeout: 60 * 1000,
    });
    
    //Interceptor
    api.interceptors.response.use(
        (response) => response,
        (error) => {
            const message = error?.response?.data?.message;
            error.message = message ?? error.message
            if (error?.response?.data?.errors)
                error.errors = error?.response?.data?.errors;
            return Promise.reject(error)
        })

    const headers = {
        "Content-Type": "multipart/form-data",
        "Accept": "application/json",
    };

    const getSection = async (section,page) => api.get(`/api/section?section=${section}&page=${page}`);
    const getSetting = async (section,page) => api.get(`/api/setting?section=${section}&page=${page}`);
    const showSection = async (id) => api.get(`/api/section/${id}`);
    const getCategory = async () => api.get(`/api/category`);
    const getSeo = async (pageName) => api.get(`/api/seo/${pageName}`);

    return {
        getSection,
        getSetting,
        showSection,
        getCategory,
        getSeo
    };
};

const cms = createBackendServer(process.env.REACT_APP_CMS_URL);

export default cms;
