
import { Button, Col, Modal, Row } from "react-bootstrap";
import { KYCForm } from "components/forms";
import { useState } from "react";
import CreateSignature from "components/CreateSignature";
import PDFViewer from "components/PDFViewer";
import { useSelector } from "react-redux";
import SignatureModal from "./SignatureModal";

const KYCModal = ({ status, toggleModal, signature }) => {

  return (
    <>
      <Modal
        show={status}
        onHide={toggleModal}
        centered
        className="invest-options-modal"
      >
        <Modal.Header closeButton>

          <Modal.Title>KYC Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Row>
            {signature && <>
              <Col><h6>Please complete the required information for the pending investment procedure.</h6></Col>
              <br />
            </>
            }
            <KYCForm signature={signature} toggleModal={toggleModal} />
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default KYCModal;
