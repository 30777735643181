const ProgressBar = (props) => {
    const { bgcolor, completed } = props;
  
    const fillerStyles = {
      position: 'relative', // Add position absolute
    //   height: '100%',
      width: `${completed}%`,
      backgroundColor: bgcolor,
      borderRadius: 'inherit',
      textAlign: 'right',
      zIndex:9999
    }
  
    const labelStyles = {
      padding: 5,
      color: 'white',
      fontWeight: 'bold'
    }
  
    return (
        <div style={{background:'#EBEBEB',position:'fixed',top:0,height:'24px',width:"100%",zIndex:9999 , borderBottom:'1px solid lightgrey'}}>
      <div style={fillerStyles}>
        <span style={labelStyles}>{ completed === 99.99 ? `100%` : `${completed}%`}</span>
      </div>
      </div>
    );
  };
  
  export default ProgressBar;
  