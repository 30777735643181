import Link from "hooks/Link";

export const replaceLinksInText = (text) => {
    // Regular expression to match URLs in the text
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text?.split(urlRegex).map((part, index) => {
        if (index % 2 === 0) {
            // This part is not a URL
            return <span key={index}>{part}</span>;
        } else {
            // This part is a URL, render a button for redirection
            return (
                <a
                className="red"
                    href={part}
                    target="_blank"
                    key={index}
                >
                    View
                </a>
            );
        }
    });
};