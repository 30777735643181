import {Box} from '@mui/material';
import {toast} from "react-toastify";
import {Container} from "react-bootstrap";
import React, {useState} from 'react';
import { useParams} from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css";
import useNavigate from "hooks/Navigate";
import apis from '../../services';
import {AddAnnualProgressForm} from "../../components/forms";
import AdminNewSidebar from 'components/admin/AdminNewSidebar';
import { Header } from 'components';



const INITIAL_FORM_STATE = {
    title: "",
    file:""
}

function AddAnnualProgress() {
    const [annualData, setAnnualData] = useState(INITIAL_FORM_STATE)
      const navigate = useNavigate();
    
    const onChangeHandler = (e) => {
        let {name,value,files}=e.target;
        if (files)
            setAnnualData((prevState) => ({...prevState, [name]: files[0]}));
      else{
          setAnnualData((prevState) => ({...prevState, [name]: value}));
      }
        
        };
  
    const onAddAnnualHandler = async (data) => {
        try {
             const form_data = new FormData();
      for (const [key, value] of Object.entries(data)) {
        form_data.append(key, value);
      }
            const res = await apis.addAnnualProgress(form_data);
            if (res?.data?.status) {
                toast.success(res.data.message);
                navigate("/admin/annual-progress")
                setAnnualData(INITIAL_FORM_STATE)
            }
        } catch (e) {
            console.error('ERROR on update annual', e);
        }
    }

  
    const onSubmitHandler = async () => {
        onAddAnnualHandler(annualData)
    }

    return (
        <Box className="dashboard-main">
            <AdminNewSidebar/>
            <div className="dashboard-section-main">
            
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    height: "100vh",
                    overflow: "auto",
                }}
                className="custom-scroll"
            >
                <Header/>
                <Container>
                <h3 className="section-title dark-text text-start mb-0">Add Annual Progress</h3>
                    <AddAnnualProgressForm
                        formData={annualData}
                        onChangeHandler={onChangeHandler}
                        submitHandler={onSubmitHandler}
                        heading={'Add'}
                    />
                </Container>
            </Box>
            </div>
        </Box>
    );
}

export default AddAnnualProgress;
