import * as translations from "../translation";
import {useDispatch, useSelector} from "react-redux";
import {setLanguage as setLang} from "../redux/slices/languageSlice";

function useTranslation() {
    const dispatch = useDispatch();
    const {language} = useSelector(store => store.language);

    const translate = (key) => {
        const keys = key.split(".");
        return (
            getNestedTranslation(language, keys) ??
            getNestedTranslation(language, keys) ??
            key
        );
    };

    const setLanguage = (language = '') => {
        dispatch(setLang(language))
    }


    return {
        language,
        setLanguage,
        t: translate,
    };
}

function getNestedTranslation(language, keys) {
    return keys.reduce((obj, key) => {
        return obj?.[key];
    }, translations[language]);
}

export default useTranslation;
