import { useEffect, useState } from "react";
import reduxApis from "redux/apis";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import imageUrl from "utils/imageUrl";
import useNavigate from "hooks/Navigate";

// import OtpInput from "react-otp-input";

import OtpInput from 'react18-input-otp';

import Link from 'hooks/Link';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  FormText,
  Row,
} from "react-bootstrap";
import { Header, Sidebar } from "components";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import apis from "services";
import {toast} from "react-toastify";
import { setUser } from "redux/slices/userSlice";


const OtpVerify = () => {
  const { language, setLanguage, t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { user } = useSelector((store) => store.user);

  const [emailOTP, setEmailOTP] = useState("");
  // if (user) {
  //   navigate(state ? state.from : "/user/dashboard");
  // }
  useEffect(() => {
    if(!state?.email)
    {
      navigate("/login");
    }
    
    if (user) {
      navigate("/");
    }
  }, []);

 
  const onSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await apis.verification({email:state?.email,otp:emailOTP});
      if (response?.status === 200) {
          dispatch(setUser({ id: response?.data?.user?._id, ...response?.data?.user }));
          toast.success(response?.data?.message);
          setTimeout(() => navigate("/",), 2000);
      }
 
      setLoading(false);
    } catch (e) {
      console.error(e);
      toast.error(e.message)
      setLoading(false);
  }

  };

  return (
    <div className="landing-main">
      <div className="landing-page landing-main-page custom-scroll New-login-form-main">
        <Header />
        {/* <Container>
          <Form onSubmit={onSubmitHandler} className="px-4">
            <Row className=" mt-4">
              <Col md={2} lg={3} />

              <Col md={8} lg={6} className="custom-box login">
                <h3 className="dashboard-title text-center">{t("titles.forgetPass")}</h3>

                <Form.Group className="mb-3 mt-4" controlId="email">
                  <Form.Control
                    type="email"
                    placeholder={t("placeholders.email")}
                    onChange={onChangeHandler}
                    value={formData.email}
                    required
                  />
                </Form.Group>

                <div className="center-wrapper">
                  <Button className="lp-btn" type="submit">
                    Continue
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
         */}

        <Container className="pt-3 ">
          <Row>
            <Col md={6} className="m-auto custom-box">
              <div className="account-created-box kyc-box p-4 rounded-3 w-75 mx-auto">
                <div className="d-block">
                  <div className="email-verification-img mx-auto mt-1 mb-4">
                    <img
                      src={imageUrl("email-verification-img.png")}
                      alt=""
                      className="w-100 h-100"
                    />
                  </div>
                </div>
                <p className="fw-bold fs-4">Two-step verification</p>
                <p className="fs-6">
                  <span className="muted-text">
                    Thanks for keeping your account secure.
                  </span>
                </p>
                <Form className="otp-form">
                  {/* <p className="fs-6 fw-bold pt-4">Mobile Verification Code</p>
                  <div className="otp-wrapper d-flex align-items-center justify-content-center">
                    <OtpInput
                      value={mobileOTP}
                      onChange={setMobileOTP}
                      req
                      numInputs={4}
                      isInputNum={true}
                      inputStyle={{
                        width: "63.32px",
                        height: "56px",
                        margin: "1rem 0.7rem",
                        fontSize: "2rem",
                        borderRadius: 4,
                        border: "none",
                        backgroundColor: "#F4F4F4",
                      }}
                    />
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="form-icon"
                    />
                  </div> */}
                  <p className="fs-6 fw-bold pt-4">Email Verification Code</p>
                  <div className="otp-wrapper d-flex align-items-center justify-content-center">
                    <OtpInput
                      value={emailOTP}
                      onChange={setEmailOTP}
                      numInputs={4}
                      isInputNum={true}
                      inputStyle={{
                        width: "63.32px",
                        height: "56px",
                        margin: "1rem 0.7rem",
                        fontSize: "2rem",
                        borderRadius: 4,
                        border: "none",
                        backgroundColor: "#EDEDED",
                      }}
                    />
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="form-icon"
                    />
                  </div>
                  {/* <Button type="submit" className="d-block w-100 primary-bg mt-4 mb-4 border-0 hbg-primary py-2">
                    Submit
                  </Button> */}
                  <div className="Login-btn-container">
                  <button onClick={onSubmitHandler} type="submit" disabled={loading}>
                    {loading ? t("buttons.is-verify") : t("buttons.verify")}
                  </button>
                </div>

                </Form>
                <p className="primary-color mt-3">Didn’t receive the code?</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default OtpVerify;
