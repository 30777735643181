import { Box, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import apis from "../../services";
import useNavigate from "hooks/Navigate";
import { DataGrid } from "@mui/x-data-grid";
import Toolbar from "components/@material-extend/CustomToolbar";
import { toast } from "react-toastify";
import UsersTable from "../../components/datagrid/UsersTable";
import AdminNewSidebar from "components/admin/AdminNewSidebar";
import { Header } from "components";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import Link from "hooks/Link";
import DeleteModal from "components/modals/DeleteModal";

import { Table, Form, Row, Col } from "react-bootstrap";
import AnnualProgressTable from "components/datagrid/AnnualProgressTable";
import { useSelector } from "react-redux";

export const AnnualProgress = () => {
  const [annualProgress, setAnnualProgress] = useState([]);

  const { adminUser } = useSelector((store) => store.admin);
  const [loader, setLoader] = useState(false);

  const [show, setShow] = useState(false);

  const [annualProgressId, setAnnualProgressId] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  /*Methods*/
  const fetchAllAnnualProgress = async () => {
    const res = await apis.getAllAnnualProgress();
    if (res.data.status) setAnnualProgress(res.data.annualProgress);
  };

  const deleteHandler = async (id) => {
    setLoader(true);
    const res = await apis.deleteAnnualProgress(id);
    if (res.data.status) {
      toast.success(res.data.message);
      await fetchAllAnnualProgress();
    }
    else toast.error(res.data?.message);

    handleClose();
    setLoader(false);
  };

  const sendToTheInvestor = async () => {
    const res = await apis.sendAnnualProgressReportInvestor();
    if (res.data.status) {
      toast.success(res.data.message);
    } else toast.error(res.data.message);
  };


  useEffect(() => {
    (async () => {
      await fetchAllAnnualProgress();
    })();
  }, []);

  const handleClose = () => {
    setShow(false);
    setAnnualProgressId("");
  };

  const openModal = (id) => {
    setAnnualProgressId(id);
    setShow(true);
  };



  let tables = 
  AnnualProgressTable(annualProgress, {
      navigate,
      deleteHandler,
      openModal,
      sendToTheInvestor,
      adminUser
    });
  

 
  return (
    <>
      <Box className="dashboard-main">
        <AdminNewSidebar />
        <div className="dashboard-section-main">
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
            className="custom-scroll"
          >
            <Header />
            <Container maxWidth="xl" sx={{ mt: 0, mb: 4 }}>
              <div className="flex-with-title">
                <h3 className="dashboard-title">{t("titles.annual-progress")}</h3>
                <Link to={"/admin/annual-progress/add"} className="lp-btn">
                  {t("buttons.add_annual-progress")}
                </Link>
              </div>
           

              <Grid
                item
                container
                justifyContent="flex-end"
                className="mt-4"
                sx={{ height: "600px" }}
              >
             
                <DataGrid
                  //checkboxSelection
                  rows={tables?.rows}
                  columns={tables?.columns}
                  getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                  }
                />
              </Grid>
              <DeleteModal
                show={show}
                handleClose={handleClose}
                handleSubmit={deleteHandler}
                loader={loader}
                id={annualProgressId}
                title={"Delete Annual Progress"}
              />
            </Container>
          </Box>
        </div>
      </Box>
    </>
  );
};

export default AnnualProgress;
