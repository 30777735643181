
import { useTranslation } from "react-i18next";

const DonationTokenTable = (transfer = []) => {
    const { language, setLanguage, t } = useTranslation();
   

    const columns = [
        {field: 'id', headerName: 'No', width: 200, hide: false,},
        {
            field: "fullName",
            headerName: t("fields.fullName"),
            minWidth: 150,
            flex: 1,
          },
          { field: "email", headerName: t("fields.email"), minWidth: 200, flex: 1 },
   
        {field: 'walletId', headerName: t("fields.WalletID"), width: 300},
        
        // {field: 'type', headerName: t("fields.type"), width: 200},
        {field: 'tokens', headerName: t("fields.Token"), width: 200},
        {field: 'memo', headerName: t("fields.memo"), width: 200},
        {
            field: "createdAt",
            headerName: t("fields.createdAt"),
            minWidth: 200,
            flex: 1,
          },
    ];

    const rows = transfer.length === 0
        ? []
        : [
            ...transfer.map((transfer,index) => ({
                id: index+1,
               
                fullName: transfer['walletId']?.userId?.name,
                email: transfer['walletId']?.userId?.email,
           
                walletId: transfer['walletId']?._id,
                // type: transfer['type'],
                tokens: transfer['tokens'],
                memo: transfer['memo'],
                createdAt: transfer['createdAt'],
               
            }))
        ];

    return {rows, columns}
}

export default DonationTokenTable
