
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import imageUrl from "utils/imageUrl";
import "../sidebar/sidebar.css";
import utils from "../../utils";
import { ADMIN_TYPES, SUB_ADMIN_TYPES } from "../../assets/constants";
import { useEffect } from "react";
import apis from "services";
import { CSVLink } from "react-csv";
import Link from 'hooks/Link';
const AdminNewSidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const [newsData, setNewsData] = useState([]);

  const { adminUser } = useSelector((store) => store.admin);
  const fetchNewsFeet = async () => {
    const res = await apis.getAllNewsFeet();
    if (res?.data?.status) setNewsData(res?.data?.news);
  };
  useEffect(() => {
    (async () => {
      if (adminUser) {
        await fetchNewsFeet();
      }
    })();
  }, []);

  // const { user } = useSelector((store) => store.user);
  return (
    <>
      <div className={`sidebar custom-scroll ${isOpen ? "open" : ""}`}>
        <div className="logo-details logo-icon primary-logo">
          <div className="sb-icon-wrapper">
            <i className="bx bxl-c-plus-plus icon"></i>
          </div>
          <img src={imageUrl("logo-icon.png")} alt="" />
        </div>
        <div className="logo-details">
          <div className="sb-icon-wrapper">
            <i className="bx bxl-c-plus-plus icon"></i>
          </div>
          <img
            src={imageUrl("bars-icon.png")}
            alt=""
            id="btn"
            onClick={() => setIsOpen((prevState) => !prevState)}
          />
        </div>
        <ul className="nav-list">
          {adminUser ? (
            <>
              {SUB_ADMIN_TYPES.includes(adminUser?.type) ? (
                <>              <li
                  data-bs-toggle="tooltip"
                  data-bs-custom-class="custom-tooltip"
                  data-bs-placement="right"
                  title="Dashboard"
                >
                  <Link to={"/sub-admin/dashboard"}>
                    <div className="sb-icon-wrapper">
                      <img src={imageUrl("dash_icon.png")} alt="" />
                    </div>
                    <span className="links_name">{t("titles.dashboard")}</span>
                  </Link>
                </li>
                  <li
                    data-bs-toggle="tooltip"
                    data-bs-custom-class="custom-tooltip"
                    data-bs-placement="right"
                    title="Wallet"
                  >
                    <Link to={"/sub-admin/wallet"}>
                      <div className="sb-icon-wrapper">
                        <img src={imageUrl("wallet_icon.png")} alt="" />
                      </div>
                      <span className="links_name">{t("buttons.wallet")}</span>
                    </Link>
                  </li>
                  <li
                    data-bs-toggle="tooltip"
                    data-bs-custom-class="custom-tooltip"
                    data-bs-placement="right"
                    title="Users"
                  >
                    <Link to={"/sub-admin/users"}>
                      <div className="sb-icon-wrapper">
                        <img src={imageUrl("user_icon.png")} alt="" />
                      </div>
                      <span className="links_name">{t("buttons.users")}</span>
                    </Link>
                  </li>
                  <li
                    data-bs-toggle="tooltip"
                    data-bs-custom-class="custom-tooltip"
                    data-bs-placement="right"
                    title="Stores"
                  >
                    <Link to={"/sub-admin/stores"}>
                      <div className="sb-icon-wrapper">
                        <img src={imageUrl("store_icon.png")} alt="" />
                      </div>
                      <span className="links_name">{t("buttons.stores")}</span>
                    </Link>
                  </li>
                  <li
                    data-bs-toggle="tooltip"
                    data-bs-custom-class="custom-tooltip"
                    data-bs-placement="right"
                    title="Blog"
                  >
                    <Link to={"/sub-admin/blog"}>
                      <div className="sb-icon-wrapper">
                        <img src={imageUrl("BlogAdd.png")} alt="" />
                      </div>
                      <span className="links_name">{t("buttons.blog")}</span>
                    </Link>
                  </li>
                  <li
                    data-bs-toggle="tooltip"
                    data-bs-custom-class="custom-tooltip"
                    data-bs-placement="right"
                    title="withDrawl User"
                  >
                    <Link to={"/sub-admin/withDrawl"}>
                      <div className="sb-icon-wrapper">
                        <img src={imageUrl("dollar-icon.png")} alt="" />
                      </div>
                      <span className="links_name">
                        {t("buttons.withDrawl")}
                      </span>
                    </Link>
                  </li>
                </>)
                : <></>
              }
              {adminUser?.type === "owner" ? (
                <>
                  <li
                    data-bs-toggle="tooltip"
                    data-bs-custom-class="custom-tooltip"
                    data-bs-placement="right"
                    title="Dashboard"
                  >
                    <Link to={"/admin/dashboard"}>
                      <div className="sb-icon-wrapper">
                        <img src={imageUrl("dash_icon.png")} alt="" />
                      </div>
                      <span className="links_name">{t("titles.dashboard")}</span>
                    </Link>
                  </li>
                  <li
                    data-bs-toggle="tooltip"
                    data-bs-custom-class="custom-tooltip"
                    data-bs-placement="right"
                    title="Wallet"
                  >
                    <Link to={"/admin/wallet"}>
                      <div className="sb-icon-wrapper">
                        <img src={imageUrl("wallet_icon.png")} alt="" />
                      </div>
                      <span className="links_name">{t("buttons.wallet")}</span>
                    </Link>
                  </li>
                  <li
                    data-bs-toggle="tooltip"
                    data-bs-custom-class="custom-tooltip"
                    data-bs-placement="right"
                    title="Users"
                  >
                    <Link to={"/admin/users"}>
                      <div className="sb-icon-wrapper">
                        <img src={imageUrl("user_icon.png")} alt="" />
                      </div>
                      <span className="links_name">{t("buttons.users")}</span>
                    </Link>
                  </li>
                  <li
                    data-bs-toggle="tooltip"
                    data-bs-custom-class="custom-tooltip"
                    data-bs-placement="right"
                    title="Users Document"
                  >
                    <Link to={"/admin/users-document"}>
                      <div className="sb-icon-wrapper">
                        <img src={imageUrl("document_icon.png")} alt="" />
                      </div>
                      <span className="links_name">{t("buttons.document")}</span>
                    </Link>
                  </li>

                  <li
                    data-bs-toggle="tooltip"
                    data-bs-custom-class="custom-tooltip"
                    data-bs-placement="right"
                    title="Users Document"
                  >
                    <Link to={"/admin/annual-progress"}>
                      <div className="sb-icon-wrapper">
                        <img src={imageUrl("document_icon.png")} alt="" />
                      </div>
                      <span className="links_name">{t("buttons.annual-progress")}</span>
                    </Link>
                  </li>



                  <li
                    data-bs-toggle="tooltip"
                    data-bs-custom-class="custom-tooltip"
                    data-bs-placement="right"
                    title="Stores"
                  >
                    <Link to={"/admin/stores"}>
                      <div className="sb-icon-wrapper">
                        <img src={imageUrl("store_icon.png")} alt="" />
                      </div>
                      <span className="links_name">{t("buttons.stores")}</span>
                    </Link>
                  </li>
                  <li
                    data-bs-toggle="tooltip"
                    data-bs-custom-class="custom-tooltip"
                    data-bs-placement="right"
                    title="Transactions"
                  >
                    <Link to={"/admin/transactions"}>
                      <div className="sb-icon-wrapper">
                        <img src={imageUrl("transaction_icon.png")} alt="" />
                      </div>
                      <span className="links_name">
                        {t("buttons.transactions")}
                      </span>
                    </Link>
                  </li>
                  <li
                    data-bs-toggle="tooltip"
                    data-bs-custom-class="custom-tooltip"
                    data-bs-placement="right"
                    title="Transactions"
                  >
                    <Link to={"/admin/admin-transactions"}>
                      <div className="sb-icon-wrapper">
                        <img src={imageUrl("transaction_icon.png")} alt="" />
                      </div>
                      <span className="links_name">
                        {t("buttons.admin-transactions")}
                      </span>
                    </Link>
                  </li>
                  <li
                    data-bs-toggle="tooltip"
                    data-bs-custom-class="custom-tooltip"
                    data-bs-placement="right"
                    title="Send Notifications"
                  >
                    <Link to={"/admin/send-notification"}>
                      <div className="sb-icon-wrapper">
                        <img src={imageUrl("rocket-icon.png")} alt="" />
                      </div>
                      <span className="links_name">
                        {t("buttons.send_notifications")}
                      </span>
                    </Link>
                  </li>
                  <li
                    data-bs-toggle="tooltip"
                    data-bs-custom-class="custom-tooltip"
                    data-bs-placement="right"
                    title="Notification History"
                  >
                    <Link to={"/admin/notification-history"}>
                      <div className="sb-icon-wrapper">
                        <img src={imageUrl("rocket-icon.png")} alt="" />
                      </div>
                      <span className="links_name">
                        {t("buttons.notifications_history")}
                      </span>
                    </Link>
                  </li>
                  <li
                    data-bs-toggle="tooltip"
                    data-bs-custom-class="custom-tooltip"
                    data-bs-placement="right"
                    title="Share Price History"
                  >
                    <Link to={"/admin/share-price-history"}>
                      <div className="sb-icon-wrapper">
                        <img src={imageUrl("rocket-icon.png")} alt="" />
                      </div>
                      <span className="links_name">
                        {t("buttons.share_price_history")}
                      </span>
                    </Link>
                  </li>
                  <li
                    data-bs-toggle="tooltip"
                    data-bs-custom-class="custom-tooltip"
                    data-bs-placement="right"
                    title="Referral History"
                  >
                    <Link to={"/admin/referral-history"}>
                      <div className="sb-icon-wrapper">
                        <img src={imageUrl("rocket-icon.png")} alt="" />
                      </div>
                      <span className="links_name">
                        {t("titles.referral")}
                      </span>
                    </Link>
                  </li>
                  <li
                    data-bs-toggle="tooltip"
                    data-bs-custom-class="custom-tooltip"
                    data-bs-placement="right"
                    title="Dividend"
                  >
                    <Link to={"/admin/dividend"}>
                      <div className="sb-icon-wrapper">
                        {/* <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" fill={"white"}>
                                                    <path d="M11.025 21v-2.15q-1.325-.3-2.287-1.15-.963-.85-1.413-2.4l1.85-.75q.375 1.2 1.113 1.825.737.625 1.937.625 1.025 0 1.738-.462.712-.463.712-1.438 0-.875-.55-1.388-.55-.512-2.55-1.162-2.15-.675-2.95-1.612-.8-.938-.8-2.288 0-1.625 1.05-2.525 1.05-.9 2.15-1.025V3h2v2.1q1.25.2 2.063.912.812.713 1.187 1.738l-1.85.8q-.3-.8-.85-1.2-.55-.4-1.5-.4-1.1 0-1.675.488-.575.487-.575 1.212 0 .825.75 1.3.75.475 2.6 1 1.725.5 2.613 1.587.887 1.088.887 2.513 0 1.775-1.05 2.7-1.05.925-2.6 1.15V21Z"/>
                                                </svg>
                                                 */}
                        <img src={imageUrl("share-transfer.png")} alt="" />
                      </div>
                      <span className="links_name">
                        {t("buttons.dividend")}
                      </span>
                    </Link>
                  </li>

                  <li
                    data-bs-toggle="tooltip"
                    data-bs-custom-class="custom-tooltip"
                    data-bs-placement="right"
                    title="Divident History"
                  >
                    <Link to={"/admin/dividend-history"}>
                      <div className="sb-icon-wrapper">
                        <img src={imageUrl("dividend.png")} alt="" />
                      </div>
                      <span className="links_name">
                        {t("buttons.dividendHistory")}
                      </span>
                    </Link>
                  </li>
                  <li
                    data-bs-toggle="tooltip"
                    data-bs-custom-class="custom-tooltip"
                    data-bs-placement="right"
                    title="Share Transfer"
                  > 
                   
                    <Link to={"/admin/share-transfer"}>
                      <div className="sb-icon-wrapper">
                        <img src={imageUrl("donation.png")} alt="" />
                      </div>
                      <span className="links_name">
                        {t("buttons.shareTransfer")}
                      </span>
                    </Link>
                  </li>

                 
                  <li
                    data-bs-toggle="tooltip"
                    data-bs-custom-class="custom-tooltip"
                    data-bs-placement="right"
                    title="Share Transfer History"
                  >
                    <Link to={"/admin/share-transfer-history"}>
                      <div className="sb-icon-wrapper">
                        <img
                          src={imageUrl("share-transfer-history.png")}
                          alt=""
                        />
                      </div>
                      <span className="links_name">
                        {t("buttons.shareTransferHistory")}
                      </span>
                    </Link>
                  </li>
                  <li
                    data-bs-toggle="tooltip"
                    data-bs-custom-class="custom-tooltip"
                    data-bs-placement="right"
                    title="Token Transfer"
                  >
                     {/* "Work Done By MOhsin" */}
                    <Link to={"/admin/token-transfer"}>
                      <div className="sb-icon-wrapper">
                        <img src={imageUrl("donation.png")} alt="" />
                      </div>
                      <span className="links_name">
                        {t("buttons.jtcTokenTransfer")}
                      </span>
                    </Link>
                  </li>

                  {/* "work Done By Mohsin" */}
                  <li
                    data-bs-toggle="tooltip"
                    data-bs-custom-class="custom-tooltip"
                    data-bs-placement="right"
                    title="Share Transfer History"
                  >
                    <Link to={"/admin/token-transfer-history"}>
                      <div className="sb-icon-wrapper">
                        <img
                          src={imageUrl("share-transfer-history.png")}
                          alt=""
                        />
                      </div>
                      <span className="links_name">
                        {t("buttons.jtcTokenTransferHistory")}
                      </span>
                    </Link>
                  </li>

                  <li
                    data-bs-toggle="tooltip"
                    data-bs-custom-class="custom-tooltip"
                    data-bs-placement="right"
                    title="Blog"
                  >
                    <Link to={"/admin/blog"}>
                      <div className="sb-icon-wrapper">
                        <img src={imageUrl("BlogAdd.png")} alt="" />
                      </div>
                      <span className="links_name">{t("buttons.blog")}</span>
                    </Link>
                  </li>
                  <li
                    data-bs-toggle="tooltip"
                    data-bs-custom-class="custom-tooltip"
                    data-bs-placement="right"
                    title="withDrawl User"
                  >
                    <Link to={"/admin/withDrawl"}>
                      <div className="sb-icon-wrapper">
                        <img src={imageUrl("dollar-icon.png")} alt="" />
                      </div>
                      <span className="links_name">
                        {t("buttons.withDrawl-user")}
                      </span>
                    </Link>
                  </li>
                  <li
                    data-bs-toggle="tooltip"
                    data-bs-custom-class="custom-tooltip"
                    data-bs-placement="right"
                    title="withDrawl Admin"
                  >
                    <Link to={"/admin/withDrawl-request"}>
                      <div className="sb-icon-wrapper">
                        <img src={imageUrl("dollar-icon.png")} alt="" />
                      </div>
                      <span className="links_name">
                        {t("buttons.withDrawl-admin")}
                      </span>
                    </Link>
                  </li>
                  <li
                    data-bs-toggle="tooltip"
                    data-bs-custom-class="custom-tooltip"
                    data-bs-placement="right"
                    title="Export"
                  >
                    <CSVLink
                      onClick={() => {
                        console.table(newsData);
                      }}
                      style={{ color: "blue" }}
                      data={newsData}
                    >
                      <div className="sb-icon-wrapper">
                        <img src={imageUrl("dollar-icon.png")} alt="" />
                      </div>
                      <span className="links_name">Export</span>
                    </CSVLink>
                  </li>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              <li
                data-bs-toggle="tooltip"
                data-bs-custom-class="custom-tooltip"
                data-bs-placement="right"
                title="Dashboard"
              >
                <Link to={"/user/dashboard"}>
                  <div className="sb-icon-wrapper">
                    <img src={imageUrl("dash_icon.png")} alt="" />
                  </div>
                  <span className="links_name">{t("new-changes.admin_dashboard")}</span>
                </Link>
              </li>
              <li
                data-bs-toggle="tooltip"
                data-bs-custom-class="custom-tooltip"
                data-bs-placement="right"
                title="Wallet"
              >
                <Link to={"/user/wallet"}>
                  <div className="sb-icon-wrapper">
                    <img src={imageUrl("wallet_icon.png")} alt="" />
                  </div>
                  <span className="links_name">{t("buttons.wallet")}</span>
                </Link>
              </li>
              <li
                data-bs-toggle="tooltip"
                data-bs-custom-class="custom-tooltip"
                data-bs-placement="right"
                title="Invest Now"
              >
                <Link to={"/franchise"}>
                  <div className="sb-icon-wrapper">
                    <img src={imageUrl("dollar-icon.png")} alt="" />
                  </div>
                  <span className="links_name">{t("buttons.invest_now")}</span>
                </Link>
              </li>
              <li
                data-bs-toggle="tooltip"
                data-bs-custom-class="custom-tooltip"
                data-bs-placement="right"
                title="
                Withdrawal"
              >
                <Link to={"/user/withdrawl"}>
                  <div className="sb-icon-wrapper">
                    <img src={imageUrl("transaction_icon.png")} alt="" />
                  </div>
                  <span className="links_name">{t("buttons.withDrawl")}</span>
                </Link>
              </li>
              <li
                data-bs-toggle="tooltip"
                data-bs-custom-class="custom-tooltip"
                data-bs-placement="right"
                title="document"
              >
                <Link to={"/user/document"}>
                  <div className="sb-icon-wrapper">
                    <img src={imageUrl("document_icon.png")} alt="" />
                  </div>
                  <span className="links_name">{t("buttons.document")}</span>
                </Link>
              </li>
              <li
                data-bs-toggle="tooltip"
                data-bs-custom-class="custom-tooltip"
                data-bs-placement="right"
                title="Referral"
              >
                <Link to={"/user/referral"}>
                  <div className="sb-icon-wrapper">
                    <img src={imageUrl("rocket-icon.png")} alt="" />
                  </div>
                  <span className="links_name">
                    {" "}{t("titles.referral")}
                  </span>
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </>
  );
};

export default AdminNewSidebar;
