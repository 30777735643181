import {Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";
import {Button, Form} from "react-bootstrap";
import {useState} from "react";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import apis from "../../services";
import {setReserveShares} from "../../redux/slices/adminSlice";
import { useEffect } from "react";

const ApproveWithDrawlModal = ({status = true, close,amount, withDrawId,fetchAllWithDrawlUser}) => {

    const dispatch = useDispatch();
    const [transactionId,setTransactionId]=useState("")
     
    const submitHandler = async (event) => {
        event.preventDefault();
        const data = {withDrawId , transactionId}   
        const res = await apis.approvedWithdrawl(data);
    if (res?.data?.status) {
        toast.success(res.data?.message);
       await fetchAllWithDrawlUser();
    } else toast.error(res.data?.message);        
        close()
        }

    return (
        <Dialog  maxWidth={"md"} open={status} onClose={close}>
            <DialogTitle>Approve Withdrawl Request</DialogTitle>
            <Form onSubmit={submitHandler}>
                <DialogContent dividers sx={{ width: 500 }}>
                    <Grid item container>
                        <Grid item xs={12}>
                            <Form.Group className="mb-3" controlId="reserveShares">
                                <Form.Label>Withdrawl Amount</Form.Label>
                                <Form.Control type="number" placeholder="" value={amount} disabled/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="shares">
                                <Form.Label>Transaction Id</Form.Label>
                                <Form.Control type="text" required placeholder="Enter the transaction id"
                                            name="transactionId" onChange={(e)=>setTransactionId(e.target.value)} />
                            </Form.Group>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Grid item container justifyContent="flex-end" gap={2}>
                        <Button className="lp-btn"
                                onClick={close}
                                style={{backgroundColor: '#212529', borderColor: '#212529'}}>
                            Cancel
                        </Button>
                        <Button className="lp-btn" type="submit">
                            Confirm
                        </Button>
                    </Grid>
                </DialogActions>
            </Form>
        </Dialog>
    )
}

export default ApproveWithDrawlModal
