import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import apis from "../../services";
import { DataGrid } from "@mui/x-data-grid";
import AdminNewSidebar from "components/admin/AdminNewSidebar";
import { Header } from "components";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import AdminUsersTable from "components/datagrid/AdminUsersTable";

export const AdminUsers = () => {
  const [users, setUsers] = useState([]);

  const { t } = useTranslation();

  /*Methods*/
  const fetchAllUsers = async () => {
    const res = await apis.getAllUser();
    if (res.data.status) setUsers(res.data.users);
  };



  useEffect(() => {
    (async () => {
      await fetchAllUsers();
    })();
  }, []);


  const { rows, columns } = AdminUsersTable(users);

  return (
    <>
      <Box className="dashboard-main">
        <AdminNewSidebar />
        <div className="dashboard-section-main">
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
            className="custom-scroll"
          >
            <Header />
            <Container maxWidth="xl" sx={{ mt: 0, mb: 4 }}>
              <div className="flex-with-title">
                <h3 className="dashboard-title">{t("titles.users")}</h3>
                {/* <Link to={"/admin/users/add"} className="lp-btn">
                  {t("buttons.add_user")}
                </Link> */}
              </div>
              <Grid
                item
                container
                justifyContent="flex-end"
                className="mt-4"
                sx={{ height: "600px" }}
              >
                <DataGrid
                  //checkboxSelection
                  rows={rows}
                  columns={columns}
                  getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                  }
                />
              </Grid>
            </Container>
          </Box>
        </div>
      </Box>
    </>
  );
};

export default AdminUsers;
