
import { useTranslation } from "react-i18next";
import { Grid, IconButton, Tooltip } from "@mui/material";
import {
    Delete,
  } from "@mui/icons-material";
import moment from "moment";
import { formatDate } from "utils/formatDate";
import { removeHTMLTags } from "utils/removeHTMLTags";

const AdminTransactionTable = (transactions = [] , methods={}) => {
    const { language, setLanguage, t } = useTranslation();


    const {  openModal} = methods;
    const columns = [
        {field: 'id', headerName: '#', width: 50, hide: false},
        {field: 'store', headerName: t("fields.store"), width: 260},
        {field: 'userName', headerName: t("fields.userName"), width: 150},
        {field: 'email', headerName: t("fields.email"), width: 200},
        {field: 'user_invested', headerName: t("fields.user_amount-invested"), width: 200},

        {field: 'user_purchased_share', headerName: t("fields.user_purchased_share"), width: 200},
        {field: 'sharePrice', headerName: t("fields.sharePrice"), width: 150},
       
        {field: 'amountInvested', headerName: t("fields.admin_amount_get"), width: 200},
        {field: 'purchasedShare', headerName: t("fields.admin_share_get"), width: 200},
        {field: 'type',headerName: t("fields.type"), width: 150},
        {field: 'transactionId', headerName: t("fields.transactionId"), width: 200},
        {field: 'purchasedAt', headerName: t("fields.purchasedAt"), width: 250},
        {field: 'profitActive', headerName: t("fields.profitActive"), width: 250},
        
        {field: 'isProfitStartDate', headerName: t("fields.isProfitStartDate"), width: 250},
       
        // {
        //     field: "actions",
        //     headerName: t("fields.actions"),
        //     minWidth: 250,
        //     flex: 1,
        //     renderCell: (props) => {
        //       const id = props.row._id;
        //       return (
        //         <Grid container>
                  
        //           <Grid item>
        //             <Tooltip title="Delete" arrow>
        //               <IconButton size="small" onClick={() => openModal(id)}>
        //                 <Delete />
        //               </IconButton>
        //             </Tooltip>
        //           </Grid>
                  
        //         </Grid>
        //       );
        //     },
        //   },
    ];

    const rows = transactions.length === 0
        ? []
        : [
            ...transactions.reverse().map((transaction,index) => ({
                id: index + 1,
                store: removeHTMLTags(transaction['storeName']),
                userName: transaction?.userTransactionId?.userId?.name,
                email: transaction?.userTransactionId?.userId?.email,    
                user_invested: transaction?.userTransactionId?.amountInvested,    
                
                user_purchased_share: transaction?.userTransactionId?.purchasedShare,
                
                amountInvested: transaction['amountInvested'],
                sharePrice: transaction['sharePrice'],
                type: transaction['type'],
                transactionId: transaction?.userTransactionId?.transactionId,
                purchasedShare: transaction['purchasedShare'],
                purchasedAt : formatDate(transaction['purchasedAt']),
                profitActive: transaction?.isProfitStart ? 'Yes' : 'No' ,
                isProfitStartDate :  transaction?.['isProfitStartDate'] ?  formatDate(transaction?.isProfitStartDate)  : "Not yet Start",
               
                // _id: transaction._id,
            }))
        ];


    return {rows, columns}
}

export default AdminTransactionTable
