import React, { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { FiUpload } from "react-icons/fi";
import apis from "services"; // Make sure you have the appropriate service functions for submitting documents

import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

function DocumentCard({ type, data, value, getDocumentData }) {
  const { language, setLanguage, t } = useTranslation();
  const [selectedImage, setSelectedImage] = useState(null);
  const fileInputRef = useRef(null);
  const [document, setDocument] = useState({});
  const [formData, setFormData] = useState({ type: value, document: "" });
  const [loading, setLoading] = useState(false);

  const { user } = useSelector((store) => store.user);

  let allowedFileTypes = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "application/pdf",
  ];

  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    // Check if the selected file is of an allowed file type
    if (file && allowedFileTypes.includes(file.type)) {
      setSelectedImage(URL.createObjectURL(file));
      setFormData((prevState) => ({
        ...prevState,
        type: value,
        document: file,
      }));
    } else {
      toast.error("Only PDF, JPG, PNG, and JPEG files are allowed.");
    }
  };
  const submitDocument = async () => {
    try {
      const form_data = new FormData();
      for (const [key, value] of Object.entries(formData)) {
        form_data.append(key, value);
      }
      setLoading(true);
      const response = await apis.submitUserDocuments(user?.id, form_data);
      if (response?.data?.status) {
        setDocument(response?.data?.document);
        toast.success(response?.data?.message);
        getDocumentData();
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast.error(e.message);
      console.error(e?.message);
    }
  };

  const getData = () => {
    data.map((item) => {
      if (item?.type === value) {
        setDocument(item);
      }
    });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (formData.document) {
      submitDocument();
    }
  }, [formData]);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  // Function to check if a file is a PDF
  const isPDF = (url) => {
    return url.toLowerCase().endsWith(".pdf");
  };

  return (
    <div className="doc-card" style={{ position: "relative" }}>
      <span
        className={`doc-card-status ${
          document?.status === "approved"
            ? "accepted"
            : document?.status === "pending"
            ? "pending"
            : document.status === "rejected"
            ? "rejected"
            : "processing"
        }`}
      >
        {document?.status === "approved"
          ? `${t("new-changes.approved")}`
          : document?.status === "pending"
          ? `${t("new-changes.pending")}`
          : document?.status === "rejected"
          ? `${t("new-changes.rejected")}`
          : `${t("new-changes.processing")}`
          }
      </span>
      <div className="doc-card-wrap">
        <div className="profile-nft-row1">
          <div className="NFT-image-holder">
            <input
              ref={fileInputRef}
              type="file"
              style={{ display: "none" }}
              onChange={handleImageUpload}
            />
            {document?.document ? (
              isPDF(document.document) ? (
                // Display PDF
                <iframe
                  src={document.document}
                  width="100%"
                  height="250"
                  title="Document PDF"
                />
              ) : (
                // Display image
                <img
                  src={document.document}
                  style={{ height: 250 }}
                  alt="Uploaded"
                />
              )
            ) : (
              <img
                src="/images/doc-dummy-image.png"
                style={{ height: 250 }}
                alt=""
              />
            )}
          </div>
          <div>
            <div className="NFT-image-upload-txt">
              <button
                onClick={handleButtonClick}
                disabled={
                  document?.status === "approved" ||
                  document?.status === "pending"
                }
                className="button-styling browse-btn"
              >
                <FiUpload />
                {document?.status === "approved"
                  ? `${t("new-changes.Completed")}`
                  : document?.status === "pending"
                  ? `${t("new-changes.processing")}`
                  : `${t("new-changes.Upload")}`}
              </button>
              <p>Jpg & PDF 200kb</p>
            </div>
            {type === "Birth certificate" && (
              <p className="footer-descc">{t("documents.birth_certificate")}</p>
            )}
            {type === "INE (Identification Document)" && (
              <p className="footer-descc">
                {t("documents.identification_Document")}
              </p>
            )}
            {type === "CURP (Unique Population Registry Code)" && (
              <p className="footer-descc">
                {t("documents.unique_Population_Registry_Code")}
              </p>
            )}
            {type === "RFC (Federal Taxpayer Registry)" && (
              <p className="footer-descc">
                {t("documents.federal_Taxpayer_Registry")}
              </p>
            )}
            {type === "Proof of address (maximum 2 months old)" && (
              <p className="footer-descc">{t("documents.proof_of_address")}</p>
            )}
            {type === "Letter of Proceeding Licita" && (
              <p className="footer-descc">
                {t("documents.letter_of_Proceeding_Licita")}
              </p>
            )}
            {type === "Fiscal Proof" && (
              <p className="footer-descc">{t("documents.fiscal_Proof")}</p>
            )}
            {type === "INE (Identification Document) Front" && (
              <>
                <p className="footer-descc">
                  {t("documents.identification_Document_front")}
                </p>
              </>
            )}
            {type === "INE (Identification Document) Back" && (
              <>
                <p className="footer-descc">
                  {t("documents.identification_Document_back")}
                </p>
              </>
            )}
            {/* // <p className='footer-descc'>{type}</p> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DocumentCard;
