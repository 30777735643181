import { JavaTimes, BranchFooter } from "./Images";
import { Fade } from "react-reveal";
function BranchCard(props) {
  return (
    <>
      <Fade top cascade>
        <div className="branch-card">
          {/* <img src={JavaTimes} /> */}
          <p className="section-title mb-0 dark-text text-uppercase">Java Times Caffe'</p>
          <p className="courgette text-capitalize">{props.title}</p>
          <p className="address">{props.address}</p>
          <img src={BranchFooter} />
        </div>
      </Fade>
    </>
  );
}

export default BranchCard;
