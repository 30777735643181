import CssBaseline from "@mui/material/CssBaseline";
import AdminSidebar from "components/admin/AdminSidebar";
import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import apis from "services";
import DonationShareTable from "../../components/datagrid/DonationShareTable";
import { DataGrid } from "@mui/x-data-grid";
import AdminNewSidebar from "components/admin/AdminNewSidebar";
import { Header } from "components";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import * as XLSX from "xlsx";

const DonationShareHistory = () => {
  let [transfer, setTransfer] = useState([]);

  const {t } = useTranslation();
  const [tab, setTab] = useState("all");

  const fetchDonationShareTransfer = async () => {
    const { data } = await apis.getAlltransferShares();
    if (data?.status) {
      setTransfer(data?.transfer);
    }
  };




  useEffect(() => {
    (async () => {
      await fetchDonationShareTransfer();
    })();
    }, []);

     const filteredData = tab === 'all' ? transfer : transfer.filter(item => item.type === tab);


  const { rows, columns } = DonationShareTable(filteredData);

  
  const downloadDonation = () => {
    // const filteredDonations = filteredData.map(({ isDeleted , _id , updatedAt  , ...rest }) => rest);
    const filteredDonations = filteredData.map(({ walletId, createdAt, share ,memo , type }) => ({
      walletid: walletId._id,
      userEmail: walletId.userId.email,
      username: walletId.userId.username,
      createdAt: createdAt, // Format the date as needed
      share,
      memo,
      type
    }));
    const worksheet = XLSX.utils.json_to_sheet(filteredDonations);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Selected Fields");
    XLSX.writeFile(workbook, "donation_data.xlsx");
  };

  return (
    <Box className="dashboard-main">
      <AdminNewSidebar />
      <div className="dashboard-section-main">
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
          className="custom-scroll"
        >
          <Header />
          <Container maxWidth="lg" sx={{ mt: 0, mb: 4 }}>
          <div className="flex-with-title">
            
            <h3 className="dashboard-title">
              {t("titles.donationShareHistory")}
            </h3>
            <button className="simple-btn my-2 flex-wrap" onClick={downloadDonation}>
                <img src="/images/excel.svg" alt="" />
              </button>
            </div>

            <div className="share-type d-flex justify-content-center mt-2">
              <Button
                className={tab === "all" ? "w-10 active_type " : "w-10"}
                variant="outline-danger"
                onClick={()=>setTab("all")}
              >
                All
              </Button>
              <Button
                className={tab === "reserve" ? "w-10 active_type " : "w-10"}
                variant="outline-danger"
                onClick={()=>setTab("reserve")}
              >
                Reserve
              </Button>
              <Button
                className={tab === "normal" ? "w-10 active_type " : "w-10"}
                variant="outline-danger"
                onClick={()=>setTab("normal")}
              >
                Normal
              </Button>
            </div>
            <Grid
              item
              container
              className="mt-4"
              justifyContent="flex-end"
              sx={{ height: "600px" }}
            >
              <DataGrid
                rows={rows}
                columns={columns}
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                }
              />
            </Grid>
          </Container>
        </Box>
      </div>
    </Box>
  );
};

export default DonationShareHistory;
