import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

function Link(props) {
  const { to, ...rest } = props;

  // Get the current language from local storage
  const lang = localStorage.getItem("i18nextLng") || 'sp';

  // Add the language prefix to the URL
  const path = `/${lang}${to}`;

  return <RouterLink to={path} {...rest} />;
}

export default Link;
