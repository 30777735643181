
import { useTranslation } from "react-i18next";
import { formatDate } from "utils/formatDate";


const SharePriceTable = (sharePrice = []) => {
    const { t } = useTranslation();
   
    const columns = [
        {field: 'id', headerName: 'No', width: 200, hide: false,},
        {field: 'sharePrice', headerName: t("fields.sharePrice"), width: 200},
        {field: 'targetAchieved', headerName: t("fields.targetAchieved"), width: 200},
        {field: 'nextTargetAchieved', headerName: t("fields.nexttargetAchieved"), width: 200},
        {field: 'status', headerName: t("fields.status"), width: 200}, 
        {field: 'createdAt', headerName: t("fields.createdAt"), width: 250},
  
      
    ];

    const rows = sharePrice.length === 0
        ? []
        : [
            ...sharePrice.map((item,index) => ({
                id: index+1,
                sharePrice: item["sharePrice"],
                targetAchieved: item['targetAchieved'],
                nextTargetAchieved: item['nextTargetAchieved'],
                status: item['active'],
                createdAt: formatDate(item['createdAt']),
               
            }))
        ];


    return {rows, columns}
}

export default SharePriceTable;
