
import { useTranslation } from "react-i18next";
import moment from "moment/moment";

import { IconButton} from "@mui/material";
import { formatDate } from "utils/formatDate";
import {
    ImageOutlined,
} from "@mui/icons-material";

const NotificationTable = (notifications = [],openImagePreview) => {
    const { t } = useTranslation();
   
    const columns = [
        {field: 'id', headerName: 'No', width: 200, hide: false,},
        {field: 'target', headerName: t("fields.target"), width: 200},
        {field: 'type', headerName: t("fields.type"), width: 200},
        {field: 'title', headerName: t("fields.title"), width: 200},
        {field: 'message', headerName: t("section_11.message"), width: 200}, 
        {field: 'createdAt', headerName: t("fields.createdAt"), width: 250},
  
        {field: 'image', headerName: t("fields.image"), width: 100, renderCell: (param) => <IconButton onClick={() => openImagePreview(param.value)}><ImageOutlined/></IconButton>},
      
    ];

    const rows = notifications.length === 0
        ? []
        : [
            ...notifications.map((notification,index) => ({
                id: index+1,
                target: notification["target"],
                type: notification['type'],
                title: notification?.notification['title'],
                message: notification?.notification['body'],
                createdAt: formatDate(notification['createdAt']),
                image: notification?.notification['image'],
               
            }))
        ];


    return {rows, columns}
}

export default NotificationTable
