import axios from "axios";

const createBackendServer = (baseURL) => {
    const api = axios.create({
        baseURL: `${baseURL}`,
        withCredentials: true,
        headers: {
            Accept: "application/json"
        },
        timeout: 60 * 1000,
    });


    //Interceptor
    api.interceptors.response.use(
        (response) => response,
        (error) => {
            const message = error?.response?.data?.message;
            error.message = message ?? error.message
            if (error?.response?.data?.errors)
                error.errors = error?.response?.data?.errors;


            if (error?.response?.status === 401) {
                window.location.href = "/sp/logout"; 
            }

            return Promise.reject(error)
        })

    const headers = {
        "Content-Type": "multipart/form-data",
    };

    /*==========    INVESTORS APIS    ==========*/
    const investorRegistration = async (body) => api.post('/api/investors/investor', body, { headers });
    const legalInvestorRegistration = async (body) => api.post('/api/investors/legal_investor', body, { headers });

    /*==========    Admin USER APIS    ==========*/

    const getTotalUsersCount = async () => api.get('/api/users/count');


    const deleteUser = async (id) => api.delete(`/api/users/delete/${id}`);
    const approveUser = async (id) => api.get(`/api/admin/verify/${id}`);
    const logoutUser = async () => api.get(`/api/users/logout`);

    /*==========    Admin STORE APIS    ==========*/
    const disableStore = async (id) => await api.put(`/api/store/disable/${id}`)
    const enableStore = async (id) => await api.put(`/api/store/enable/${id}`)

    /*==========    Admin Blog APIS    ==========*/

    const verifyUserDocuments = (id, type) => api.put(`/api/admin/verifiedDocument/${id}?type=${type}`);

    const updateUserProfile = (id, body) => api.put(`/api/users/profile/${id}`, body);

    const updateUserProfileToken = (id, body) => api.put(`/api/users/profile/notification/${id}`, body);
    const updateAdminProfileToken = (id, body) => api.put(`/api/admin/profile/notification/${id}`, body);

    
    const disableBlog = async (id) => await api.put(`/api/admin/blogs/disable/${id}`)
    const enableBlog = async (id) => await api.put(`/api/admin/blogs/enable/${id}`)

    const getAllActiveBlog = async () => api.get("/api/admin/blogs/active");

    const adminInvestUser = (id, body) => api.post(`/api/admin/invest/${id}`, body);
    const adminDeleteTransactionUser = (id) => api.get(`/api/admin/invest-rollback/${id}`);
    const getUserReferral = (id) => api.get(`/api/users/referral/${id}`);

    

    
    const contactUs = (body) => api.post(`/api/admin/contactUs`, body);

    const getAdminReferral = async () => await api.get('/api/admin/referral');

    const getTotalStoresCount = async () => await api.get('/api/store/count');

    const getAllUser = async () => api.get('/api/admin/allUser');
    const getAllStores = async () => api.get("/api/store/");
    const getAllActiveStores = async () => api.get("/api/store/all");
    const getCountTotalShares = () => api.get('/api/store/countStores');//soon
    const getCountAvailableShares = () => api.get('/api/store/countStores');//soon


    const getUserDocuments = (id) => api.get(`/api/users/documentUpload/${id}`);


    const getAdminProfile = async id => api.get(`/api/admin/profile/${id}`);
    const getWallet = (id) => api.get(`/api/wallet/${id}`);
    const getProfile = async id => api.get(`/api/users/profile/${id}`);
    const getStore = (id) => api.get(`/api/store/${id}`);
    const getAllStore = async () => await api.get('/api/store');
    const getTotalRecord = async () => await api.get('/api/users/countData');
    const getRecentUserInvest = async () => await api.get('/api/users/recentUserInvest');
    const getChartData = async () => await api.get('/api/admin/analyticStore');
    
    const getAnalyticsDataUser = async (id) => await api.get(`/api/users/analytics-app/${id}`);
    const getTransactions = async (data) => api.get(`/api/transactions?startDate=${data?.startDate}&endDate=${data?.endDate}`);
    const getAdminTransactions = async (data) => api.get(`/api/transactions/admin?startDate=${data?.startDate}&endDate=${data?.endDate}`);
    
    
    const getDividend = async () => api.get('/api/admin/dividend');
    const getAlltransferShares = async (type) => api.get(`/api/admin/transfer_shares`);
    const getAlltransferTokens = async (type) => api.get(`/api/admin/transfer_jtctokens`);
    const createSecretAdmin = async () => api.get('/api/users/createAdmin?master=fundraiser125%');
    const getAllBlogs = async () => await api.get('/api/admin/blogs');
    const getOneBlog = async (id) => await api.get(`/api/admin/blogs/${id}`);
    const getOneBlogById = async (id) => await api.get(`/api/admin/one-blogs/${id}`);
    const getUserAllDetails = async (id, type) => await api.get(`/api/admin/userAllDetails/${id}?type=${type}`);
    const getUserWithdrawl = async (id) => await api.get(`/api/withdrawl/user/${id}`);
    const getAllWithdrawl = async () => await api.get(`/api/withdrawl`);
    const getAllNewsFeet = async () => await api.get(`/api/admin/news`);
    const getAllWithdrawlAdmin = async () => await api.get(`/api/withdrawl-admin`);

    /*==========    POST REQUESTS    ==========*/

    const createOrder = async (body) => api.post("/api/invest/order", body);

   const adminSendNotification = async (body) => api.post("/api/admin/notification-send", body);
    const submitUserDocuments = async (id, body) => api.post(`/api/users/documentUpload/${id}`, body)

    const resetEmail = async (body) => api.post("/api/admin/reset-email", body);
    const forget = async (body) => api.post("/api/users/forget", body);
    const updatePassword = async (body) => api.post("/api/users/update", body);
    const authLogin = async body => api.post("/api/users/login", body)
    const verification = async (body) => api.post("/api/users/auth-verify", body);
    const kycForm = async (body) => api.post('/api/kyc/create', body, { headers });
    const saveContact = async (body) => api.post('/api/contact', body);
    const adminLogin = async (body) => api.post("/api/users/admin-login", body)
    const updateStore = async (body) => api.put(`/api/store`, body, { headers });
    const updateBlog = async ({ blogId, blogData }) => api.put(`/api/admin/blogs/${blogId}`, blogData, { headers });
    const transferShares = async (body) => api.post(`/api/admin/transfer_shares`, body);
    const transferTokens = async (body) => api.post(`/api/admin/transfer_jtctokens`, body);
    const registration = (body,code) => api.post(`/api/users?referralId=${code}`, body, { headers });
    const legalForm = (body) => api.post("/api/users/legal-Form", body, { headers });
    const investNow = (body) => api.post("/api/invest/investor/:id", body);
    const createStore = async (body) => api.post("/api/store", body, { headers });
    const createBlog = async (body) => api.post("/api/admin/blogs", body, { headers });
    const deleteBlogs = async (id) => api.delete("api/admin/blogs", { data: id });
    const deleteStores = async (id) => api.delete(`api/store/delete/${id}`);
    const invest = async (id, body) => api.post(`/api/invest/wallet_invest/${id}`, body)


    const generateLink = async (id) => api.post(`/api/users/referral/${id}`);

    const generateDividend = async (body) => api.post(`/api/invest/generate_dividend`, body);
    const cancelWithdrawl = async (id) => await api.put(`/api/withdrawl/cancel/${id}`);
    const addWithdrawl = async (body) => await api.post(`/api/withdrawl`, body);
    const addWithdrawlAdmin = async (body) => await api.post(`/api/withdrawl-admin`, body);
    const approvedWithdrawl = async (body) => await api.put(`/api/withdrawl/approve`, body);
    const addNewsFeet = async (body) => await api.post(`/api/admin/news`, body);
    const userKYC = async (id) => await api.get(`/api/kyc/${id}`);

    const submitSignature = async (id, body) => await api.put(`/api/kyc/sign-contract/${id}`, body);
    const userProfit = async (id) => await api.get(`/api/users/profit/${id}`);

    const getUserNotification = async (id) => await api.get(`/api/users/notification/${id}`);
    const getAdminNotificationRecieve = async (id) => await api.get(`/api/admin/notification/${id}`);

    
    const seenNotification = async (id) => await api.post(`/api/users/notification-seen/${id}`);

    
    const seenAdminNotificationHistory = async (id) => await api.post(`/api/admin/notification-seen/${id}`);

    
    const getAdminNotification = async () => await api.get(`/api/admin/admin-notification`);
    
    const getSharePriceHistory = async () => await api.get(`/api/admin/share-price-history`);
    


    
    const getUserNotificationDetails = async (id) => await api.get(`/api/users/notification/details/${id}`);

    


    const getAllAnnualProgress = async () => api.get('/api/admin/annual-progress');

    const deleteAnnualProgress = async (id) => api.delete(`api/admin/annual-progress/${id}`);

    const addAnnualProgress = async (body) => api.post('/api/admin/annual-progress',body);

    const sendAnnualProgressReportInvestor = async (body) => api.post('/api/admin/send-annual-progress',body);

    const approvedDocumentsUser = async (id) => await api.post(`/api/admin/user-document-approved/${id}`);
    
    //Returning all the API
    return {
        getAnalyticsDataUser,
        approvedDocumentsUser,
        sendAnnualProgressReportInvestor,
        deleteAnnualProgress,
        addAnnualProgress,
        getAllAnnualProgress,
        generateLink,
        getUserReferral,
        getAdminReferral,
        seenAdminNotificationHistory,
        getAdminNotificationRecieve,
        getUserNotificationDetails,
        getAdminNotification,
        getUserNotification,
        seenNotification,
        adminSendNotification,
        userProfit,
        invest,
        getStore,
        legalForm,
        authLogin,
        investNow,
        getWallet,
        getProfile,
        getAllUser,
        deleteUser,
        adminLogin,
        getAllStore,
        approveUser,
        enableStore,
        createStore,
        updateStore,
        saveContact,
        getAllStores,
        deleteStores,
        disableStore,
        registration,
        transferShares,
        getTransactions,
        generateDividend,
        createSecretAdmin,
        updateUserProfile,
        getTotalUsersCount,
        getAllActiveStores,
        getCountTotalShares,
        getTotalStoresCount,
        investorRegistration,
        getCountAvailableShares,
        legalInvestorRegistration,
        getTotalRecord,
        getRecentUserInvest,
        getChartData,
        getDividend,
        getAlltransferShares,
        getAllBlogs,
        getOneBlog,
        createBlog,
        deleteBlogs,
        updateBlog,
        disableBlog,
        enableBlog,
        getAllActiveBlog,
        getUserWithdrawl,
        getAllWithdrawl,
        cancelWithdrawl,
        addWithdrawl,
        approvedWithdrawl,
        addNewsFeet,
        getAllNewsFeet,
        getOneBlogById,
        logoutUser,
        kycForm,
        forget,
        updatePassword,
        getUserAllDetails,
        adminInvestUser,
        contactUs,
        addWithdrawlAdmin,
        getAllWithdrawlAdmin,
        resetEmail,
        createOrder,
        verification,
        getUserDocuments,
        submitUserDocuments,
        verifyUserDocuments,
        userKYC,
        submitSignature,
        adminDeleteTransactionUser,
        getAdminProfile,
        getAdminTransactions,
        updateUserProfileToken,
        updateAdminProfileToken,
        getSharePriceHistory,
        transferTokens,
        getAlltransferTokens
    };
};

const apis = createBackendServer(process.env.REACT_APP_SERVER_URL);

export default apis;
