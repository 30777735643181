import React, { useEffect, useState } from "react";
import { FaPhone } from "react-icons/fa";
import Link from "hooks/Link";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import apis from "services";

const CallToNews = () => {
  const { t } = useTranslation();

  const { user } = useSelector((store) => store.user);
  const lang = localStorage.getItem("i18nextLng") || "sp";

const [data,setData]=useState({
  "totalInvestor": 0,
  "totalStoreFundedAmount": 0,
  "TOTAL_SHARES": 120000000,
  "totalAvailableShares": 0,
  "TOTAL_PRICE_PER_SHARE": 0
});
  const fetchData = async () => {
    const res = await apis.getTotalRecord();
    setData(res?.data);
  };

  useEffect(()=>{
    fetchData();
  },[])

  function formatNumber(num) {
    return num.toLocaleString('en-US');
}
  console.log(data,"DATAAAA");
  return (
    <>
      <div className="join-banner-news">
        <a
          className="lp-news-btn fill square text-center"
        >

         {t("new-changes.news")}
        </a>
        {/* <img src="/assets/images/breaking1.png" alt="" /> */}

        <marquee behavior="scroll" direction="left">
          {/* {t("descs.news_message")} */}
       {lang == "en" ?
         `Available shares: ${formatNumber(data?.totalAvailableShares)}. Each share is priced at ${formatNumber(data?.TOTAL_PRICE_PER_SHARE)} MXN with a 30% premium. Capital needed to open more stores: ${formatNumber(data?.TOTAL_PRICE_PER_SHARE * data?.totalAvailableShares)} MXN. Total amount financed for stores: ${formatNumber(data?.totalStoreFundedAmount)} MXN. Currently, there is one store open out of a total of 300.`
       :
       `Acciones disponibles: ${formatNumber(data?.totalAvailableShares)}. Cada acción tiene un precio de ${formatNumber(data?.TOTAL_PRICE_PER_SHARE)} MXN con un recargo del 30%. Capital necesario para abrir más tiendas: ${formatNumber(data?.TOTAL_PRICE_PER_SHARE * data?.totalAvailableShares)} MXN. Monto total financiado para las tiendas: ${formatNumber(data?.totalStoreFundedAmount)} MXN. Actualmente, hay una tienda abierta de un total de 300.`
       
       }
        </marquee>
        {/* <marquee>HELLO WORLD</marquee> */}
      </div>
    </>
  );
};

export default CallToNews;
