import React from 'react'
import imageUrl from 'utils/imageUrl';
const PageLoader = () => {
    return (
        <div className='pageloader'>
            <div>
                <img src={imageUrl("logo.webp")} alt="" />
            </div>
        </div>
    )
}

export default PageLoader;