import apis from "services";
import { toast } from "react-toastify";
import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { InputAdornment } from "@mui/material";

import useNavigate from "hooks/Navigate";

import CustomTextField from "../ui/CustomTextField";
import { AccountBalance, Person} from "@mui/icons-material";
import NumbersIcon from '@mui/icons-material/Numbers';

import { useDispatch, useSelector } from "react-redux";
import { setUser, setUserType } from "../../redux/slices/userSlice";
import { useTranslation } from "react-i18next";
import TermsAndConditionInvestModal from "components/modals/TermsAndConditionInvestModal";

import CertificateConditionModal from "components/modals/CertificateConditionModal";

const textFieldStyle = { color: "var(--primary-red)" };

const KYCForm = ({ toggleModal, signature }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();


    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({ isCertificate: false, isAcceptTerms: false });



    const [showModalInvest, setShowModalInvest] = useState(false);

    const [showModalTerms, setShowModalTerms] = useState(false);

    const toggleModalInvest = () => {
        setShowModalInvest((prevState) => !prevState)
    }

    const toggleModalTerms = () => {
        setShowModalTerms((prevState) => !prevState)
    }

    const { user } = useSelector((store) => store.user);

    const onChangeHandler = (e) => {
        const { id, value } = e.target;
        setState(prevState => ({ ...prevState, [id]: value }))
    }
    const onChangeHandlerConditions = (name, value) => {
        console.log(name, value)
        if (name === "isAcceptTerms") {
            setState(prevState => ({ ...prevState, "isAcceptTerms": true }))
        }
        if (name === "isCertificate") {
            setState(prevState => ({ ...prevState, "isCertificate": true }))
        }
    }


    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = new FormData(e.currentTarget)
        const formData = Object.fromEntries(data);

        console.log(formData, "formData")

        if (state.isCertificate && state.isAcceptTerms) {

            const data = { ...formData, userId: user?.id, isCertificate: state.isCertificate, isAcceptTerms: state.isAcceptTerms }

            try {
                setLoading(true);
                const res = await apis.kycForm(data);
                if (res?.data?.status) {
                    const user = res?.data?.user
                    toast.success(res.data?.message);
                    if (user) {
                        dispatch(setUser({ id: user?._id, ...user }));
                    }
                    if (signature) {

                        toggleModal("open")
                    }
                    else {
                        toggleModal()

                    }
                }
            } catch (error) {
                setLoading(false);
                toast.error(error.message);
            }
        }
    };

    return (
        <>
            <Form onSubmit={handleSubmit} enctype="multipart/form-data">
                <Row>
                    <Col md={12} lg={12} className="my-3">
                        <CustomTextField
                            id="name"
                            label="Name"
                            name='name'
                            variant="standard"
                            // onChange={onChangeHandler}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Person sx={textFieldStyle} />
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            required
                        />
                    </Col>
                    {/* <Col md={12} lg={6} className="my-3">
                        <CustomTextField
                            id="firstName"
                            name="firstName"
                            label={t("labels.first_name")}
                            variant="standard"
                            // onChange={onChangeHandler}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Person sx={textFieldStyle}/>
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            required
                        />
                    </Col>
                     */}
                    {/* <Col md={6} lg={6} className="my-3">
                        <CustomTextField
                            id="lastName"
                            name="lastName"
                            label={t("labels.last_name")}
                            variant="standard"
                            // onChange={onChangeHandler}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Person sx={textFieldStyle}/>
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            required
                        />
                    </Col> */}
                    <Col md={12} lg={12} className="my-3">
                        <CustomTextField
                            id="rfcNo"
                            name="rfcNo"
                            label="RFC Number"
                            variant="standard"
                            // onChange={onChangeHandler}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <NumbersIcon sx={textFieldStyle} />
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            required
                        />
                    </Col>
                </Row>

                <Row>
                    {/* <Col md={6} lg={4} className="my-3">
                        <CustomTextField
                            id="fatherName"
                            name="fatherName"
                            label="Father Name"
                            variant="standard"
                           // onChange={onChangeHandler}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Person sx={textFieldStyle}/>
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            required
                        />
                    </Col>
                    <Col md={6} lg={4} className="my-3">
                        <CustomTextField
                            id="motherName"
                            name="motherName"
                            label="Mother Name"
                            variant="standard"
                           // onChange={onChangeHandler}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Person sx={textFieldStyle}/>
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            required
                        />
                    </Col> */}
                    <Col md={12} lg={12} className="my-3">
                        <CustomTextField
                            id="address"
                            name="address"
                            label={t("labels.address")}
                            variant="standard"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountBalance sx={textFieldStyle} />
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            required
                        // onChange={onChangeHandler}
                        />
                    </Col>
                    <Col md={12} lg={12} className="my-2">
                        <Form.Group className="mb-2" >
                            <Form.Check
                                type="checkbox"
                                id="isAcceptTerms"
                                name="isAcceptTerms"
                                label={t("labels.isAcceptTerms")}
                                checked={state.isCertificate}

                                onChange={toggleModalTerms}
                                fullWidth
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col md={12} lg={12} className="my-1">
                        <Form.Group className="mb-2">
                            <Form.Check
                                type="checkbox"
                                id="isCertificate"
                                name="isCertificate"
                                label={t("labels.isCertificate")}
                                checked={state.isAcceptTerms}
                                onChange={toggleModalInvest}
                                fullWidth
                                required
                            />
                        </Form.Group>
                    </Col>

                    {/*                     
                    <Col md={6} lg={4} className="my-3">
                                <Form.Group className="mb-3">
                                    
                                <Form.Text className="text-muted">
                                Upload ID/Passport/Driving License
                                    </Form.Text>
                                    <Form.Control
                                        type="file"
                                        id="file"
                                        name="file"
                                        // onChange={onChangeHandler}
                                        required
                                    />
                                </Form.Group>
                            </Col> */}
                    {/* <Col md={6} lg={4} className="my-3">
                                <Form.Group className="mb-3">
                                    
                                <Form.Text className="text-muted">
                                Number of ID/Passport/Driving License
                                    </Form.Text>
                                    <Form.Control
                                        type="text"
                                        id="passportNo"
                                        name="passportNo"
                                        // onChange={onChangeHandler}
                                        required
                                    />
                                </Form.Group>
                            </Col> */}

                    {/*<Col md={6} lg={4} className="my-3">
                        <CustomTextField
                            id="email"
                            name="email"
                            label="Email Address"
                            type="email"
                            variant="standard"
                           // onChange={onChangeHandler}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Email sx={textFieldStyle}/>
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            required
                        />
                    </Col>*/}
                </Row>

                {/*<Row >
                    <Col md={6} lg={4} className="my-3">
                        <CustomTextField
                            id="password"
                            name="password"
                            label="Password"
                            type={togglePassword.password ? "text" : "password"}
                            variant="standard"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Person sx={textFieldStyle}/>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton size="small" onClick={() => toggle("password")}>
                                            {togglePassword.password ? (
                                                <VisibilityOff sx={textFieldStyle}/>
                                            ) : (
                                                <Visibility sx={textFieldStyle}/>
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                           // onChange={onChangeHandler}
                            required
                        />
                    </Col>
                    <Col md={6} lg={4} className="my-3">
                        <CustomTextField
                            id="confirmPassword"
                            name="confirmPassword"
                            label="Password Confirmation"
                            type={togglePassword.confirmPassword ? "text" : "password"}
                           // onChange={onChangeHandler}
                            variant="standard"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Person sx={textFieldStyle}/>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            size="small"
                                            onClick={() => toggle("confirmPassword")}
                                        >
                                            {togglePassword.confirmPassword ? (
                                                <Visibility sx={textFieldStyle}/>
                                            ) : (
                                                <VisibilityOff sx={textFieldStyle}/>
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            required
                        />
                    </Col>
                </Row>*/}
                <Row className="justify-content-center">
                    <Col md={4}>


                        <button
                            className='lp-btn my-4 w-100'
                            variant="outlined"
                            type="submit"
                            fullWidth
                            disabled={loading}
                        >
                            {loading ? t("buttons.submitting") : t("buttons.submit")}
                        </button>

                    </Col>
                </Row>
            </Form>

            <TermsAndConditionInvestModal toggleModal={toggleModalInvest}
                status={showModalInvest}
                onChangeHandlerConditions={onChangeHandlerConditions}
            />
            <CertificateConditionModal
                toggleModal={toggleModalTerms}
                status={showModalTerms}
                onChangeHandlerConditions={onChangeHandlerConditions}
            />
        </>
    );
};

export default KYCForm;
