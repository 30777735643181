import { Modal, Row, Button, Col } from "react-bootstrap";

import { useEffect, useState } from "react";
import CreateSignature from "components/CreateSignature";
import { useSelector } from "react-redux";
import PDFViewer from "components/PDFViewer";
import apis from "services";
import { CircularProgress } from "@mui/material";

const WithdrawlReceiptModal = ({ status, toggleModal, data }) => {
 console.log(data,'filefilefile')
  return (
    <Modal
      show={status}
      onHide={toggleModal}
      centered
      size="lg"
      className="invest-options-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div>
            <h4>Withdrawl Reciept</h4>
            <Button
              className="lp-btn mt-4"
              variant="outline-danger"
              type="submit"
            >
              <a href={data?.file} target="_blank" download>
                Download Now
              </a>
            </Button>

          </div>

        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
       
          <Row>
            <Col>
              <PDFViewer file={data?.file} status={false}></PDFViewer>
            </Col>
          </Row>
      </Modal.Body>
    </Modal>
  );
};

export default WithdrawlReceiptModal;
