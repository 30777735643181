import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import i18n from 'i18next';
import { SUB_ADMIN_TYPES } from "assets/constants";

const ProtectedRoute = ({ isAdmin = false, isSubAdmin = false, auth = false }) => {
    const { pathname } = useLocation();
    const { user } = useSelector((store) => store.user);
    const { adminUser } = useSelector((store) => store.admin);

    if (auth) {
        if (!user || !adminUser) {
            return user || adminUser ? <Outlet /> : redirectToPath(`/${i18n.language}/registration`);
        }
    } else if (isAdmin) {
        return adminUser?.type === "owner" ? <Outlet /> : redirectToPath(`/${i18n.language}/admin-login`);
    } else if (isSubAdmin) {
        return SUB_ADMIN_TYPES.includes(adminUser?.type) ? <Outlet /> : redirectToPath(`/${i18n.language}/admin-login`);
    } else {
        if (user) {
            return user ? <Outlet /> : redirectToPath(`/${i18n.language}/login`);
        } else {
            toast.error('Register yourself first ...', { toastId: 123 });
            return redirectToPath('/');
        }
    }

    function redirectToPath(path) {
        window.location.replace(path)
    }
}

export default ProtectedRoute;
