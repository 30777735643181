import { CircularProgress } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SignatureCanvas from "react-signature-canvas";
import { toast } from "react-toastify";
import { setUser } from "redux/slices/userSlice";

import apis from "services";

const CreateSignature = ({ toggleModalSignature }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const { user } = useSelector((store) => store.user);

  const [loadingFonts, setLoadingFonts] = useState(true);

  useEffect(() => {
    handleGenerateSignatures();
  }, []);

  useEffect(() => {
    if (loadingFonts) {
      const delay = setTimeout(() => {
        setLoadingFonts(false);
      }, 1000);

      return () => clearTimeout(delay);
    }
  }, [loadingFonts]);

  const [selectedOption, setSelectedOption] = useState("pad");
  const [signatures, setSignatures] = useState([]);
  const [selectedSignature, setSelectedSignature] = useState(null);
  
  const [firstTimeLoading, setFirstTimeLoading] = useState(true);

  const [loading, setLoading] = useState(false);

  const [capturedSignature, setCapturedSignature] = useState(null);
  const signatureCanvasRef = useRef();

  const [family, setFamily] = useState([
    "Pacifico",
    "Parisienne",
    "Shrikhand",
    "Rock_Salt",
    "Arizonia",
  ]);
  const handleGenerateSignatures = () => {
    if (name === "" && !firstTimeLoading) {
      return;
    }
    setLoadingFonts(true);
    const canvasList = [];
    const canvasSize = { width: 200, height: 100 };

    for (let i = 0; i < family.length; i++) {
      const canvas = document.createElement("canvas");
      canvas.width = canvasSize.width;
      canvas.height = canvasSize.height;

      const ctx = canvas.getContext("2d");
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, canvasSize.width, canvasSize.height);

      ctx.font = `25px ${family[i]}`;
      ctx.fillStyle = "black";
      ctx.fillText(name, 20, 80);

      canvasList.push(canvas.toDataURL());
    }
    if (!firstTimeLoading) {
      setSignatures(canvasList);
      setCapturedSignature(canvasList[0]);
    }
    setFirstTimeLoading(false);
  };

  const clearCanvas = () => {
    signatureCanvasRef.current.clear();
    setSelectedSignature(null);
  };

  const handleCaptureSignature = () => {
    if (selectedOption === "pad" && !signatureCanvasRef.current.isEmpty()) {
      const signatureDataURL = signatureCanvasRef.current.toDataURL();
      setSelectedSignature(signatureDataURL);
    } else if (selectedOption === "text" && selectedSignature) {
      setCapturedSignature(selectedSignature);
    } else {
      toast.error("Please select or generate a signature first.");
    }
  };

  const handleSubmit = async () => {
    let formData = new FormData();
if(name === "" && selectedOption === "text")
{
return toast.error("Please select or generate a signature first.");
}
else if ( selectedSignature === "" && selectedOption === "pad"){
return toast.error("Please sign a signature first.");
}
    
    if (selectedOption === "pad") {
      formData.append("image", selectedSignature);
    } else {
      formData.append("image", capturedSignature);
    }
    setLoading(true);
    try {
      const res = await apis.submitSignature(user?.id, formData);
      if (res?.data?.status) {
         let user = res?.data?.user
        toast.success(res?.data?.message);
        if (res?.data?.user) {
          dispatch(setUser({ id: user?._id, ...user }));
        }
        if (res?.data?.kyc) {
          toggleModalSignature("success")
        }
      }
      else{
        toast.error(res?.data?.message);
      }
      setLoading(false);
    } catch (e) {
      toast.error(e?.message);
      setLoading(false);

    }
  };

  return (

        <div className="signature-sec">
          <div className="top">
            <div className="top-1">
              <label>
                <input
                  type="radio"
                  value="pad"
                  checked={selectedOption === "pad"}
                  onChange={() => setSelectedOption("pad")}
                />
                Sign on Pad
              </label>
              <label>
                <input
                  type="radio"
                  value="text"
                  checked={selectedOption === "text"}
                  onChange={() => setSelectedOption("text")}
                />
                Sign by Text
              </label>
            </div>
            {selectedOption === "pad" && (
              <div className="top-2">
                <SignatureCanvas
                  ref={signatureCanvasRef}
                  penColor="black"
                  canvasProps={{
                    width: 200,
                    height: 100,
                    style: { border: "1px solid black" },
                    id: "convas",
                  }}
                />
                <button onClick={handleCaptureSignature}>
                  Capture Signature
                </button>

                <button className="mt-1" onClick={clearCanvas}>Clear</button>

                <div className="d-flex justify-content-center align-item-center mt-3">
                  {selectedSignature && (
                    <img src={selectedSignature} alt="Selected Signature" />
                  )}
                </div>
                {selectedSignature && (
                 
                <div className="Login-btn-container">
                  <button
                    type="submit"
                    disabled={loading}
                    onClick={handleSubmit}
                  >
                    {loading ? "Applying" : "Applied"}
                  </button>
                </div>
                )}
              </div>
            )}

            {selectedOption === "text" && (
              <div className="top-3">
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter your name"
                />
                <button onClick={handleGenerateSignatures}>
                  Generate Signatures
                </button>
              </div>
            )}
          </div>
          <div className="bottom">
            {loadingFonts ? (
              <div className="d-flex justify-content-center align-items-center m-5">
                <CircularProgress color="error" />
              </div>
            ) : (
              <>
                {selectedOption === "text" && (
                  <div className="d-flex flex-columns flex-wrap">
                    {signatures.map((signature, index) => (
                      <div
                        key={index}
                        className={`signature-option ${
                          capturedSignature == signature
                            ? "signature-active"
                            : ""
                        }`}
                        onClick={() => {
                          // handleCaptureSignature()
                          setCapturedSignature(signature);
                        }}
                      >
                        <img src={signature} alt={`Signature ${index + 1}`} />
                      </div>
                    ))}
                    {capturedSignature && (
                      <div className="captured-signature">
                        {/* <img src={capturedSignature} alt="Captured Signature" /> */}
                        <button onClick={handleSubmit}>
                          {" "}
                          {loading ? "Applying" : "Applied"}
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
  );
};

export default CreateSignature;
