import {Outlet} from "react-router-dom";
import useLocationNavigation from "../hooks/useLocationNavigation";
import useLanguageUrl from "hooks/useLanguageUrl";

export default function Language() {
    // useLocationNavigation();

    useLanguageUrl()
    //Check if no language defined then assign default language
    //Check if no language defined then assign default language
    return <Outlet/>
}