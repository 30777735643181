import { Grid, TextField, Typography, FormControlLabel, Checkbox, Button, Box, Alert,Input, InputLabel, MenuItem, Select, FormControl, FormLabel, RadioGroup, Radio, FormGroup, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Avatar } from '@mui/material';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import apis from '../../services';

const UpdateStore = () => {
    const [storeName, setName] = useState("")
    const [title, setTitle] = useState("")
    const [location, setLocation] = useState("")
    const [description, setDescription] = useState("")
    const [totalShares, setTotalShares] = useState(0)
    const [pimage, setPimage] = useState("")
    const [sharePerPrice, setSharePerPrice] = useState(0)
    const [dividend, setDividend] = useState(0)
    
    const [error, setError] = useState({
      status: false,
      msg: "",
      type: ""
    })

    const dispatch = useDispatch();
    const param = useParams();
    const storeId = param.id ;

    const storeDetails=async()=>{
        await dispatch(apis.getStore(storeId))
        .then(async (res)=>{
         const result = res?.payload?.data?.store

         setName(result.storeName)
         setTitle(result.title)
         setLocation(result.location)
         setDescription(result.description)
         setTotalShares(result.totalShares)
         setSharePerPrice(result.sharePerPrice)
         setDividend(result.dividend)

     }).catch((err)=>{
         console.log(err);
     })
    }
    useEffect(() => {
        storeDetails();
}, []);
        
    const storeUpdated=async()=>{
        
          let data = {storeName,title,location,description,totalShares,totalShares,sharePerPrice,dividend};
          
            await dispatch(apis.storeUpdate(storeId , data))
            .then(async (res)=>{
              

         }).catch((err)=>{
             console.log(err);
         })
        }
  return (
    <>
    <Box display="flex" justifyContent="center" sx={{ backgroundColor: 'error.light', padding: 2 }}>
    <Typography variant='h2' component="div" sx={{ fontWeight: 'bold', color: 'white' }}>Store Updated</Typography>
  </Box>
  <Grid container justifyContent="center">

    <Grid item xs={5}>
      <Box component="form" sx={{ p: 5 }} noValidate id="resume-form"><br/><br/>
        Name <input type="text" required value={storeName} onChange={(e) => setName(e.target.value)}></input><br/><br/>
        Title <input type="text" required value={title} onChange={(e) => setTitle(e.target.value)}></input><br/><br/>
        Location <input type="text" required value={location} onChange={(e) => setLocation(e.target.value)}></input><br/><br/>
        Description <input type="text" required value={description} onChange={(e) => setDescription(e.target.value)}></input><br/><br/>
        TotalShares <input type="number" required value={totalShares} onChange={(e) => setTotalShares(e.target.value)}></input><br/><br/>
        SharePerPrice <input type="number" required value={sharePerPrice} onChange={(e) => setSharePerPrice(e.target.value)}></input><br/><br/>
        Dividend <input type="number" required value={dividend} onChange={(e) => setDividend(e.target.value)}></input><br/><br/>
{/*        
        <TextField id="name" name="name" value={name} required fullWidth margin='normal'onChange={(e) => setName(e.target.value)} />
        <TextField id="title" name="title" value={title} required fullWidth margin='normal' label='Title' onChange={(e) => setTitle(e.target.value)} />
        <TextField id="location" name="location" value={location} required fullWidth margin='normal' label='Location' onChange={(e) => setLocation(e.target.value)} />
        <TextField id="description" name="description"  value={description} required fullWidth margin='normal' label='description' onChange={(e) => setDescription(e.target.value)} />
        <TextField id="totalShares" name="totalShares"  value={totalShares} required fullWidth margin='normal' label='totalShares' onChange={(e) => setTotalShares(e.target.value)} />
        <TextField id="sharePerPrice" name="sharePerPrice" value={sharePerPrice}  required fullWidth margin='normal' label='sharePerPrice' onChange={(e) => setSharePerPrice(e.target.value)} />
        <TextField id="dividend" name="dividend" required  value={dividend} fullWidth margin='normal' label='dividend' onChange={(e) => setDividend(e.target.value)} />
         */}
        {/* <Stack direction="row" alignItems="center" spacing={4} >
          <label htmlFor='profile-photo'>
            <Input accept="image/*" id="profile-photo" type="file" onChange={(e) => { setPimage(e.target.files[0]) }} />
          </label>
        </Stack> */}
        <Button variant='contained' sx={{ mt: 3, mb: 2, px: 5 }} onClick={storeUpdated} color="error">Save</Button>
        {error.status ? <Alert severity={error.type}>{error.msg}</Alert> : ''}
      </Box>
    </Grid>
  </Grid>
  </>
  )
}

export default UpdateStore