import React, {Fragment} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import {STATES} from "../../assets/constants";
import utils from "utils";
import {Avatar, IconButton} from "@mui/material";

import PhoneInput from "react-phone-input-2";
import {PhotoCameraRounded, Visibility, VisibilityOff} from "@mui/icons-material";
const AddUserForm = ({formData, submitHandler, onChangeHandler, heading,setPhone,phone,onPhoneHandler}) => {

    const submit = (e) => {
        e.preventDefault();
        submitHandler(formData);
    }

    return (
        <Fragment>
            <Form onSubmit={submit} className='my-5'>
                  {/* <Row className="my-3">
                                        <div className="d-flex justify-content-center position-relative">
                                            <Avatar sx={{width: '6em', height: '6em'}}
                                                    src={utils.createObjectURL(formData.image)}/>
                                            <IconButton aria-label="upload picture"
                                                        sx={{position: "absolute", bottom: 0, left: '52%'}}
                                                        component="label">
                                                <input hidden name="image" accept="image/*" type="file" onChange={onChangeHandler}/>
                                                <PhotoCameraRounded sx={{color: "#404040"}}/>
                                            </IconButton>
                                        </div>
                                    </Row>
                                    */}
                <Row className='d-flex justify-content-center align-items-center'>
                    
                    <Col xs={12} md={6} lg={4}>
                        <Form.Group className="mb-3" controlId="title">
                            <Form.Label>Full Name</Form.Label>
                            <Form.Control type="text" name='name' placeholder='Full Name'
                                          onChange={onChangeHandler} value={formData.name}/>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <Form.Group className="mb-3" controlId="nickName">
                            <Form.Label>Nick Name</Form.Label>
                            <Form.Control type="text" name='nickName' placeholder='Nick Name'
                                          onChange={onChangeHandler} value={formData.nickName}/>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" name='email' placeholder='Email'
                                          onChange={onChangeHandler} value={formData.email}/>
                        </Form.Group>
                    </Col>
                    {/* <Col xs={12} md={6} lg={6}>
                        <Form.Group className="mb-3" controlId="phone">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control type="number" name='phone' placeholder='Phone Number'
                                          onChange={onChangeHandler} value={formData.phone}/>
                        </Form.Group>
                    </Col> */}
                    {/* <Col xs={12} lg={6}>
                                            <Form.Group  className="mb-3" controlId="phoneNo">
                                                <Form.Label>Phone Number</Form.Label>
                                                <PhoneInput
                                                    country="mx"
                                                    inputProps={{name: 'phone'}}
                                                    inputStyle={{width: "100%"}}
                                                    value={phone}
                                                    // onChange={(e)=>onPhoneHandler(e)}
                                                />
                                            </Form.Group>
                                        </Col> */}
                                        
                    <Col xs={12} md={8} lg={4}>
                        <Button className='w-100 mt-3' variant="outline-danger" type='submit'> {heading} User</Button>
                    </Col>
                </Row>
            </Form>
            
        </Fragment>
    )
}

export default AddUserForm
