import React, {Fragment} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";

const AddAnnualProgressForm = ({formData, submitHandler, onChangeHandler, heading}) => {

    const submit = (e) => {
        e.preventDefault();
        submitHandler(formData);
    }

    return (
        <Fragment>
            <Form onSubmit={submit} className='my-5'>
                 
                <Row className='d-flex justify-content-center align-items-center'>
                    
                    <Col xs={12} md={6} lg={7}>
                        <Form.Group className="mb-3" controlId="title">
                            <Form.Label>Title</Form.Label>
                            <Form.Control type="text" name='title' placeholder='Title' required
                                          onChange={onChangeHandler} value={formData.title}/>
                        </Form.Group>
                    </Col>
     
                    <Col xs={12} md={6} lg={7}>
                        <Form.Group className="mb-3" controlId="postalCode">
                            <Form.Label>File</Form.Label>
                            <Form.Control accept=".pdf" type="file" name='file' placeholder='File' required
                                          onChange={onChangeHandler}/>
                        </Form.Group>
                    </Col>
                   
                                        
                    <Col xs={12} md={8} lg={7}>
                        <Button className='w-100 mt-3' variant="outline-danger" type='submit'> {heading} Annual Progress</Button>
                    </Col>
                </Row>
            </Form>
            
        </Fragment>
    )
}

export default AddAnnualProgressForm
