import React, { useState,useEffect } from 'react';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFViewer = ({status = true ,file}) => {
const [numPages,setNumPages] = useState(null)
  function onDocumentLoadSuccess ({numPage}){
    setNumPages(numPage)
  }
  return (
    <div className='pdf-container'>
      <Document file={file}  onLoadSuccess={onDocumentLoadSuccess}>
       
       {status ? <>
       
        <Page pageNumber={1} 
        renderTextLayer={false}
        renderAnnotationLayer={false}
        customTextRenderer={false} />

<Page  pageNumber={2} 
        renderTextLayer={false}
        renderAnnotationLayer={false}
        customTextRenderer={false} />

<Page  pageNumber={3} 
        renderTextLayer={false}
        renderAnnotationLayer={false}
        customTextRenderer={false} />


<Page  pageNumber={4} 
        renderTextLayer={false}
        renderAnnotationLayer={false}
        customTextRenderer={false} />


<Page  pageNumber={5} 
        renderTextLayer={false}
        renderAnnotationLayer={false}
        customTextRenderer={false} />

       </> : <>
        <Page pageNumber={1} 
        renderTextLayer={false}
        renderAnnotationLayer={false}
        customTextRenderer={false} /></>}
  

      </Document>


    </div>
  );
};

export default PDFViewer;
