import React, { useEffect } from "react";
import { CircularProgress } from "@mui/material";
import apis from "services";
import { useDispatch } from "react-redux";
import { logout } from "redux/slices/userSlice";
import { walletNull } from "redux/slices/walletSlicer";
import { adminLogout } from "redux/slices/adminSlice";

const Logout = () => {
    const dispatch = useDispatch();
   
    useEffect(() => {
        const logoutAndRedirect = async () => {
            try {
                dispatch(logout());
                dispatch(walletNull());
                dispatch(adminLogout());
                await apis.logoutUser();
                setTimeout(()=>{
                    window.location.href = "/login"
                },2000)
            } catch (error) {
                console.error("Logout failed:", error);
               setTimeout(()=>{
                    window.location.href = "/login"
                },2000)
            }
        };

        logoutAndRedirect();
    }, []);

    return (
        <>
            <div className="w-100 min-vh-100 d-flex justify-content-center align-items-center">
                <CircularProgress color="error" />
            </div>
        </>
    );
};

export default Logout;
