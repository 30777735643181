import React, {Fragment} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import {STATES} from "../../assets/constants";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


const AddStoreForm = ({formData, submitHandler, onChangeHandler, addPitch, removePitch, addFile, removeFile,setStoreData, heading,storeId}) => {
    const submit = (e) => {
        e.preventDefault();
        submitHandler(formData);
        e.currentTarget.reset();
    };

    const handleChange = (field , index) => (event, editor) => {

        const data = editor.getData();
       console.log(field,'fieldfieldfield')
       
        if (["pitchTitleEnglish", "pitchTitleSpanish","pitchDescriptionEnglish","pitchDescriptionSpanish"].includes(field)) {
            const pitches = [...formData.pitches];
            pitches[index] = {
                ...pitches[index],
                [field]: data,
            };
             setStoreData((prevState) => ({ ...prevState, pitches }));
        }else{
            setStoreData((prevState) => ({
                ...prevState,
                [field]: data
              }));
        }
     
      };

    return (
        <Fragment>
            <Form onSubmit={submit} className='my-5'>
                <Row className='d-flex justify-content-center align-items-center'>
                    <Col xs={12} md={6} lg={4}>
                        <Form.Group className="mb-3" controlId="title_en">
                            <Form.Label>Title English</Form.Label>
                            {/* <Form.Control type="text" name='title_en' placeholder='Store title english' required
                                          onChange={onChangeHandler} value={formData.title_en}/> */}


<CKEditor
          editor={ClassicEditor}
          data={formData.title_en}
          onChange={handleChange('title_en')}
        />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <Form.Group className="mb-3" controlId="title_sp">
                            <Form.Label>Title Spanish</Form.Label>
                            {/* <Form.Control type="text" name='title_sp' placeholder='Store title spanish' required
                                          onChange={onChangeHandler} value={formData.title_sp}/> */}
                      
<CKEditor
          editor={ClassicEditor}
          data={formData.title_sp}
          onChange={handleChange('title_sp')}
        />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <Form.Group className="mb-3" controlId="description_en">
                            <Form.Label>Description English</Form.Label>
                            {/* <Form.Control as="textarea" type="text" name='description_en' placeholder='Store description english'
                                          onChange={onChangeHandler} value={formData.description_en}/> */}
                      
                      <CKEditor
          editor={ClassicEditor}
          data={formData.description_en}
          onChange={handleChange('description_en')}
        />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <Form.Group className="mb-3" controlId="description_sp">
                            <Form.Label>Description Spanish</Form.Label>
                            {/* <Form.Control as="textarea" type="text" name='description_sp' placeholder='Store description spanish'
                                          onChange={onChangeHandler} value={formData.description_sp}/>
                        */}
                               <CKEditor
          editor={ClassicEditor}
          data={formData.description_sp}
          onChange={handleChange('description_sp')}
        />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <Form.Group className="mb-3" controlId="state">
                            <Form.Label>State</Form.Label>
                            <Form.Select name='state' onChange={onChangeHandler} value={formData.location.state}>
                                <option>Select State...</option>
                                {STATES.map(state => <option key={state}>{state}</option>)}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <Form.Group className="mb-3" controlId="city">
                            <Form.Label>City</Form.Label>
                            <Form.Control type="text" name='city' placeholder='City' required
                                          onChange={onChangeHandler} value={formData.location.city}/>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <Form.Group className="mb-3" controlId="address">
                            <Form.Label>Address</Form.Label>
                            <Form.Control type="text" name='address' placeholder='Address' required
                                          onChange={onChangeHandler} value={formData.location.address}/>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <Form.Group className="mb-3" controlId="postalCode">
                            <Form.Label>Postal Code</Form.Label>
                            <Form.Control type="text" name='postalCode' placeholder='Postal code' required
                                          onChange={onChangeHandler} value={formData.location.postalCode}/>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <Form.Group className="mb-3" controlId="totalShares">
                            <Form.Label>Total Shares</Form.Label>
                            <Form.Control type="number" name='totalShares' placeholder='Total Shares' required
                                          onChange={onChangeHandler} value={formData.totalShares}/>
                        </Form.Group>
                    </Col>
                    {/* <Col xs={12} md={6} lg={4}>
                        <Form.Group className="mb-3" controlId="image">
                            <Form.Label>Cover Image</Form.Label>
                            <Form.Control type="file" name='image' placeholder='Store image' required
                                          onChange={onChangeHandler}/>
                        </Form.Group>
                    </Col> */}

                    <Col xs={12}>
                        <h5 className="mb-3 mt-2">Pitches</h5>
                    </Col>

                    {formData.pitches.map((pitch, index) => (
                        <Fragment key={index}>
                            <Col xs={12} md={6} lg={4}>
                                <Form.Group className="mb-3" controlId={`pitchTitleEnglish${index}`}>
                                    <Form.Label>Pitch Title English</Form.Label>
                                    {/* <Form.Control
                                        type="text"
                                        required
                                        name="pitchTitleEnglish"
                                        placeholder="Pitch title english"
                                        onChange={(e) => onChangeHandler(e, index, 'pitches')}
                                        value={pitch.pitchTitleEnglish}
                                    /> */}
               <CKEditor
          editor={ClassicEditor}
          data={pitch.pitchTitleEnglish}
          onChange={handleChange('pitchTitleEnglish',index)}
        />


                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6} lg={4}>
                                <Form.Group className="mb-3" controlId={`pitchTitleSpanish${index}`}>
                                    <Form.Label>Pitch Title Spanish</Form.Label>
                                    {/* <Form.Control
                                        type="text"
                                        required
                                        name="pitchTitleSpanish"
                                        placeholder="Pitch title spanish"
                                        onChange={(e) => onChangeHandler(e, index, 'pitches')}
                                        value={pitch.pitchTitleSpanish}
                                    /> */}

<CKEditor
          editor={ClassicEditor}
          data={pitch.pitchTitleSpanish}
          onChange={handleChange('pitchTitleSpanish',index)}
        />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6} lg={4}>
                                <Form.Group className="mb-3" controlId={`pitchDescriptionEnglish${index}`}>
                                    <Form.Label>Pitch Description English</Form.Label>
                                    {/* <Form.Control
                                        as="textarea"
                                        type="text"
                                        required
                                        name="pitchDescriptionEnglish"
                                        placeholder="Pitch description english"
                                        onChange={(e) => onChangeHandler(e, index, 'pitches')}
                                        value={pitch.pitchDescriptionEnglish}
                                    /> */}

<CKEditor
          editor={ClassicEditor}
          data={pitch.pitchDescriptionEnglish}
          onChange={handleChange('pitchDescriptionEnglish',index)}
        />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6} lg={4}>
                                <Form.Group className="mb-3" controlId={`pitchDescriptionSpanish${index}`}>
                                    <Form.Label>Pitch Description Spanish</Form.Label>
                                    {/* <Form.Control
                                        as="textarea"
                                        type="text"
                                        required
                                        name="pitchDescriptionSpanish"
                                        placeholder="Pitch description spanish"
                                        onChange={(e) => onChangeHandler(e, index, 'pitches')}
                                        value={pitch.pitchDescriptionSpanish}
                                    /> */}
                                    
<CKEditor
          editor={ClassicEditor}
          data={pitch.pitchDescriptionSpanish}
          onChange={handleChange('pitchDescriptionSpanish',index)}
        />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6} lg={4}>
                                <Form.Group className="mb-3" controlId={`pitchImage${index}`}>
                                    <Form.Label>Pitch Image</Form.Label>
                                    <Form.Control
                                        type="file"
                                        required={storeId ? false : true}
                                        name="pitchImage"
                                        placeholder="Pitch image"
                                        onChange={(e) => onChangeHandler(e, index, 'pitches')}
                                    />
                                </Form.Group>
                            </Col>
                            {
                        !storeId &&
                            <Col xs={12} className="mb-3">
                                <Button variant="danger" onClick={() => removePitch(index)}>Remove Pitch {index+1}</Button>
                            </Col>
}
                        </Fragment>
                    ))}
                         {
                        !storeId &&
                    <Col xs={12} className="mb-3">
                        <Button variant="primary" onClick={addPitch}>Add Pitch</Button>
                    </Col>
                      }
                    <Col xs={12}>
                        <h5 className="mb-3 mt-2">Files</h5>
                    </Col>

                    {formData.files.map((file, index) => (
                        <Fragment key={index}>
                            {/* <Col xs={12} md={6} lg={4}>
                                <Form.Group className="mb-3" controlId={`fileType${index}`}>
                                    <Form.Label>File Type</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="type"
                                        placeholder="File type"
                                        onChange={(e) => onChangeHandler(e, index, 'files')}
                                        value={file.type}
                                    />
                                </Form.Group>
                            </Col> */}
                              <Col xs={12} md={6} lg={4}>
                                <Form.Group className="mb-3" controlId={`fileType${index}`}>
                                    <Form.Label>File Type</Form.Label>
                                    <Form.Select required name="type" onChange={(e) => onChangeHandler(e, index, 'files')} value={file.type}>
                                        <option>Select File Type...</option>
                                        <option value="image">Image</option>
                                        <option value="video">Video</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6} lg={4}>
                                <Form.Group className="mb-3" controlId={`fileFile${index}`}>
                                    <Form.Label>File</Form.Label>
                                    <Form.Control
                                        required={storeId ? false : true}
                                        type="file"
                                        name="file"
                                        placeholder="File"
                                        onChange={(e) => onChangeHandler(e, index, 'files')}
                                    />
                                </Form.Group>
                            </Col>
                            {
                        !storeId &&
                            <Col xs={12} className="mb-3">
                                <Button variant="danger" onClick={() => removeFile(index)}>Remove File {index+1}</Button>
                            </Col>
                            }
                        </Fragment>
                    ))}
                    {
                        !storeId &&
                        <Col xs={12} className="mb-3">
                            <Button variant="primary" onClick={addFile}>Add File</Button>
                        </Col>
                    }

                    <Col xs={12} className="d-flex justify-content-center">
                        <Button variant="outline-danger" type="submit">{heading} Store</Button>
                    </Col>
                </Row>
            </Form>
        </Fragment>
    );
};

export default AddStoreForm;
