import { Modal, Row, Button, Col } from "react-bootstrap";

import { useEffect, useState } from "react";
import CreateSignature from "components/CreateSignature";
import { useSelector } from "react-redux";
import PDFViewer from "components/PDFViewer";
import apis from "services";
import { CircularProgress } from "@mui/material";

const SignatureSuccessModal = ({ status, toggleModalSuccess }) => {
  const { user } = useSelector((store) => store.user);
  const [loading, setLoading] = useState(false);

  const [kyc, setKYC] = useState({});

  const getKYC = async () => {
    try {
      setLoading(true);

      const response = await apis.userKYC(user?.id);
      if (response?.data?.status) {
        console.log(response?.data, "DATAA");
        setKYC(response?.data?.kyc);
      }
      setLoading(false);
    } catch (e) {
      console.log(e.message);

      setLoading(false);
    }
  };

  useEffect(() => {
    if (user?.id) {
      getKYC();
    }
  }, [status]);

  return (
    <Modal
      show={status}
      onHide={toggleModalSuccess}
      centered
      size="lg"
      className="invest-options-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div>
            <h4>KYC Contract Sign Completed</h4>
            <Button
              className="lp-btn mt-4"
              variant="outline-danger"
              type="submit"
            >
              <a href={kyc?.file} target="_blank" download>
                Download Now
              </a>
            </Button>

          </div>

        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <>
            <div className="d-flex justify-content-center align-items-center m-5">
              <CircularProgress color="error" />
            </div>
          </>
        ) : (
          <Row>
            <Col>       
                  <PDFViewer file={kyc?.file}></PDFViewer>


              
            </Col>
          </Row>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SignatureSuccessModal;
