import { Footer, MainHeader, TeamCard } from "components";
import { teamMembers } from "dummyData";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import imageUrl from "utils/imageUrl";
import { useSelector, useDispatch } from "react-redux";
import { setTotalUnseenNotifications, setUserNotifications } from "redux/slices/userSlice";
import apis from "services";
import Link from 'hooks/Link'
import { formatDate } from "utils/formatDate";
import { setAdminNotifications, setTotalUnseenAdminNotifications } from "redux/slices/adminSlice";
import { CircularProgress  ,Pagination } from "@mui/material";
import { replaceLinksInText } from "utils/replaceLinksInText";
import useNavigate from "hooks/Navigate";

function Notifications() {
  const { user, notifications } = useSelector((store) => store.user);
  const { adminUser, notificationsAdmin } = useSelector((store) => store.admin);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;  

const navigate = useNavigate(); 
  const [loading, setLoading] = useState(false);

  const [not, setNot] = useState([]);

  const dispatch = useDispatch();

   // Pagination
   const indexOfLastItem = currentPage * itemsPerPage;
   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
   const currentItems = not?.slice(indexOfFirstItem, indexOfLastItem);
 
   const handlePageChange = (event, newPage) => {
     setCurrentPage(newPage);
   };

   useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  const [showDropdown, setShowDropdown] = useState(false);

  const { t } = useTranslation();

  const getUserNotification = async () => {
    try {
      setLoading(true)
      const res = await apis.getUserNotification(user?.id)
      if (res?.data?.status) {
        dispatch(setUserNotifications(res?.data?.notifications))
        dispatch(setTotalUnseenNotifications(res?.data?.unSeenNotifications))
        setNot(notifications);
      }
      
      setLoading(false)
    }
    catch (e) {
      
      setLoading(false)
    }
  }


  const getAdminNotification = async () => {
    try {
      
      setLoading(true)
      const res = await apis.getAdminNotificationRecieve(adminUser?._id)
      if (res?.data?.status) {
        dispatch(setAdminNotifications(res?.data?.notifications))
        dispatch(setTotalUnseenAdminNotifications(res?.data?.unSeenNotifications))
         setNot(notificationsAdmin);
      }
      
      setLoading(false)
    }
    catch (e) {
    
      setLoading(false)
    }
  }
  useEffect(() => {
    (async () => {
      if(user)
      {
        await getUserNotification();
      }
      else if(adminUser)
      {
        await getAdminNotification();
      }
    })();
  }, []);

  const isTextTooLong = (text) => {
    return text?.length >= 300;
  };
  return (
    <>
      <div onClick={() => { if (showDropdown) setShowDropdown(!showDropdown) }}>
        <div className="page-banner" >
          <MainHeader showDropdown={showDropdown} setShowDropdown={setShowDropdown} />

          <img
            src={imageUrl("about_img.webp")}
            className="background-img"
            alt=""
          />
          <div className="page-banner-body">
            <p className="page-banner-title mx-3">{t("buttons.notifications_history")}</p>
          </div>
        </div>
        <section className="py-5">
          <div className="container">
            <div className="row">
              <div className="mx-auto col-lg-8">
                {
                  loading ? <div className="d-flex justify-content-center align-items-center m-5"><CircularProgress color="error" /></div> : <>
                  
                <div className="row">
                  <div  className="col-lg-12">
                    {currentItems?.length > 0 ? <>
                      {currentItems?.map(notification => (
                      <div onClick={()=>navigate(`/notification/${notification?._id}`)} key={notification?._id} className="notification-history-card" >
                        <div className="row">
                          <div className="col-lg-3 col-md-4 col-sm-4 d-flex justify-content-center align-items-center mx-auto">
                            <div className='image-holder-detail-notification'>
                              <img src='/images/coffee_logo.png' alt="" />
                            </div>
                          </div>
                          <div className="col-lg-9 mt-3">
                            <h4 className="t-card-desc mt-2 mb-1">{notification?.notification?.title}</h4>
                            <h6 className="t-card-desc mt-2 mb-1">{formatDate(notification?.createdAt)}</h6>
                         

                            <p className="t-card-desc mb-1 mt-3">{replaceLinksInText(notification?.notification?.body.slice(0,300))}
                            {" "}
                            {isTextTooLong(notification?.notification?.body)  && (
                              <Link to={`/notification/${notification?._id}`} className="read-more-btn">
                                Read more...
                              </Link>
                            )}
                              {notification?.image  && (
                              <Link to={`/notification/${notification?._id}`} className="read-more-btn">
                                Read more...
                              </Link>
                            )}
                            </p>
                            {/* {isTextTooLong(notification?.notification?.body) && (
                              <Link to={`/notification/${notification?._id}`} className="read-more-btn">
                                Read more...
                              </Link>
                            )} */}
                          </div>

                        </div>

                      </div>
                    ))}
                    </> : <h2 className="d-flex justify-content-center align-items-center m-5">No Notification!</h2>}
                    {not?.length > itemsPerPage && (
          <div className="d-flex justify-content-center">
            <Pagination
              count={Math.ceil(not.length / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
            />
          </div>
        )}
                  </div>
                </div>
                  </>
                }
              </div>
            </div>
          </div>

        </section>


        <Footer />
      </div>
    </>
  );
}

export default Notifications;
