import {Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";
import {Button, Form} from "react-bootstrap";
import {useState} from "react";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import apis from "../../services";
import {setReserveShares} from "../../redux/slices/adminSlice";
import { useEffect } from "react";
import {  Col} from "react-bootstrap";
const ConfirmWithDrawlModal = ({status = true, toggleModal,amount,submit}) => {

    return (
        <Dialog  maxWidth={"md"} open={status} onClose={toggleModal}>
            <DialogTitle>Confirm Withdrawl Request</DialogTitle>
           
                <DialogContent dividers sx={{ width: 500 }}>
                    <Grid item container>
                        <Grid item xs={12}>
                            <Form.Group className="mb-3" controlId="reserveShares">
                                <Form.Label>Withdrawl Amount</Form.Label>
                                <Form.Control type="number" placeholder="" value={amount} disabled/>
                            </Form.Group>
                           </Grid>
                    </Grid>
                    <span style={{ color:"red"}}>
          Withdrawal Policy: When you submit a withdrawal request, a 30% tax will be deducted from the requested amount.
          You will receive the remaining 70%.
        </span>
          <br/> 
                </DialogContent>
               

                <DialogActions>
                    <Grid item container justifyContent="flex-end" gap={2}>
                        <Button className="lp-btn"
                                onClick={toggleModal}
                                style={{backgroundColor: '#212529', borderColor: '#212529'}}>
                            Cancel
                        </Button>
                        <Button className="lp-btn" type="submit" onClick={submit}>
                            Confirm
                        </Button>
                    </Grid>
                </DialogActions>
        </Dialog>
    )
}

export default ConfirmWithDrawlModal
