import React, { useEffect, useState } from 'react'
import { useDispatch } from "react-redux";
import { getAllUser } from '../../redux/slices/adminSlice';
import { useParams } from 'react-router-dom';
import AdminNewSidebar from './AdminNewSidebar';
import Header from 'components/Header';
import { Box, Container, Grid, Typography } from "@mui/material";
import apis from "services";
import DonationShareTable from "../../components/datagrid/DonationShareTable";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import UserDetailsTable from 'components/datagrid/UserDetails';
import ImagePreview from 'components/modals/ImagePreview';


const UserDetails = () => {
    const { language, setLanguage, t } = useTranslation();
    const param = useParams();
    const userId = param.id;
    const [tab, setTab] = useState("user_details");
    let [data, setData] = useState([]);

    const fetchUserDetails = async (type="user_details") => {

      if(type === "profit_details")
      {
        const { data } = await apis.userProfit(userId);
      
        if (data?.status) {
           setData(data?.transaction);
        }
        else{
          setData([]);
        }

        
      }
      else{
        
        const { data } = await apis.getUserAllDetails(userId,type);
      
        if (data?.status) {
           setData([data?.user]);
        }
      }
     
    };
  
    const [image, setImage] = useState("");
    const [imageModal, setImageModal] = useState(false);
  
    const closeImageModal = (image) => {
      if (image) setImage(image);
      setImageModal((prevState) => !prevState);
  };
  
    useEffect(() => {
      (async () => {
        await fetchUserDetails();
      })();
      }, []);
  
      const fetchByType = async (type) => {
        setTab(type);
        setData([]);
        await fetchUserDetails(type);
       };
      
    const { rows, columns } = UserDetailsTable(data,tab,closeImageModal);
  
    return (
        <>

<Box className="dashboard-main">
      <AdminNewSidebar />
      <div className="dashboard-section-main">
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
          className="custom-scroll"
        >
          <Header />
          <Container maxWidth="lg" sx={{ mt: 0, mb: 4 }}>
            <h3 className="dashboard-title">
              {t("titles.user_details")}
            </h3>
            <div className="share-type d-flex justify-content-center">
              <Button
                className={tab === "user_details" ? "w-10 active_type " : "w-10"}
                variant="outline-danger"
                onClick={()=>fetchByType("user_details")}
              >
                User Details
              </Button>
              <Button
                className={tab === "wallet_details" ? "w-10 active_type " : "w-10"}
                variant="outline-danger"
                onClick={()=>fetchByType("wallet_details")}
              >
                Wallet Details
              </Button>
              <Button
                className={tab === "kyc_details" ? "w-10 active_type " : "w-10"}
                variant="outline-danger"
                onClick={()=>fetchByType("kyc_details")}
              >
                KYC Details
              </Button>
              
              {/* <Button
                className={tab === "investor_details" ? "w-10 active_type " : "w-10"}
                variant="outline-danger"
                onClick={()=>fetchByType("investor_details")}
              >
                Investor Details
              </Button> */}
              
              <Button
                className={tab === "profit_details" ? "w-10 active_type " : "w-10"}
                variant="outline-danger"
                onClick={()=>fetchByType("profit_details")}
              >
                Profit Details
              </Button>
            
            </div>
            <Grid
              item
              container
              className="mt-4"
              justifyContent="flex-end"
              sx={{ height: "600px" }}
            >
              <DataGrid
                rows={rows}
                columns={columns}
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                }
              />
            </Grid>
          </Container>
        </Box>
      </div>
    </Box>

    
    <ImagePreview status={imageModal} close={closeImageModal} image={image}/>
        </>

    )
}

export default UserDetails