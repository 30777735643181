import apis from "services";
import {createAsyncThunk} from "@reduxjs/toolkit";

/***********    USER REDUX APIS     ***********/
const userLogin = createAsyncThunk("Users Login", async (body) => apis.authLogin(body));
const getProfile = createAsyncThunk("Get Profile", async (id) => apis.getProfile(id));

/***********    WALLET REDUX APIS     ***********/
const getWallet = createAsyncThunk('Get Wallet Data', async (id) => await apis.getWallet(id));

/***********    STORE REDUX APIS     ***********/
// const getStoreCount = createAsyncThunk("GET Stores Count", async() => await apis.getStoreCount());
const getStore = createAsyncThunk("GET Store data", async (id) => await apis.getStore(id));
const getAllStore = createAsyncThunk("GET All Store data", async () => await apis.getAllStore());

/***********    ADMIN USER REDUX APIS     ***********/
const adminLogin = createAsyncThunk('Admin Login', async (body) => apis.adminLogin(body));
// const getUserCount = createAsyncThunk('Geting Users Count Data', async () => await apis.getUserCount());
// const getStoreCount = createAsyncThunk('Geting Stores Count Data', async () => await apis.getStoreCount());
// const getAllStore = createAsyncThunk('Geting All Stores Data', async () => await apis.getAllStore());
// const getAllUser = createAsyncThunk('Geting All Users Data', async () => await apis.getAllUser());
// const getProfile = createAsyncThunk('Geting Users Profile Data', async (id) => await apis.getProfile(id));

const reduxApis = {userLogin, getProfile, getWallet, adminLogin, getStore, getAllStore}


export default reduxApis
