
import { Button, Col, Modal, Row } from "react-bootstrap";
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";
import { Form} from "react-bootstrap";
import { Link } from "react-router-dom";

const DeleteModal= ({ show, handleClose, handleSubmit ,id,loader,title}) => {

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className="invest-options-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{padding:0}}>
        
                <DialogContent dividers sx={{ width: 500 }}>
                    <Grid item container>
                        <Grid item xs={12}>
                            <Form.Group className="mb-3">
                            <Form.Label className='text-color'>Are you sure you want to delete?</Form.Label>
                            </Form.Group>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Grid item container justifyContent="flex-end" gap={2}>
                        <Link className="lp-btn"
                                onClick={()=>handleClose()}
                                style={{backgroundColor: 'white',color:"#212529", borderColor: '#212529'}}>
                            Cancel
                        </Link>
                        <Link onClick={()=>handleSubmit(id)}   disabled={loader} className="lp-btn" type="submit">
                        {loader ? "Loading" : "Delete"}
                        </Link>
                    </Grid>
                </DialogActions>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteModal;
