import { Button, Col, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useNavigate from "hooks/Navigate";

const InvestProfitPolicyModal = ({ status, toggleModal, setState }) => {
  const { t } = useTranslation();
  
  const submitHandler = () => {
    setState(true);
    toggleModal();
  };

  return (
    <Modal
      show={status}
      onHide={toggleModal}
      centered
      className="invest-options-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("terms-profit.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ fontSize: '12px' }}>
        <Col>{t("terms-profit.col-1-reg")}</Col>
        <br />
        <Col>{t("terms-profit.col-2-reg")}</Col>
        <br />
        <Col>{t("terms-profit.col-3-reg")}</Col>
        <br />
        <Col>{t("terms-profit.col-4-reg")}</Col>
        <br />
        <Col>{t("terms-profit.col-5-reg")}</Col>
        <br />
        <Col>{t("terms-profit.col-6-reg")}</Col>
        <br />

        <div className='Login-btn-container mb-3' >
          <button type='submit' onClick={submitHandler} >
            {t("terms-profit.agree")}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default InvestProfitPolicyModal;
