
import { useTranslation } from "react-i18next";
import { Grid, IconButton, Tooltip } from "@mui/material";
import {
    Delete,
  } from "@mui/icons-material";
import moment from "moment";
import { formatDate } from "utils/formatDate";

const ReferralTable = (referrals = [] , methods={}) => {
    const { language, setLanguage, t } = useTranslation();


    const {  openModal} = methods;
    const columns = [
        {field: 'id', headerName: '#', width:50, hide: false},
        {field: 'referredBy', headerName: t("fields.referredBy"), width: 260},
        {field: 'referralUser', headerName: t("fields.referralUser"), width: 150},
        {
            field: "status",
            headerName: t("fields.status"),
            width: 250,
              renderCell: (props) => {
              const status = props.row.status;
              return <span style={{color: status === "investor" ?  'green' : 'red' , textTransform:'capitalize'}}>{status}</span>
            }
          },
        {field: 'amountInvested', headerName: t("fields.amountInvested"), width: 150},
        {field: 'rewards', headerName: t("fields.rewards"), width: 150},
        {field: 'createdAt', headerName: t("fields.createdAt"), width: 250},
  
        // {
        //     field: "actions",
        //     headerName: t("fields.actions"),
        //     minWidth: 250,
        //     flex: 1,
        //     renderCell: (props) => {
        //       const id = props.row._id;
        //       return (
        //         <Grid container>
                  
        //           <Grid item>
        //             <Tooltip title="Delete" arrow>
        //               <IconButton size="small" onClick={() => openModal(id)}>
        //                 <Delete />
        //               </IconButton>
        //             </Tooltip>
        //           </Grid>
                  
        //         </Grid>
        //       );
        //     },
        //   },
    ];

    const rows = referrals.length === 0
        ? []
        : [
            ...referrals.reverse().map((referral,index) => ({
                id: index+1,
                referredBy: referral['from_referral_userId']?.name,
                referralUser: referral['to_referral_userId']?.name,
                status: referral['status'],
                amountInvested: referral['invested_amount'],
                rewards: referral['rewarded_amount'],
                createdAt : formatDate(referral['createdAt']),       
            }))
        ];


    return {rows, columns}
}

export default ReferralTable
