import { Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import useNavigate from "hooks/Navigate";
const TermsAndConditionInvestModal = ({
  status,
  toggleModal,
  onChangeHandlerConditions,
}) => {
  const navigate = useNavigate();
  const { language } = useSelector((store) => store.language);
  const { t } = useTranslation();
  const submitHandler = () => {
    onChangeHandlerConditions("isAcceptTerms", true);
    toggleModal();
  };
  return (
    <Modal
      show={status}
      onHide={toggleModal}
      centered
      className="invest-options-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
        {t("terms-and-conditions.title")}
          {/* Java Times Caffe Conditions to accept to give funder the 30% */}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ fontSize: "12px" }}>
        <br />
        <Col>
        {t("terms-and-conditions.desc1")}
        {/* TÉRMINOS Y CONDICIONES DE INVERSIÓN EN JAVA TIMES CAFÉ (JTC) */}
        </Col>
        <br />
        <Col>
        {t("terms-and-conditions.desc2")}
          {/* ENTIDAD DE GESTIÓN: La gestión de las acciones, inversiones y pago de
          dividendos de JTC está a cargo de Startup Networks Sapi de CV. JTC es
          solo el nombre comercial y el único proveedor autorizado de Startup
          Networks Sapi de CV. ACEPTACIÓN DE LOS TÉRMINOS: Al invertir en JTC,
          el inversor acepta completamente estos términos y condiciones. */}
        </Col>
        <br />
        <Col>
        {t("terms-and-conditions.desc3")}
          {/* DISTRIBUCIÓN DE LA INVERSIÓN: El inversor comprende y acepta que el
          70% de la inversión total se reflejará en su cartera digital. El 30%
          restante se asignará a las carteras digitales de los fundadores de
          JTC. */}
        </Col>
        <br />
        <Col>
        {t("terms-and-conditions.desc4")}
          {/* SEGURIDAD DE INVERSIÓN: JTC le asegura al inversor la seguridad de su
          inversión. La acción se emite con una prima del 30%, que se dirige al
          capital directo. */}
        </Col>
        <br />
        <Col>
        {t("terms-and-conditions.desc5")}
          {/* USO DE FONDOS: JTC se compromete a usar el 100% de la inversión para
          establecer nuevas cafeterías y para impulsar el desarrollo de la
          compañía. */}
        </Col>
        <br />
        <Col>
        {t("terms-and-conditions.desc6")}
          {/* RIESGO: El inversor entiende que todas las inversiones conllevan un
          riesgo y que el rendimiento pasado no es indicativo de rendimientos
          futuros. */}
        </Col>
        <br />
        <Col>
        {t("terms-and-conditions.desc7")}
          {/* LÍMITES DE RESPONSABILIDAD: En ningún caso JTC ni sus fundadores, ni
          Startup Networks Sapi de CV serán responsables por cualquier pérdida o
          daño incluyendo, sin limitación, pérdida o daño indirecto o
          consecuencial, o cualquier pérdida o daño que surja de la pérdida de
          datos o ganancias que surjan de, o en relación con, el uso de este
          servicio de inversión. */}
        </Col>
        <br />
        <Col>
        {t("terms-and-conditions.desc8")}
          {/* LEYES Y REGULACIONES APLICABLES: Todas las inversiones están sujetas a
          las leyes y regulaciones aplicables en la jurisdicción en la que se
          realiza la inversión. Es responsabilidad del inversor entender y
          cumplir con estas leyes y regulaciones. */}
        </Col>
        <br />
        <Col>
        {t("terms-and-conditions.desc9")}
          {/* Al invertir en JTC, se considera que ha leído y aceptado estos
          términos y condiciones. Si no está de acuerdo con estos términos, no
          debe realizar ninguna inversión en JTC. */}
        </Col>
        <br />
        <br />
        <Col>
        {t("terms-and-conditions.desc10")}
        {/* Fecha de efectividad: 7 de Julio de 2023. */}
        </Col>
        <br />
        <br />
        <Col>
        {t("terms-and-conditions.desc11")}
          {/* Por favor, consulte con un asesor financiero antes de realizar
          cualquier inversión. */}
        </Col>
        <br />
        <div className="Login-btn-container mb-3">
          <button type="submit" onClick={() => submitHandler()}>
        {t("terms-and-conditions.button")}
            {/* Agree */}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TermsAndConditionInvestModal;
