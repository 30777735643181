import { Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import useNavigate from "hooks/Navigate";
const GuaranteeModal = ({ status, toggleModal }) => {
  const navigate = useNavigate();
  const {language} = useSelector(store => store.language)
  const {  t } = useTranslation();
  return (
    <Modal
      show={status}
      onHide={toggleModal}
      centered
      className="invest-options-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("gurantee.desc_1")} </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{fontSize:'12px'}}>
          <Col style={{fontSize:'14px'}}>{t("gurantee.desc_2")}:</Col>
          <br/>        
          <Col>{t("gurantee.desc_3")}:</Col>
          <br/>   
          <Col>{t("gurantee.desc_4")}</Col>
          <br/>   
          <Col>{t("gurantee.desc_5")}</Col>
          <br/>   
          <Col>{t("gurantee.desc_6")}</Col>
          <br/>   
          <Col>{t("gurantee.desc_7")}</Col>
          <br/>   
          <Col>{t("gurantee.desc_8")}</Col>
          <br/>
          <Col>{t("gurantee.desc_9")},</Col>
          <Col>Antonio Leite</Col>
          <Col>Founder</Col>
          <Col>Java Times Caffe</Col>
    
      </Modal.Body>
    </Modal>
  );
};

export default GuaranteeModal;
