import React, { useEffect, useRef, useState, useCallback } from "react";
import PersonItem from "./PersonItem";
import Slider from "react-slick";
import apis from "services";
// import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { useTranslation } from "react-i18next";
import img1bg from "assets/images/img1.png";
import { FaRegCirclePlay } from "react-icons/fa6";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import detectLang from "utils/detectLang";
import cmsImage from "utils/cmsImage";
import cmsVideos from "utils/cmsVideos";

const TestimonialCardSlider = ({ data,className,lang }) => {
  const { t } = useTranslation();
console.log("datadatadatadatadatadatadata",data);

  // const [isPlaying4, setIsPlaying4] = useState(false);
  // const videoRef4 = useRef(null);
  // const togglePlaytest = () => {
  //   if (videoRef4.current.paused || videoRef4.current.ended) {
  //     videoRef4.current.play();
  //     setIsPlaying4(true);
  //   } else {
  //     videoRef4.current.pause();
  //     setIsPlaying4(false);
  //   }
  // };
  // const handleVideoEndedtest = () => {
  //   setIsPlaying4(false);
  // };

  const [videoStates, setVideoStates] = useState([]);
  const videoRefs = useRef([]);

  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  useEffect(() => {
    const initialStates = Array(videoRefs.current.length).fill(false);
    setVideoStates(initialStates);
  }, []);

  const togglePlay = (index) => {
    const newStates = [...videoStates];
    const video = videoRefs.current[index];

    if (video.paused || video.ended) {
      video.play();
      newStates[index] = true;
    } else {
      video.pause();
      newStates[index] = false;
    }

    setVideoStates(newStates);
  };

  const handleVideoEnded = (index) => {
    const newStates = [...videoStates];
    newStates[index] = false;
    setVideoStates(newStates);
  };

  const testimonailsData = [
    {
      // desc: "descs.testimonialDesc1",
      // videoSrc: `${process.env.REACT_APP_SERVER_URL}upload/videoTestimonial1.mp4`,
      // img: "/videos/img04.jpeg",
    
      desc: lang === "en" ? data?.description_en_1 : data?.description_sp_1,
      videoSrc: cmsVideos(data?.video_1),
      img: cmsImage(data?.image_1),
    
    },
    {
      desc: lang === "en" ? data?.description_en_2 : data?.description_sp_2,
      videoSrc: cmsVideos(data?.video_2),
      img: cmsImage(data?.image_2),

      // desc: "descs.testimonialDesc1",
      // videoSrc: `${process.env.REACT_APP_SERVER_URL}upload/videoTestimonail2.mp4`,
      // img: "/videos/img03.jpeg",
    },
    {

      desc: lang === "en" ? data?.description_en_3 : data?.description_sp_3,
      videoSrc: cmsVideos(data?.video_3),
      img: cmsImage(data?.image_3),

      // desc: "descs.testimonialDesc1",
      // videoSrc: `${process.env.REACT_APP_SERVER_URL}upload/videoTestimonail3.mp4`,
      // img: "/videos/img02.jpeg",
    },
    {
      desc: lang === "en" ? data?.description_en_4 : data?.description_sp_4,
      videoSrc: cmsVideos(data?.video_4),
      img: cmsImage(data?.image_4),

      // desc: "descs.testimonialDesc1",
      // videoSrc: `${process.env.REACT_APP_SERVER_URL}upload/videoTestimonail4.mp4`,
      // img: "/videos/img01.jpeg",
    }, 
    {

      desc: lang === "en" ? data?.description_en_5 : data?.description_sp_5,
      videoSrc: cmsVideos(data?.video_5),
      img: cmsImage(data?.image_5),

      // desc: "descs.testimonialDesc1",
      // videoSrc: `${process.env.REACT_APP_SERVER_URL}upload/videoTestimonail5.mp4`,
      // img: "/videos/img05.png",
    }
   

  ];
  const settings = {
    autoplay: true,
    autoplaySpeed: 3000,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    // arrows:false,
    // nextArrow: <MdKeyboardArrowRight />,
    // prevArrow: <MdKeyboardArrowLeft />,

    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 592,
        settings: {
          slidesToShow: 1,
        },
      },
    ],

    // nextArrow: <PrimaryNextArrow />,
    // prevArrow: <PrimaryPrevArrow />,
  };

  return (
    <>
      <div className={className}>
        <Swiper
          ref={sliderRef}
          slidesPerView={1}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
        >
          {testimonailsData.map((data, index) => (
            <SwiperSlide style={{}} key={index}>
              <div>
                <div className="video">
                  <video
                    ref={(el) => (videoRefs.current[index] = el)}
                    onEnded={() => handleVideoEnded(index)}
                    onClick={() => togglePlay(index)}
                    playsInline={true}
                    width={"100%"}
                    height={"100%"}
                    style={{ opacity: videoStates[index] ? 1 : 0 }}
                  >
                    <source src={data.videoSrc} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <>
                    <div
                      className="background"
                      style={{ opacity: videoStates[index] ? 0 : 1 }}
                    >
                      <img
                        onClick={() => togglePlay(index)}
                        src={data.img}
                        alt=""
                      />
                      <FaRegCirclePlay onClick={() => togglePlay(index)} />
                    </div>
                  </>
                </div>
                {/* <video className="testimonial-video" loop autoPlay controls>
                <source src={data.videoSrc} type="video/mp4" />
                Your browser does not support the video tag.
              </video> */}
                <p className="testimonial-desc">{t(data.desc)}</p>
                {/* <img src={data.img} alt="" /> */}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="home-slider-button-parent">
          <div className="home-slider-prev" onClick={handlePrev}>
            <FaArrowLeftLong />
          </div>
          <div className="home-slider-next" onClick={handleNext}>
            <FaArrowRightLong />
          </div>
        </div>
      </div>
    </>
  );
};

export default TestimonialCardSlider;
