import { useState, useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import useNavigate from "hooks/Navigate";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import reduxApis from "../redux/apis";
import { Header, Sidebar } from "components";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { SUB_ADMIN_TYPES } from "assets/constants";

const AdminLogin = () => {
  const [formData, setFormData] = useState({ email: "", password: "" });

  const { adminUser, loading } = useSelector((store) => store.admin);
  const [loginBtn, setLoginBtn] = useState(false);
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { state } = useLocation();
  const onChangeHandler = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [id]: value }));
  };

  const { user } = useSelector((store) => store.user);

  if (user) {
    navigate(state ? state?.from : "/user/dashboard");
  }

  useEffect(() => {
    if (adminUser?.type === "owner") {
      navigate("/admin/dashboard");
    } else if (SUB_ADMIN_TYPES.includes(adminUser?.type)) {
      navigate("/sub-admin/dashboard");
    }
  }, []);

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      setLoginBtn(true);
      const res = await dispatch(reduxApis.adminLogin(formData));
      setLoginBtn(false);
      if (res?.payload) {
        setTimeout(() => {
          if (adminUser?.type === "owner") {
            navigate("/admin/dashboard");
          } else {
            navigate("/sub-admin/dashboard");
          }
        }, 1000);
      }
    } catch (error) {
      setLoginBtn(false);
      const message = error.response.data.message;
      toast.error(message);
    }
  };

  return (
    <div className="landing-main New-login-form-main">
      {/* <Sidebar/> */}
      <div className="landing-page landing-main-page custom-scroll">
        <Header />
        <Container>
          {/* <Row>
                       
                        <Col>
                            <h3 className="section-title dark-text ">{t("titles.login_admin")}</h3>

                        </Col>
                    </Row> */}

          {/* <Form onSubmit={onSubmitHandler}>
                        <Row>
                            <Col md={4}/>

                            <Col md={4}>

                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Control
                                        type="email"
                                        placeholder={t("placeholders.email")}
                                        onChange={onChangeHandler}
                                        value={formData.email}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="password">
                                    <Form.Control
                                        type="password"
                                        placeholder={t("placeholders.password")}
                                        onChange={onChangeHandler}
                                        value={formData.password}
                                        required
                                    />
                                </Form.Group>

                                <Button
                                    variant="outline-danger"
                                    className="w-100 mb-5"
                                    type="submit"
                                    disabled={loginBtn}
                                >
                                    {t("buttons.login")}

                                </Button>
                            </Col>
                        </Row>
                    </Form> */}

          <Row className="justify-content-center">
            <Col md={8} lg={5}>
              <div className="New-login-form-form p-5">
                <div className="New-login-form-inside">
                  <div className="Centre-the-content">
                    <p className="New-login-form-heading-2 pb-5">
                      {t("titles.login_admin")}
                    </p>
                  </div>
                  <div>
                    <Form onSubmit={onSubmitHandler}>
                      <Form.Group className="mb-3" controlId="email">
                        <Form.Control
                          type="email"
                          placeholder={t("placeholders.email")}
                          onChange={onChangeHandler}
                          value={formData.email}
                          required
                        />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="password">
                        <Form.Control
                          type="password"
                          placeholder={t("placeholders.password")}
                          onChange={onChangeHandler}
                          value={formData.password}
                          required
                        />
                      </Form.Group>
                      <div className="Login-btn-container pb-3">
                        <button type="submit" disabled={loading}>
                          {loading
                            ? t("buttons.logging_in")
                            : t("buttons.login")}
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default AdminLogin;
