import { MainHeader } from "components";
import { teamMembers } from "dummyData";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { FaGraduationCap } from "react-icons/fa";
import { useParams } from "react-router-dom";
import apis from "services";
import _html from "utils/_html";
import imageUrl from "utils/imageUrl";
import { replaceLinksInText } from "utils/replaceLinksInText";

const NotificationDetails = () => {
  const [showDropdown, setShowDropdown] = useState(false);

  const { id } = useParams();

  const [notification, setNotification] = useState({});

  const getUserNotificationDetail = async () => {
    try {
      const res = await apis.getUserNotificationDetails(id);
      if (res?.data?.status) {
        console.log(res?.data, "res?.data");
        setNotification(res?.data?.notification);
      }
    } catch (e) {}
  };
  useEffect(() => {
    (async () => {
      await getUserNotificationDetail();
    })();
  }, [id]);

  console.log(notification, "notificationnotificationnotificationnotification");
  return (
    <>
      <MainHeader
        bg
        showDropdown={showDropdown}
        setShowDropdown={setShowDropdown}
      />
      <section
        className="team-member-section py-5"
        style={{ marginTop: "5rem" }}
        onClick={() => {
          if (showDropdown) setShowDropdown(!showDropdown);
        }}
      >
        <Container>
          <Row className="g-5">
            <Col
              md={3}
              className="px-4 grey-bg p-4 member-open-card"
              style={{ minHeight: 0 }}
            >
              <div className="investor-card-img mb-2">
                <div className="investor-card-img-inner">
                  <img src="/images/coffee_logo.png" alt="" />
                </div>
              </div>
              <p className="custom-card-title small text-center fw-light text-capitalize fw-semibold">
                {/* {detectLang(section, "name", lang)} */}
                {/* {notification?.notification?.title} */}
              </p>
              {/* <p className="card-subtitle text-center">
                {detectLang(section, "designation", lang)}
              </p> */}

              {/* <p className="card-subtitle text-center">Lorem, ipsum dolor.</p> */}
            </Col>
            <Col md={9}>
              <p
                className="section-title text-start mb-2"
                style={{ wordBreak: "break-all", fontWeight: 600 }}
              >
                {/* {detectLang(section, "name", lang)} */}
                {notification?.notification?.title}
              </p>
              <div className="card-line mb-3"></div>
              <p
                className="text-body simple-text"
                style={{ wordBreak: "break-all" }}
              >
                {/* {_html(detectLang(section, "description", lang))} */}
                {replaceLinksInText(notification?.notification?.body)}
              </p>
              {notification?.image && (
                <div
                  className="investor-card-img-inner m-5"
                
                >
                  <img src={imageUrl("about_img.webp")} alt="" />
                </div>
              )}
              {/* <Row>
                <Col md={6}>
                  <p className="blog-card-title">Lorem, ipsum.</p>
                  <ul className="p-0">
                    <li>• Lorem, ipsum.</li>
                    <li>• Lorem, ipsum.</li>
                    <li>• Lorem, ipsum.</li>
                    <li>• Lorem, ipsum.</li>
                  </ul>
                </Col>
                <Col md={6}>
                  <p className="blog-card-title">Lorem, ipsum.</p>
                  <ul className="p-0">
                    <li><FaGraduationCap className="icon me-2"/>Lorem, ipsum.</li>
                    <li><FaGraduationCap className="icon me-2"/>Lorem, ipsum.</li>
                    <li><FaGraduationCap className="icon me-2"/>Lorem, ipsum.</li>
                    <li><FaGraduationCap className="icon me-2"/>Lorem, ipsum.</li>
                  </ul>
                </Col>
              </Row> */}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default NotificationDetails;
