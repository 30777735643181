/**
 @param stores
 @param methods
 */
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import {
  DeleteOutline,
  ImageOutlined,
  RemoveModerator,
  VerifiedUser,
  VisibilityOutlined,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const BlogsTable = (blogs = [], methods = {}, openImagePreview) => {
  const { handleEnableBlog, handleDisableBlog,handleDeleteBlog } =
    methods;
  const { language, setLanguage, t } = useTranslation();
const navigate = useNavigate()
  const columns = [
    { field: "id", headerName: t("fields.id"), width: 150, hide: true },

    { field: "author", headerName: t("fields.author"), width: 150 },
    { field: "title", headerName: t("fields.title"), width: 150 },
    {
      field: "image",
      headerName: t("fields.image"),
      width: 150,
      renderCell: (param) => (
        <IconButton onClick={() => openImagePreview(param.value)}>
          <ImageOutlined />
        </IconButton>
      ),
    },
    {
      field: "authorImage",
      headerName: t("fields.authorImage"),
      width: 150,
      renderCell: (param) => (
        <IconButton onClick={() => openImagePreview(param.value)}>
          <ImageOutlined />
        </IconButton>
      ),
    },
    { field: "description", headerName: t("fields.description"), width: 150 },
    { field: "active", headerName: t("fields.active"), width: 150 },
    {
      field: "createdAt",
      headerName: t("fields.createdAt"),
      minWidth: 150,
    },
    {
      field: "actions",
      headerName: t("fields.actions"),
      width: 150,
      renderCell: ({ row }) => {
        const isActive = row.active === "Yes";
        const id = row._id;

        return (
          <Grid container>
            <Grid>
              <Tooltip title="View & Update" arrow>
                <IconButton size="small" onClick={() => navigate(`add/${id}`)}>
                  <VisibilityOutlined />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid>
              {isActive ? (
                <Tooltip title="Disable Blog" arrow>
                  <IconButton
                    size="small"
                    onClick={() => handleDisableBlog(id)}
                  >
                    <RemoveModerator className="failure" />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Enable Blog" arrow>
                  <IconButton size="small" onClick={() => handleEnableBlog(id)}>
                    <VerifiedUser className="success" />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
            <Grid>
              <Tooltip title="Delete Blog" arrow>
                <IconButton size="small" onClick={() => handleDeleteBlog(id)}>
                  <DeleteOutline />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        );
      },
    },
  ];


  const rows =
    blogs.length === 0
      ? []
      : [
          ...blogs.map((blog, index) => ({
            id: index,
            author: blog.author,
            title: blog.title,
            image: blog.image,
            authorImage: blog.authorImage,
            description: blog.description,
            active: blog.active ? "Yes" : "NO",
            createdAt: moment(blog.createdAt).format("MMMM Do YYYY"),
            _id: blog._id,
          })),
        ];

  return { columns, rows };
};

export default BlogsTable;
