import { BorderBottom } from "@mui/icons-material";
import React, { useState } from "react";
import { FaMinus, FaPlus } from "react-icons/fa";

function Question({ title, para }) {
  // const formattedPara = para.split("\n").map((line, index) => (
  //   <React.Fragment key={index}>
  //     {line}
  //     <br />
  //   </React.Fragment>
  // ));

  const [clicked, setClicked] = useState(false);
  return (
    <div className="sec10-container" data-aos="fade-up" data-aos-once="true">
      <div className="sec10-list" onClick={() => setClicked(!clicked)}>
        <div
          className={clicked ? "sec10-title sec10-title-b" : "sec10-title"}
          style={{ BorderBottom: clicked ? "1px solid #000" : "none" }}
        >
          <h3>{title}</h3>
          {clicked ? (
            <FaMinus className="sec10-icon" />
          ) : (
            <FaPlus className="sec10-icon" />
          )}
        </div>
        {clicked ? (
          <div className="sec10-para">
            <p dangerouslySetInnerHTML={{ __html: para }}></p>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Question;
