import React, {Fragment, useState,useEffect} from "react";
import utils from "utils";
import apis from "services";
import {toast} from "react-toastify";
import PhoneInput from "react-phone-input-2";
import {PhotoCameraRounded, Visibility, VisibilityOff} from "@mui/icons-material";
import {Button, Col, Container, Form, InputGroup, Row} from "react-bootstrap";

import useNavigate from "hooks/Navigate";
import { useTranslation } from "react-i18next";
import {Avatar, IconButton} from "@mui/material";

const AdminUserRegistrationForm = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();

    const [loader, setLoader] = useState(false);
    const [image, setImage] = useState(null);

    const [togglePassword, setTogglePassword] = useState({password: false, confirmPassword: false});

    const onImageHandler = (e) => setImage(e.target.files[0])

    const toggle = (key) =>
        setTogglePassword((prevState) => ({...prevState, [key]: !prevState[key]}));

    const submitHandler = async (e) => {
        e.preventDefault();

        const form = new FormData(e.currentTarget);
        const formData = Object.fromEntries(form);

        const {password, confirmPassword} = formData;

        if (!utils.isPasswordMatch(password, confirmPassword)) {
            return toast.error('Password & Confirm Password field does not match')
        }

        try {
            setLoader(true);
            const response = await apis.registration(formData);
            if (response.status === 201) {
                toast.success(response.data.message);
                    setTimeout(() => navigate("/en/admin/users"), 2000);
         
            }
        } catch (e) {
            console.error(e);
            toast.error(e.message)
            setLoader(false);
        }


    }

    return (
        <Fragment>
            <div className="landing-main">

                <div className="landing-page landing-main-page custom-scroll dashboard-section-main">

               
                  
                    <Container>
                        <Form onSubmit={submitHandler}>
                            <Row className='d-flex justify-content-center align-items-center'>
                                <Col md={10} lg={8} className="custom-box p-5">
                                    <Row className="my-3">
                                        <div className="d-flex justify-content-center position-relative">
                                            <Avatar sx={{width: '6em', height: '6em'}}
                                                    src={utils.createObjectURL(image)}/>
                                            <IconButton aria-label="upload picture"
                                                        sx={{position: "absolute", bottom: 0, left: '52%'}}
                                                        component="label">
                                                <input hidden name="image" accept="image/*" type="file" onChange={onImageHandler}/>
                                                <PhotoCameraRounded sx={{color: "#404040"}}/>
                                            </IconButton>
                                        </div>
                                    </Row>
                                    <Row className="mt-4">
                                        <Col xs={12} lg={6}>
                                            <Form.Group className="mb-3" controlId="name">
                                                <Form.Label>{t("labels.full_name")}</Form.Label>
                                                <Form.Control type="text" name='name' placeholder='Enter Full Name'
                                                              required/>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} lg={6}>
                                            <Form.Group className="mb-3" controlId="name">
                                                <Form.Label>{t("labels.nick_name")}</Form.Label>
                                                <Form.Control type="text" name='nickName' placeholder='Enter Nick Name '
                                                              required/>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} lg={6}>
                                            <Form.Group className="mb-3" controlId="email">
                                                <Form.Label>{t("labels.email")}</Form.Label>
                                                <Form.Control type="email" name='email' placeholder='Enter Email'
                                                              required/>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} lg={6}>
                                            <Form.Group className="mb-3" controlId="phoneNo">
                                                <Form.Label>{t("labels.phone_number")}</Form.Label>
                                                <PhoneInput
                                                    country="mx"
                                                    inputProps={{name: 'phone'}}
                                                    inputStyle={{width: "100%"}}
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} lg={6}>
                                            <Form.Group className="mb-3" controlId="password">
                                                <Form.Label>{t("labels.password")}</Form.Label>
                                                <InputGroup>
                                                    <Form.Control type={togglePassword.password ? "text" : 'password'}
                                                                  name='password' placeholder={t("labels.password")}
                                                                  required/>
                                                    <InputGroup.Text className='cursor-pointer' role='button'
                                                                     onClick={() => toggle('password')}>
                                                        {togglePassword.password
                                                            ? <VisibilityOff/>
                                                            : <Visibility/>
                                                        }
                                                    </InputGroup.Text>
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} lg={6}>
                                            <Form.Group className="mb-3" controlId="confirmPassword">
                                                <Form.Label>{t("labels.confirm_password")}</Form.Label>
                                                <InputGroup>
                                                    <Form.Control
                                                        type={togglePassword.confirmPassword ? "text" : 'password'}
                                                        name='confirmPassword'
                                                        placeholder={t("labels.confirm_password")} required/>
                                                    <InputGroup.Text className='cursor-pointer' role='button'
                                                                     onClick={() => toggle('confirmPassword')}>
                                                        {togglePassword.confirmPassword
                                                            ? <VisibilityOff/>
                                                            : <Visibility/>
                                                        }
                                                    </InputGroup.Text>
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                        <Button className=' mx-auto m-2' variant="outline-danger"
                                                type='submit'
                                                disabled={loader}> {loader ? t("buttons.submitting") : t("buttons.submit")}
                                        </Button>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                </div>
            </div>

        </Fragment>
    )
}


export default AdminUserRegistrationForm;
