import {Col, Container, Form, Row} from 'react-bootstrap';
import React, {useEffect, useState} from 'react';
import InvestorForm from '../components/forms/InvestorForm';
import LegalEntityForm from '../components/forms/LegalEntityForm';
import {FORM_NAMES} from '../assets/constants';
import {useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import { Header, Sidebar } from 'components';
import { useTranslation } from "react-i18next";
import { KYCForm } from 'components/forms';

import useNavigate from "hooks/Navigate";
const KYCRegistration = () => {
    const navigate = useNavigate();
    const {search, pathname} = useLocation();
    const { language, setLanguage, t } = useTranslation();

    const searchParams = new URLSearchParams(search);
    const {type} = Object.fromEntries(searchParams)

    const [formName, setFormName] = useState(type);

    const {user} = useSelector(store => store.user);

    useEffect(() =>{
        if(!user){
            navigate('/login', {state: {from: pathname}})
        }
    },[user])

    const onFormChangeHandler = (e) => setFormName(e.target.value);

 
    return (
        <div className="landing-main light-bg">
        <Sidebar/>
        <div className="landing-page landing-main-page custom-scroll">
         <Header/>
        <Container className=' pb-5'>
            <div className="investor-form custom-box p-5">
                <Row>
                    <KYCForm/>
                </Row>
            </div>
        </Container>
        </div>

        </div>
    );
};

export default KYCRegistration;
