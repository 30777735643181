/**
 @param stores
 @param methods
 */
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import {
  DeleteOutline,
  ImageOutlined,
  RemoveModerator,
  VerifiedUser,
  ReceiptLong,
  VisibilityOutlined,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import moment from "moment";

const WithDrawlTable = (withDrawl = [], methods = {}, openImagePreview ) => {
  const { navigate, handleCancelWithDrawl,toggleReceiptModal } =
    methods;
  const { language, setLanguage, t } = useTranslation();

  const columns = [
    { field: "id", headerName: t("fields.Sno"), width: 90},
    { field: "transactionId", headerName: t("fields.transactionId"), width: 220 },
  
    { field: "amount", headerName: t("fields.amount"), width: 220 },
    
    
    { field: "status", headerName: t("fields.status"), width: 220 },
    {
      field: "createdAt",
      headerName: t("fields.createdAt"),
      minWidth: 250,
    },
    {
      field: "actions",
      headerName: t("fields.actions"),
      width: 110,
      renderCell: ({ row }) => {
        const isCancel = row.status === "cancelled";
        const isApproved = row.status === "approved";
    
        const id = row._id;
console.log(row,"   _id: item._id,")
        return (

          <Grid container>
            {isCancel ? <>
              <Grid>
              <Tooltip title="Cancelled already" arrow>
                <IconButton size="small">
                <RemoveModerator className="failure" />
                </IconButton>
              </Tooltip>
            </Grid>
            </>
           : isApproved ?
           <>
           <Grid>
              <Tooltip title="Approved" arrow>
                <IconButton size="small">
                <VerifiedUser className="success" />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid>
              <Tooltip title="View Reciept" arrow>
                <IconButton onClick={() => toggleReceiptModal(row)} size="small">
                <ReceiptLong  />
                </IconButton>
              </Tooltip>
            </Grid>
            </>
          : 
           <>
            <Grid>
              <Tooltip title="Cancel WithDrawl" arrow>
                <IconButton size="small" onClick={() => handleCancelWithDrawl(id)}>
                  <DeleteOutline />
                </IconButton>
              </Tooltip>
            </Grid>
            </>}
           
          </Grid>
        );
      },
    },
  ];

  const rows =
  withDrawl.length === 0
      ? []
      : [
          ...withDrawl.map((item, index) => ({
            id: index+1,
            transactionId: item.transactionId,
            amount: item.amount,
            status: item.status,
            createdAt: moment(item.createdAt).format("MMMM Do YYYY HH:mm"),
            _id: item._id,
            file: item.file,
          })),
        ];

  return { columns, rows };
};

export default WithDrawlTable;
