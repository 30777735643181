import {Dialog, DialogContent, Grid, IconButton} from "@mui/material";

const ImagePreview = ({status = true, close, image}) => {
    return (
        <Dialog open={status} onClose={close}>
            <DialogContent sx={{ padding: 0 }}>
                <Grid item sx={{position: 'absolute', right: 5, top: 5}}>
                    <IconButton
                        size='small'
                        sx={{color: 'white', bgcolor: 'rgba(0,0,0,0.5)', padding: '0 6px', ':hover': { bgcolor: 'rgba(0,0,0,0.5)' }}}
                        onClick={close}>&#10006;</IconButton></Grid>
                <img src={image} alt='image' width='100%'/>
            </DialogContent>
        </Dialog>
    )
}

export default ImagePreview
