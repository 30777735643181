export default function detectLang(array,path, lang) {
    if(array === undefined){
        return ""
    }else {
        function getNestedTranslation(lang, keys) {
            return keys.reduce((obj, key) => {
                return obj?.[`${key}_${lang ? lang : 'sp'}`];
            }, array);
        }
        const paths = path && path.split(".");
        return (
            getNestedTranslation(lang, paths) ??
            getNestedTranslation(lang, paths) ??
            paths
        );
    }
}