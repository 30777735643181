import { Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import useNavigate from "hooks/Navigate";
const CertificateConditionModal = ({
  status,
  toggleModal,
  onChangeHandlerConditions,
}) => {
  const navigate = useNavigate();
  const { language } = useSelector((store) => store.language);
  const { t } = useTranslation();
  const submitHandler = () => {
    onChangeHandlerConditions("isCertificate", true);
    toggleModal();
  };

  return (
    <Modal
      show={status}
      onHide={toggleModal}
      centered
      className="invest-options-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Java Times Caffe Terms and conditions for investors then making
          investment
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ fontSize: "12px" }}>
   
        <Col>Términos y Condiciones para el Inversionista</Col>
        <br />
        <Col>
          Al comprar acciones, usted se compromete a proporcionar todos los
          documentos necesarios para convertirse en socio de una fracción del
          proyecto de las 300 tiendas de Java Times Caffe en México. Esta
          documentación nos permite verificar su identidad, domicilio y estatus
          fiscal.
        </Col>
        <br />
        <Col>Documentación Requerida:</Col>
        <br />
        <Col>
          INE (Identificación Oficial con Fotografía del Instituto Nacional
          Electoral)
        </Col>
        <br />
        <Col>CURP (Clave Única de Registro de Población)</Col>
        <br />
        <Col>RFC (Registro Federal de Contribuyentes)</Col>
        <br />
        <Col>
          Comprobante de domicilio reciente (no mayor a 2 meses de antigüedad)
        </Col>
        <br />
        <Col>Acta de Nacimiento</Col>
        <br />
        <Col>
          Carta de Procedencia Lícita (esta debe declarar que los fondos
          utilizados para la inversión provienen de actividades lícitas)
        </Col>
        <br />
        <Col>
          Constancia de situación fiscal en régimen de ingresos por dividendos.
        </Col>
        <br />
        <Col>Contrato firmado por ambas partes.</Col>
        <br />
        <Col>
          Usted deberá firmar el contrato y mantener una copia para sus
          registros. Le recomendamos que revise detenidamente este contrato
          antes de firmarlo.
        </Col>
        <br />

        <br />
        <Col>
          Por favor tenga en cuenta que la falta de cualquiera de estos
          documentos puede retrasar o impedir el proceso de inversión.
        </Col>
        <br />

        <br />
        <Col>
          Una vez que hayamos recibido y verificado todos sus documentos,
          procederemos a preparar su contrato de sociedad. Este contrato será
          firmado por ambas partes, indicando su acuerdo con los términos y
          condiciones de la inversión.
        </Col>
        <br />

        <br />
        <Col>
          Apreciamos su interés en ser parte de nuestro proyecto y esperamos
          trabajar con usted para expandir Java Times Caffe en México.
        </Col>
        <br />

        <br />

        <div className="Login-btn-container mb-3">
          <button type="submit" onClick={() => submitHandler()}>
            Agree
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CertificateConditionModal;
