import {createSlice} from '@reduxjs/toolkit';
import reduxApis from "../apis";


const walletSlice = createSlice({
    name: 'wallet',
    initialState: {
        loading: false,
        wallet: {
            amount: 0,
            shares: 0,
            dividend: 0,
            balance: 0,
        },
        error: null,
    },
    reducers: {
        walletNull: (state, action) => {
            state.wallet = null
        },
        setWallet: (state, action) => {
            state.wallet = action.payload
        },
        
    },
    extraReducers: {
        [reduxApis.getWallet.pending]: (state, action) => {
            state.loading = true;
        },
        [reduxApis.getWallet.fulfilled]: (state, action) => {
            state.loading = false;
            state.wallet = action.payload.data.wallet;
        },
        [reduxApis.getWallet.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});


export const {walletNull,setWallet} = walletSlice.actions;
export default walletSlice.reducer;
