import React, { useEffect } from 'react';
import { CircularProgress, Button } from "@mui/material";
import apis from 'services';
import { useSelector } from 'react-redux';
import useLocalizedNavigate from 'hooks/Navigate';


function UserNotification({ notifications, loader ,showDropdown , unseenCount}) {

  const navigate = useLocalizedNavigate()
    console.log(notifications, "notificationsnotifications");
    const { adminUser} = useSelector((store) => store.admin);
    const { user } = useSelector((store) => store.user);

    const first5Notifications = notifications?.slice(0, 4);
    const seenNotification = async () => {
        try {
           await apis.seenNotification(user?.id)
        }
        catch (e) {
            console.log(e)
        }
    }

    const seenAdminNotification = async () => {
        try {
           await apis.seenAdminNotificationHistory(adminUser?._id)
        }
        catch (e) {
            console.log(e)
        }
    }
console.log(unseenCount,"unseenCount")
    useEffect(() => {
        if (user && unseenCount > 0) {
            seenNotification();
        }
        else if(adminUser && unseenCount > 0)
        {
            seenAdminNotification();
        }

    }, [showDropdown])


    return (
        <>
            <div className='info-title m-3'>Notifications</div>

            {loader ? (
                <div className='m-5 d-flex justify-content-center align-items-center'>
                    <CircularProgress size={23} color="error" />
                </div>
            ) : (
                <div  >
                    {first5Notifications?.length > 0 ? (
                        first5Notifications?.map((item, id) => (
                            <div onClick={()=>navigate("/notifications")} key={id} className={`Notification-in-header ${item?.isSeen ? '' : 'is_seen'}`} >
                                <div className='image-holder'>
                                    <img src='/images/coffee_logo.png' alt="" />
                                </div>
                                <div className='text-area'>
                                    <div className='name-txt line-ellipse'>
                                        <span>{item?.notification?.title}</span>
                                    </div>
                                    <div className='msg-txt line-ellipse'>
                                        {item?.notification?.body}
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div  className='m-5 d-flex justify-content-center align-items-center'>No Notification!</div>
                    )}

                    {notifications?.length >= 5 && (
                        <div
                        onClick={()=>navigate("/notifications")}
                        style={{
                            color: "red",
                            fontSize: "16px",
                            cursor: "pointer",
                        }} className="text-center mt-3 red cursor-pointer">
                            View All
                        </div>
                    )}
                </div>
            )}
        </>
    );
}

export default UserNotification;
