import { Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import useNavigate from "hooks/Navigate";
const SignUpConditionModal = ({ status, toggleModal, setState }) => {
  const navigate = useNavigate();
  const { language } = useSelector(store => store.language)
  const { t } = useTranslation();
const submitHandler=()=>{
  setState(true)
  toggleModal()
}
  return (
    <Modal
      show={status}
      onHide={toggleModal}
      centered
      className="invest-options-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Java Times Caffe Terms And Conditions</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ fontSize: '12px' }}>
        <Col style={{ fontSize: '14px' }}>Dear Investor:</Col>
        <br />
        <Col>{t("new-changes.col-1-reg")}</Col>
        <br />
        <Col>{t("new-changes.col-2-reg")}</Col>
        <br />
        <Col>{t("new-changes.col-3-reg")}</Col>
        <br />
        <Col>{t("new-changes.col-4-reg")}</Col>
        <br />
        <Col>{t("new-changes.col-5-reg")}</Col>
        <br />
        <Col>{t("new-changes.col-6-reg")}</Col>
        <br />

        <div className='Login-btn-container mb-3' >

          <button type='submit' onClick={submitHandler} >
          {t("new-changes.agree")}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SignUpConditionModal;
