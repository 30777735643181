/**
 @param stores
 @param methods
 */
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import {
  DeleteOutline,
  ImageOutlined,
  RemoveModerator,
  VerifiedUser,
  ReceiptLong,
  VisibilityOutlined,
  Delete
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import moment from "moment";

const AdminWithDrawlTable = (withDrawl = [], methods = {}, openImagePreview) => {
  const { navigate, handleApprovedWithDrawl , toggleReceiptModal } =
    methods;
  const { language, setLanguage, t } = useTranslation();

  const columns = [
    { field: "id", headerName: t("fields.Sno"), width: 90 , hidden : true},
    { field: "fullName", headerName: t("fields.fullName"), width: 240 },
    { field: "walletId", headerName: t("fields.WalletID"), width: 240 },
    { field: "transactionId", headerName: t("fields.transactionId"), width: 220 },
    { field: "amount", headerName: t("fields.amount"), width: 120 },
    { field: "status", headerName: t("fields.status"), width: 120 },
    {
      field: "createdAt",
      headerName: t("fields.createdAt"),
      minWidth: 210,
    },
    {
      field: "actions",
      headerName: t("fields.actions"),
      width: 110,
      renderCell: ({ row }) => {
        const isCancel = row.status === "cancelled";
        const isApproved = row.status === "approved";
    
        const id = row._id;
      
        const amount = row.amount;
        return (

          <Grid container>
            {isCancel ? <>
              <Grid>
              <Tooltip title="Cancelled already by user" arrow>
                <IconButton size="small">
                <Delete/>
                </IconButton>
              </Tooltip>
            </Grid>
            </>
           : isApproved ?
           <>
           <Grid>
              <Tooltip title="Approved" arrow>
                <IconButton size="small">
                <VerifiedUser className="success" />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid>
              <Tooltip title="View Reciept" arrow>
                <IconButton onClick={() => toggleReceiptModal(row)} size="small">
                <ReceiptLong  />
                </IconButton>
              </Tooltip>
            </Grid>
            </>
          : 
           <>
            <Grid>
              <Tooltip title="Approved WithDrawl" arrow>
                <IconButton size="small" onClick={() => handleApprovedWithDrawl(id,amount)}>
                <RemoveModerator className="failure" />
                </IconButton>
              </Tooltip>
            </Grid>
            </>}
           
          </Grid>
        );
      },
    },
  ];

  const rows =
  withDrawl.length === 0
      ? []
      : [
          ...withDrawl.map((item, index) => ({
            id: index+1,
            fullName: item?.userId?.name || item?.adminId?.firstName + " " +item?.adminId?.lastName,
            transactionId:item?.transactionId,
            walletId: item?.walletId,
            amount: item?.amount,
            status: item?.status,
            createdAt: moment(item?.createdAt).format("MMMM Do YYYY HH:mm"),
            _id: item?._id,
            file: item.file,
          })),
        ];

  return { columns, rows };
};

export default AdminWithDrawlTable;
