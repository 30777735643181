import React from "react";
import { Col, Row, Tooltip } from "react-bootstrap";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

const data = [
  {
    year: "01",
    "Sales Revenue": 7660800000,
    COGS: 1991808000,
    "Net Profit": 2552274039,
  },
  {
    year: "02",
    "Sales Revenue": 42681600000,
    COGS: 11097216000,
    "Net Profit": 15410431575,
  },
  {
    year: "03",
    "Sales Revenue": 99809280000,
    COGS: 25950412800,
    "Net Profit": 37736354520,
  },
  {
    year: "04",
    "Sales Revenue": 162846720000,
    COGS: 42340147200,
    "Net Profit": 64340361720,
  },
  {
    year: "05",
    "Sales Revenue": 231575040000,
    COGS: 60209510400,
    "Net Profit": 107561907000,
  },
];

const COLORS = ["#2ECA7F", "#D71F26", "#007DF0"];

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-chart-tooltip">
        <p className="label">{`${label} : ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

function formatNumber(num) {
  return num.toLocaleString(undefined, { maximumFractionDigits: 0 });
}

const FinancialChart = () => {
  return (
    <>
      <div className="financial-chart">
        <ResponsiveContainer width="100%" height={400}>
          <BarChart width={730} height={250} data={data}>
            <CartesianGrid opacity={0.5} vertical={false} />
            <Tooltip content={<CustomTooltip />} />
            <XAxis dataKey="name" tick={false} />
            <YAxis
              width={150}
              tickFormatter={(number) => `$${number}`}
              tickCount={8}
              axisLine={false}
            />
            <Bar
              dataKey="Sales Revenue"
              fill={COLORS[0]}
              radius={[20, 20, 0, 0]}
              barSize={45}
            />
            <Bar
              dataKey="COGS"
              fill={COLORS[1]}
              radius={[20, 20, 0, 0]}
              barSize={45}
            />
            <Bar
              dataKey="Net Profit"
              fill={COLORS[2]}
              radius={[20, 20, 0, 0]}
              barSize={45}
            />
          </BarChart>
        </ResponsiveContainer>
        {/* =================================CHART STATISTICS TABLE======================================= */}
        <div className="chart-stats pt-3">
          <Row className="padding-from-left-for-chart_1">
            <Col className="text-center col col-md-2">
              <p className="legend-cell_1">Year</p>
            </Col>
            {data.map((item) => {
              return (
                <>
                  <Col className="col col-md-2">
                    <p className="legend-cell_1">{item["year"]}</p>
                  </Col>
                </>
              );
            })}
          </Row>
          <Row>
            <Col ml={2}>
              <div className="p-3 light-bg">
                <div className="legend-entry">
                  <div
                    className="square-dot"
                    style={{ backgroundColor: COLORS[0] }}
                  ></div>
                  <span className="dark">Sales Revenue</span>
                </div>
                <div className="legend-entry">
                  <div
                    className="square-dot"
                    style={{ backgroundColor: COLORS[1] }}
                  ></div>
                  <span className="dark">COGS</span>
                </div>
                <div className="legend-entry last">
                  <div
                    className="square-dot"
                    style={{ backgroundColor: COLORS[2] }}
                  ></div>
                  <span className="dark">Net Profit</span>
                </div>
              </div>
            </Col>
            {data.map((item) => {
              return (
                <>
                  <Col xs={2} className="p-3 hide-on-responsive">
                    <p className="legend-cell">
                      $ {formatNumber(item["Sales Revenue"])}
                    </p>
                    <p className="legend-cell">
                      $ {formatNumber(item["COGS"])}
                    </p>
                    <p className="legend-cell">
                      $ {formatNumber(item["Net Profit"])}
                    </p>
                  </Col>
                </>
              );
            })}
          </Row>
        </div>
      </div>
    </>
  );
};

export default FinancialChart;
