import { Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useNavigate from "hooks/Navigate";
import { setUserInvestment } from "redux/slices/userSlice";
import { FaCheckCircle, FaTimesCircle, FaQuestionCircle } from "react-icons/fa";

import { useEffect, useState } from "react";
import apis from "services";

const PromptModal = ({ status, toggleModal }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const submitHandler = () => {
    toggleModal();
    navigate("/user/document");
  };

  const { user } = useSelector((store) => store.user);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getDocumentData = async () => {
    try {
      setLoading(true);
      const response = await apis.getUserDocuments(user?.id);
      if (response?.data?.status) {
        console.log(response?.data?.documents, "response");
        setData(response?.data?.documents);
        dispatch(setUserInvestment(response?.data?.analytics));
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e?.message);
    }
  };

  useEffect(() => {
    getDocumentData();
  }, [status]);

  // Create a function to check the status of each required document
  const getDocumentStatus = (documentType) => {
    const document = data.find((item) => item.type === documentType);
    return document ? document.status : "pending";
  };

  return (
    <Modal
      show={status}
      onHide={toggleModal}
      centered
      className="invest-options-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Document requirements for Java Times Cafe</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ fontSize: "12px" }}>
        <Col>
          <h6>
            Please complete the required documentation for the pending
            investment procedure.
          </h6>
        </Col>
        <br />

        <Col>
          o INE (Identification Document) Front:{" "}
          {getDocumentStatus("INE_FRONT") === "approved" ? (
            <FaCheckCircle color="green" />
          ) : getDocumentStatus("INE_FRONT") === "rejected" ? (
            <FaTimesCircle color="red" />
          ) : (
            <FaQuestionCircle color="gray" />
          )}
        </Col>
        <br />

        <Col>
          o INE (Identification Document) Back:{" "}
          {getDocumentStatus("INE_BACK") === "approved" ? (
            <FaCheckCircle color="green" />
          ) : getDocumentStatus("INE_BACK") === "rejected" ? (
            <FaTimesCircle color="red" />
          ) : (
            <FaQuestionCircle color="gray" />
          )}
        </Col>
        <br />

        <Col>
          o CURP (Unique Population Registry Code):{" "}
          {getDocumentStatus("CURP") === "approved" ? (
            <FaCheckCircle color="green" />
          ) : getDocumentStatus("CURP") === "rejected" ? (
            <FaTimesCircle color="red" />
          ) : (
            <FaQuestionCircle color="gray" />
          )}
        </Col>
        <br />

        <Col>
          o RFC (Federal Taxpayer Registry):{" "}
          {getDocumentStatus("RFC") === "approved" ? (
            <FaCheckCircle color="green" />
          ) : getDocumentStatus("RFC") === "rejected" ? (
            <FaTimesCircle color="red" />
          ) : (
            <FaQuestionCircle color="gray" />
          )}
        </Col>
        <br />

        <Col>
          o Proof of address (maximum 2 months old):{" "}
          {getDocumentStatus("address") === "approved" ? (
            <FaCheckCircle color="green" />
          ) : getDocumentStatus("address") === "rejected" ? (
            <FaTimesCircle color="red" />
          ) : (
            <FaQuestionCircle color="gray" />
          )}
        </Col>
        <br />

        <Col>
          o Birth certificate:{" "}
          {getDocumentStatus("birthCertificate") === "approved" ? (
            <FaCheckCircle color="green" />
          ) : getDocumentStatus("birthCertificate") === "rejected" ? (
            <FaTimesCircle color="red" />
          ) : (
            <FaQuestionCircle color="gray" />
          )}
        </Col>
        <br />

        <Col>
          o Letter of Proceeding Licita:{" "}
          {getDocumentStatus("Licita") === "approved" ? (
            <FaCheckCircle color="green" />
          ) : getDocumentStatus("Licita") === "rejected" ? (
            <FaTimesCircle color="red" />
          ) : (
            <FaQuestionCircle color="gray" />
          )}
        </Col>
        <br />

        <Col>
          o Fiscal Proof:{" "}
          {getDocumentStatus("Fiscal") === "approved" ? (
            <FaCheckCircle color="green" />
          ) : getDocumentStatus("Fiscal") === "rejected" ? (
            <FaTimesCircle color="red" />
          ) : (
            <FaQuestionCircle color="gray" />
          )}
        </Col>
        <br />

        <Col>
          o Contract signed:{" "}
          {getDocumentStatus("contract") === "approved" ? (
            <FaCheckCircle color="green" />
          ) : getDocumentStatus("contract") === "rejected" ? (
            <FaTimesCircle color="red" />
          ) : (
            <FaQuestionCircle color="gray" />
          )}
        </Col>
        <br />

        <br />

        <div className="Login-btn-container mb-3">
          <button type="submit" onClick={submitHandler}>
            Continue
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PromptModal;
