import React from "react";
import { Grid, IconButton, Tooltip } from "@mui/material";
import {
  Delete,
  RemoveModerator,
  Send,
  VerifiedUser,
  Visibility,
  MonetizationOn,
  Download
} from "@mui/icons-material";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from "react-i18next";
/**
 * @param users
 * @param methods
 * @return {{columns: [{headerName: string, field: string, width: number},{headerName: string, field: string, width: number},{headerName: string, field: string, width: number},{headerName: string, field: string, width: number},{headerName: string, field: string, width: number},null], rows: *[]}}
 */
const AnnualProgressTable = (users = [], methods = {}) => {
  const { openModal ,sendToTheInvestor , adminUser} = methods;
  const {  t } = useTranslation();


  const handleDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = '';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  const columns = [
    { field: "id",
     headerName: "#",    
     width: 50 },
    {
      field: "title",
      headerName: t("fields.title"),
      minWidth: 150,
      flex: 1,
    },
    {
      field: "file",
      headerName: t("fields.viewFile"),
      width: 150,
      renderCell: (props) => {
        const id = props.row.file;
        return (
             <>   
             <a href={id} target="_blank">   <Visibility />
               </a>
               </>
        );
      },
    }, 
    {
      field: "createdAt",
      headerName: t("fields.createdAt"),
      minWidth: 150,
      flex: 1,
    },
    {
      field: "actions",
      headerName: t("fields.actions"),
      minWidth: 250,
      flex: 1,
      renderCell: (props) => {
        const id = props.row._id;
        const { file } = props.row;
        return (
          <Grid container>
           {
          adminUser ?
          <>
          <Grid item>
          <Tooltip title="Delete" arrow>
            <IconButton size="small" onClick={() => openModal(id)}>
              <Delete />
            </IconButton>
          </Tooltip>
        </Grid>
          <Grid item>
          <Tooltip title="Send To The Investor" arrow>
            <IconButton size="small">
              <Send  onClick={() => sendToTheInvestor()}/>
            </IconButton>
          </Tooltip>
        </Grid>
        </>
        :
        <Grid item>
        <Tooltip title="Download Annual Report" arrow>
          <IconButton size="small">
            <Download onClick={() => handleDownload(file)}/>
          </IconButton>
        </Tooltip>
      </Grid>
         }
         
           </Grid>
        );
      },
    },
  ];

  const rows =
    users.length === 0
      ? []
      : [
          ...users.map((user, id) => ({
            id: id + 1,
            title: user.title,
            file:user.file,
            createdAt: user.createdAt,
            _id: user._id,
          })),
        ];

  return { rows, columns };
};

export default AnnualProgressTable;
