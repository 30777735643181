import {Box} from '@mui/material';
import {toast} from "react-toastify";
import {Container} from "react-bootstrap";
import React, {useEffect, useState} from 'react';
import { useParams} from 'react-router-dom';

import "react-toastify/dist/ReactToastify.css";

import useNavigate from "hooks/Navigate";
import apis from '../../services';
import CssBaseline from "@mui/material/CssBaseline";
import {AddStoreForm} from "../../components/forms";
import AdminSidebar from "../../components/admin/AdminSidebar";
import AdminNewSidebar from 'components/admin/AdminNewSidebar';
import { Header } from 'components';
import { useSelector } from 'react-redux';



const INITIAL_FORM_STATE = {
    title_en: "",
    title_sp: "",
    description_en: "",
    description_sp: "",
    totalShares: "",
    location: {
        state: "",
        city: "",
        address: "",
        postalCode: ""
    },
    // image: "",
    files: [{ type: "", file: "" }],
    pitches: [{ pitchTitleEnglish: "", pitchTitleSpanish: "", pitchDescriptionEnglish: "", pitchDescriptionSpanish: "", pitchImage: "" }]
};

function AddUpdateStore() {
    const [storeData, setStoreData] = useState(INITIAL_FORM_STATE);
    const {id: storeId} = useParams();
    
    const {adminUser} = useSelector((store) => store.admin);
    const navigate = useNavigate();
 
    const onChangeHandler = (e, index, type) => {
        const { name, value, files } = e.target;
        if (type === 'pitches') {
            const pitches = [...storeData.pitches];
            pitches[index] = {
                ...pitches[index],
                [name]: files ? files[0] : value,
            };
            return setStoreData((prevState) => ({ ...prevState, pitches }));
        }
        if (type === 'files') {
            const filesArray = [...storeData.files];
            filesArray[index] = {
                ...filesArray[index],
                [name]: files ? files[0] : value,
            };
            return setStoreData((prevState) => ({ ...prevState, files: filesArray }));
        }
        if (files) return setStoreData((prevState) => ({ ...prevState, [name]: files[0] }));
        if (['state', 'city', 'address', 'postalCode'].includes(name))
            return setStoreData((prevState) => ({ ...prevState, location: { ...prevState.location, [name]: value } }));
        setStoreData((prevState) => ({ ...prevState, [name]: value }));
    };

    const fetchStoreDetails = async (storeId) => {
        try {
            const res = await apis.getStore(storeId);
            if (res.data.status) {
                setStoreData(res.data.store);
            }
        } catch (e) {
            console.error('ERROR in fetching store details', e);
        }
    };

    const onCreateStoreHandler = async (data) => {
        try {
            const res = await apis.createStore(data);
            if (res?.data?.status) {
                toast.success(res.data.message);
                const redirect = adminUser?.type === "owner" ?  "/admin/stores" : "/sub-admin/stores";
                navigate(redirect);
                setStoreData(INITIAL_FORM_STATE);
            }
        } catch (e) {
            console.error('ERROR on Create Store', e);
        }
    };

    const onUpdateStoreHandler = async (data) => {
        try {
            const res = await apis.updateStore(data);
            if (res?.data?.status) {
                toast.success(res.data.message);
                const redirect = adminUser?.type === "owner" ?  "/admin/stores" : "/sub-admin/stores";
                navigate(redirect);
                setStoreData(INITIAL_FORM_STATE);
            }
        } catch (e) {
            console.error('ERROR on update Store', e);
        }
    };

    useEffect(() => {
        (async () => {
            if (storeId)
                await fetchStoreDetails(storeId);
        })();
    }, [storeId]);

    const onSubmitHandler = async (data) => {
        if (storeId) await onUpdateStoreHandler(storeData);
        else await onCreateStoreHandler(storeData);
    };

    const addPitch = () => {
        setStoreData((prevState) => ({
            ...prevState,
            pitches: [...prevState.pitches, { pitchTitleEnglish: "", pitchTitleSpanish: "", pitchDescriptionEnglish: "", pitchDescriptionSpanish: "", pitchImage: "" }]
        }));
    };

    const removePitch = (index) => {
        setStoreData((prevState) => ({
            ...prevState,
            pitches: prevState.pitches.filter((_, i) => i !== index)
        }));
    };

    const addFile = () => {
        setStoreData((prevState) => ({
            ...prevState,
            files: [...prevState.files, { type: "", file: "" }]
        }));
    };

    const removeFile = (index) => {
        setStoreData((prevState) => ({
            ...prevState,
            files: prevState.files.filter((_, i) => i !== index)
        }));
    };

    return (
        <Box className="dashboard-main">
            <AdminNewSidebar />
            <div className="dashboard-section-main">
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        height: "100vh",
                        overflow: "auto",
                    }}
                    className="custom-scroll"
                >
                    <Header />
                    <Container>
                        <h3 className="section-title dark-text text-start mb-0">{storeId ? 'Update' : 'Add'} Store</h3>
                        <AddStoreForm
                            formData={storeData}
                            onChangeHandler={onChangeHandler}
                            addPitch={addPitch}
                            removePitch={removePitch}
                            addFile={addFile}
                            removeFile={removeFile}
                            submitHandler={onSubmitHandler}
                            heading={storeId ? 'Update' : 'Add'}
                            storeId={storeId}
                            setStoreData={setStoreData}
                        />
                    </Container>
                </Box>
            </div>
        </Box>
    );
}

export default AddUpdateStore;
