import { Footer, MainHeader, TeamCard } from "components";
import { useTranslation } from "react-i18next";
import React, {useState } from "react";
import imageUrl from "utils/imageUrl";


function TermOfUse() {


  const [showDropdown, setShowDropdown] = useState(false);

  const { t } = useTranslation();


 

  return (
    <>
      <div
        onClick={() => {
          if (showDropdown) setShowDropdown(!showDropdown);
        }}
      >
        <div className="page-banner">
          <MainHeader
            showDropdown={showDropdown}
            setShowDropdown={setShowDropdown}
          />

          <img
            src={imageUrl("about_img.webp")}
            className="background-img"
            alt=""
          />
          <div className="page-banner-body">
            <p className="page-banner-title mx-3">
              {t("titles.termsOfUse")}
            </p>
          </div>
        </div>
        <section className="py-5">
          <div className="container">
            <div className="row">
              <div className="mx-auto col-lg-10">
                <div className="container d-flex justify-content-between">
                  {/* <h4 className="section-top-title mb-4">
                    {t("titles.referralTitle")}
                  </h4> */}
                </div>

                <div className="container mb-5">
                  <div className="invite-friends">
               
                  <div dangerouslySetInnerHTML={{ __html: t("titles.termsDetails") }}></div>
            
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
}

export default TermOfUse;
