import React from "react";
// import {Link} from "react-router-dom";
import {NotFoundImg} from "../components/Images";
import {ArrowBackOutlined} from "@mui/icons-material";
import useLocationNavigation from "../hooks/useLocationNavigation";
import Loader from "../components/Loader";
import useLanguageUrl from "hooks/useLanguageUrl";
import Link from 'hooks/Link';

const NotFound = () => {
    const loader = useLanguageUrl()

    // if (loader) return <Loader/>

    return (
        <div>
            {" "}
            <div className="not-found-image ">
                <img src={NotFoundImg}/>
                <div className="button ">
                    <Link to={"/"}>
                        <ArrowBackOutlined/>
                        <span>Go to Home</span>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
