import { useEffect, useState } from "react";
import reduxApis from "redux/apis";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation} from "react-router-dom";

import useNavigate from "hooks/Navigate";
import Link from 'hooks/Link';
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  FormText,
  Row,
} from "react-bootstrap";
import { Header, Sidebar } from "components";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import apis from "services";
import { toast } from "react-toastify";

const ForgetPassword = () => {
  const [formData, setFormData] = useState({ email: ""});
  const { language, setLanguage, t } = useTranslation();
const [loading,setLoading]=useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();

  const { user } = useSelector((store) => store.user);

  if (user) {
    navigate(state ? state.from : "/user/dashboard");
  }

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, []);

  const onChangeHandler = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [id]: value }));
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
      const res= await apis.forget(formData);
      if(res?.data?.status)
      {
        toast.success(res?.data?.message,{id:1})
      }
      else{
        toast.error(res?.data?.message,{id:1})  
      }
    
      };

  return (
    <div className="landing-main">
      <div className="landing-page landing-main-page custom-scroll New-login-form-main">
        <Header />
        <Container>
          <Form onSubmit={onSubmitHandler} className="px-4">
            <Row className=" mt-4">
              <Col md={2} lg={3} />

              <Col md={8} lg={6} className="custom-box login">
                <h3 className="dashboard-title text-center">{t("titles.forgetPass")}</h3>

                <Form.Group className="mb-3 mt-4" controlId="email">
                  <Form.Control
                    type="email"
                    placeholder={t("placeholders.email")}
                    onChange={onChangeHandler}
                    value={formData.email}
                    required
                  />
                </Form.Group>

                <div className="center-wrapper">
                  <Button className="lp-btn" type="submit">
                    Continue
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </div>
  );
};

export default ForgetPassword;
