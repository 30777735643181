import AdminNewSidebar from "components/admin/AdminNewSidebar";
import React, { useEffect, useState } from "react";
import { Box, Container } from "@mui/material";
import { Header } from "components";
import { useTranslation } from "react-i18next";
import { Table, Button, Form, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import apis from "../../services";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAdmin } from "redux/slices/adminSlice";

import useNavigate from "hooks/Navigate";
export default function AdminSendNotification() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false)
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [showSelectAllUsers, setShowSelectAllUsers] = useState(false);



  let { id } = useParams();


  const [data, setData] = useState({
    title: '',
    target: 'All-Users',
    type: 'news',
    body: '',
    image: '',
    userIds: [],
    url: '',
    from: "admin",
    to: "users"
  });

  const onChangeHandler = (e) => {
    const { id, value, files } = e.currentTarget;
    if (files)
      return setData((prevState) => ({ ...prevState, [id]: files[0] }));
    else {
      setData((prevState) => ({ ...prevState, [id]: value }));
      setShowSelectAllUsers(value === 'Selected-Users');
      if (value !== "Selected-Users" ) {
        setData((prevState) => ({ ...prevState, userIds: [] }));

      }
    }

  };

  const fetchAllUsers = async () => {
    const res = await apis.getAllUser();
    setUsers(res?.data?.users);
  };

  useEffect(() => {
    (async () => {
      await fetchAllUsers();
    })();
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (data?.target === "Selected-Users" && !data?.userIds?.length > 0) {
      return toast.error("Please select the users");
    }
    const form_data = new FormData();
    for (const [key, value] of Object.entries(data)) {
      if (Array.isArray(value)) {
        for (const id of value) {
          form_data.append(`${key}[]`, id);
        }
      } else {
        form_data.append(key, value);
      }
    }
    try {
      setLoader(true);
      const response = await apis.adminSendNotification(form_data);
      if (response.data?.status) {
        toast.success(response.data.message);
      }
      setLoader(false);
    } catch (e) {
      console.error(e);
      toast.error(e.message)
      setLoader(false);
    }

  }
  const handleUserSelection = (userId) => {
    setData((prevState) => {
      if (prevState.userIds.includes(userId)) {
        const updatedUserIds = prevState.userIds.filter((id) => id !== userId);
        return { ...prevState, userIds: updatedUserIds };
      } else {
        const updatedUserIds = [...prevState.userIds, userId];
        return { ...prevState, userIds: updatedUserIds };
      }
    });
  };
  const filterUsers = () => {
    const filtered = users.filter((user) => {
      const fullName = `${user.name} ${user.email}`.toLowerCase();
      return fullName.includes(searchQuery.toLowerCase());
    });
    setFilteredUsers(filtered);
  };
  const handleSelectAllUsers = (e) => {
    const checked = e.target.checked;
    if (checked) {
      if (data?.target === "specific") {
        const allUserIds = users.map((user) => user._id);
        setData((prevState) => ({ ...prevState, userIds: allUserIds }));
      }
      else {
        setData((prevState) => ({ ...prevState, userIds: [] }));
      }

    }
    else {
      setData((prevState) => ({ ...prevState, userIds: [] }));
    }
  };

  console.log(data, "DATAAAAAAA")

  return (
    <>
      <Box className="dashboard-main">
        <AdminNewSidebar />
        <div className="dashboard-section-main">
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
            className="custom-scroll"
          >
            <Header />
            <Container maxWidth="lg" sx={{ mt: 0, mb: 4 }}>
              <div className="bg-light p-2 mb-5">
                <h3 className="m-3"> Send Notification</h3>
                <Row>
                  <Form className="d-flex justify-content-around flex-columns flex-wrap" onSubmit={submitHandler}>
                    <Col xs={12} md={6} lg={6} className="mt-3">
                      <Form.Group className="m-2">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                          type="text"
                          id="title"
                          placeholder="Enter Title"
                          onChange={onChangeHandler}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={6} className="mt-3">
                      <Form.Group className="m-2">
                        <Form.Label>Message</Form.Label>
                        <Form.Control
                          as="textarea"
                          id="body"
                          placeholder="Enter Message"
                          onChange={onChangeHandler}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={12} lg={6} className="mt-3">
                      <Form.Group className="m-2" controlId="image">
                        <Form.Label>Image (Optional)</Form.Label>
                        <Form.Control type="file" name='image' placeholder='Image'
                          onChange={onChangeHandler} />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={6} className="mt-3">
                      <Form.Group className="m-2">
                        <Form.Label>Redirect Url (Optional)</Form.Label>
                        <Form.Control
                          type="text"
                          id="url"
                          placeholder="Enter Redirect URL"
                          onChange={onChangeHandler}
                        />
                      </Form.Group>
                    </Col>

                    <Col xs={12} md={6} lg={6} className="mt-3">
                      <Form.Group className="m-2" controlId="state">
                        <Form.Label>Type</Form.Label>
                        <Form.Select id='type' onChange={onChangeHandler} >
                          <option disabled>Select type...</option>
                          {['news', 'offers'].map(state => <option key={state}>{state}</option>)}
                        </Form.Select>
                      </Form.Group>
                    </Col>



                    <Col xs={12} md={6} lg={6} className="mt-3">
                      <Form.Group className="m-2" controlId="state">
                        <Form.Label>Target</Form.Label>
                        <Form.Select id='target' onChange={onChangeHandler} >
                          <option disabled>Select target...</option>
                          {['All-Users', 'Investors', 'Non-Investors', 'Selected-Users'].map(state => <option key={state}>{state}</option>)}
                        </Form.Select>
                      </Form.Group>
                    </Col>

                    {data?.target === "Selected-Users" ?
                      <>
                        <Col xs={12} md={6} lg={6} className="mt-3">
                          <Form.Group className="m-2">
                            <Form.Label>Search by Name or Email</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter name or email"
                              value={searchQuery}
                              onChange={(e) => setSearchQuery(e.target.value)}
                              onKeyUp={filterUsers}
                            />
                          </Form.Group>
                        </Col>

                        <Col xs={12} md={12} lg={12} className="mt-3">
                          <Form.Group className="m-2" controlId="users">
                            <div className="d-flex justify-content-between flex-wrap">
                              <Form.Label>Select Users</Form.Label>

                              <Form.Group controlId="selectAllUsers" className="mb-3">
                                {/* <Form.Check
                                    type="checkbox"
                                    label="Select All Users"
                                    onChange={handleSelectAllUsers}
                                  /> */}
                                Selected Users {data?.userIds?.length || 0}
                              </Form.Group>
                            </div>


                            <div style={{ overflow: "auto", maxHeight: "150px" }} >

                              <Table bordered hover size="sm">
                                <thead>
                                  <tr style={{ textAlign: "center" }}>
                                    <th>Name</th>
                                    <th>Email</th>


                                    <th>Investor</th>
                                    <th>Verified</th>

                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {searchQuery !== ""
                                    ? filteredUsers.length > 0
                                      ? filteredUsers.map((item, index) => {
                                        return (
                                          <tr key={index} style={{ textAlign: "center" }}>
                                            <td>{item?.name}</td>
                                            <td>{item?.email}</td>

                                            <td> {item?.isInvestor ? <span style={{ color: 'green' }}>Yes</span> : <span style={{ color: "red" }}>Not yet</span>}</td>
                                            <td>  {item?.isVerified ? <span style={{ color: 'green' }}>Yes</span> : <span style={{ color: "red" }}>Not yet</span>}</td>

                                            <td>
                                              <input
                                                style={{ marginTop: '12px' }}
                                                type="checkbox"
                                                onClick={() => handleUserSelection(item?._id)}
                                                checked={data.userIds.includes(item?._id)}
                                              />
                                            </td>
                                          </tr>
                                        );
                                      })
                                      : (
                                        <tr style={{ textAlign: "center" }}>
                                          <td colSpan={5}>No matching users</td>
                                        </tr>
                                      )
                                    : users?.length > 0
                                      ? users?.map((item, index) => {
                                        return (
                                          <tr key={index} style={{ textAlign: "center" }}>
                                            <td className="text-align-center">{item?.name}</td>
                                            <td className="text-align-center">{item?.email}</td>
                                            <td> {item?.isInvestor ? <span style={{ color: 'green' }}>Yes</span> : <span style={{ color: "red" }}>Not yet</span>}</td>
                                            <td>  {item?.isVerified ? <span style={{ color: 'green' }}>Yes</span> : <span style={{ color: "red" }}>Not yet</span>}</td>

                                            <td >
                                              <input
                                                style={{ marginTop: '12px' }}
                                                type="checkbox"
                                                onClick={() => handleUserSelection(item?._id)}
                                                checked={data.userIds.includes(item?._id)}
                                              />
                                            </td>
                                          </tr>
                                        );
                                      })
                                      : (
                                        <tr style={{ textAlign: "center" }}>
                                          <td colSpan={3}>No Users</td>
                                        </tr>
                                      )}
                                </tbody>
                              </Table>


                            </div>
                          </Form.Group>
                        </Col>
                      </>
                      :

                      <></>

                    }
                    <div className="m-3">
                      <Button className=' mx-auto' variant="outline-danger"
                        type='submit'
                        disabled={loader}> {loader ? t("buttons.submitting") : t("buttons.submit")}
                      </Button>
                    </div>
                  </Form>
                </Row>

              </div>
            </Container>
          </Box>
        </div>
      </Box>
    </>
  );
}
