import { MainHeader } from "components";
import { teamMembers } from "dummyData";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaGraduationCap } from "react-icons/fa";
import { useParams } from "react-router-dom";
import cms from "services/cms";
import cmsImage from "utils/cmsImage";
import detectLang from "utils/detectLang";
import _html from "utils/_html";

const TeamMember = ({ TeamMembers }) => {
  // const { lang: _lang } = useParams();
  // const lang = _lang || "en";  
  const [showDropdown, setShowDropdown] = useState(false);

  const lang = localStorage.getItem("i18nextLng") || "sp";

  const { id } = useParams();
  const [section, setSection] = useState({});
  const fetchSection = async () => {
    const res = await cms.showSection(id);
    setSection(res?.data?.data?.content);
  };
  useEffect(() => {
    (async () => {
      await fetchSection();
    })();
  }, []);
  return (
    <>
      <MainHeader bg showDropdown={showDropdown} setShowDropdown={setShowDropdown} />
      <section className="team-member-section py-5 mt-5" onClick={()=>{ if(showDropdown) setShowDropdown(!showDropdown)}}>
        <Container>
          <Row className="g-5">
            <Col md={3} className="px-4 grey-bg p-4 member-open-card">
              <div className="investor-card-img mb-2">
                <div className="investor-card-img-inner">
                  <img src={cmsImage(section?.image)} alt="" />
                </div>
              </div>
              <p className="custom-card-title small text-center fw-light text-capitalize fw-semibold">
                {detectLang(section, "name", lang)}
              </p>
              <p className="card-subtitle text-center">
                {detectLang(section, "designation", lang)}
              </p>
              {/* <p className="card-subtitle text-center">Lorem, ipsum dolor.</p> */}
            </Col>
            <Col md={9}>
              <p className="section-title text-start mb-2">
                {detectLang(section, "name", lang)}
              </p>
              <div className="card-line mb-3"></div>
              <p className="text-body simple-text">
                {_html(detectLang(section, "description", lang))}
              </p>
              {/* <Row>
                <Col md={6}>
                  <p className="blog-card-title">Lorem, ipsum.</p>
                  <ul className="p-0">
                    <li>• Lorem, ipsum.</li>
                    <li>• Lorem, ipsum.</li>
                    <li>• Lorem, ipsum.</li>
                    <li>• Lorem, ipsum.</li>
                  </ul>
                </Col>
                <Col md={6}>
                  <p className="blog-card-title">Lorem, ipsum.</p>
                  <ul className="p-0">
                    <li><FaGraduationCap className="icon me-2"/>Lorem, ipsum.</li>
                    <li><FaGraduationCap className="icon me-2"/>Lorem, ipsum.</li>
                    <li><FaGraduationCap className="icon me-2"/>Lorem, ipsum.</li>
                    <li><FaGraduationCap className="icon me-2"/>Lorem, ipsum.</li>
                  </ul>
                </Col>
              </Row> */}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default TeamMember;
