import {Box} from '@mui/material';
import {toast} from "react-toastify";
import {Container} from "react-bootstrap";
import React, {useEffect, useState} from 'react';
import { useParams} from 'react-router-dom';

import useNavigate from "hooks/Navigate";
import "react-toastify/dist/ReactToastify.css";

import apis from '../../services';
import CssBaseline from "@mui/material/CssBaseline";
import AdminSidebar from "../../components/admin/AdminSidebar";
import AdminNewSidebar from 'components/admin/AdminNewSidebar';
import { Header } from 'components';
import { AddBlogForm } from 'components/forms';
import { useSelector } from 'react-redux';



const INITIAL_FORM_STATE = {
    title: "",
    description: "",
    author: "",
    image: "",
    authorImage: "",
}

function AddUpdateBlog() {
    const [blogData, setBlogData] = useState(INITIAL_FORM_STATE)
    const {id: blogId} = useParams();
    const navigate = useNavigate();


    const {adminUser} = useSelector((store) => store.admin);
    const onChangeHandler = (e) => {
        const {name, value, files} = e.target;
        if (files)
            return setBlogData((prevState) => ({...prevState, [name]: files[0]}));
            setBlogData((prevState) => ({...prevState, [name]: value}));
    };

    const fetchBlogDetails = async (blogId) => {
        try {
            const res = await apis.getOneBlogById(blogId)
            if (res.data.status) {
                setBlogData(res.data.blog)
            }
        } catch (e) {
            console.error('ERROR in fetching blog details', e);
        }
    };

    const onCreateBlogHandler = async (data) => {
        try {
            const res = await apis.createBlog(data);
            if (res?.data?.status) {
                toast.success(res.data.message);
                setBlogData(INITIAL_FORM_STATE)
                const redirect = adminUser?.type === "owner" ?  "/admin/blog" : "/sub-admin/blog";
                navigate(redirect);
            }
            else toast.error(res.data?.message);

        } catch (e) {
            console.error('ERROR on Create Blog', e);
        }
    };
    const onUpdateCreateHandler = async (data) => {
        try {
            const res = await apis.updateBlog(data);
            if (res?.data?.status) {
                toast.success(res.data.message);
                setBlogData(INITIAL_FORM_STATE)
                const redirect = adminUser?.type === "owner" ?  "/admin/blog" : "/sub-admin/blog";
                navigate(redirect);
            }else{
                toast.error(res.data.message);
            }
        } catch (e) {
            console.error('ERROR on update Blog', e);
        }
    }

    useEffect(() => {
        (async () => {
            if (blogId)
                await fetchBlogDetails(blogId);

        })()
    }, [blogId]);

    const onSubmitHandler = async (data) => {
        if (blogId) await onUpdateCreateHandler({blogId,blogData})
        else await onCreateBlogHandler(blogData)
    }

    return (
        <Box className="dashboard-main">
            <AdminNewSidebar/>
            <div className="dashboard-section-main">
            
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    height: "100vh",
                    overflow: "auto",
                }}
                className="custom-scroll"
            >
                <Header/>
                <Container>
                <h3 className="section-title dark-text text-start mb-0">{blogId ? 'Update' : 'Add'} Blog</h3>
                    <AddBlogForm
                        formData={blogData}
                        onChangeHandler={onChangeHandler}
                        submitHandler={onSubmitHandler}
                        heading={blogId ? 'Update' : 'Add'}
                    />
                </Container>
            </Box>
            </div>
        </Box>
    );
}

export default AddUpdateBlog;
