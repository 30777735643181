import React, { useRef, useState } from "react";
import Link from "hooks/Link";
import Slider from "react-slick";
import { Logo, WalletCardIcon } from "./Images";
import detectLang from "utils/detectLang";
import { useTranslation } from "react-i18next";

import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import useNavigate from "hooks/Navigate";
import { FaRegCirclePlay } from "react-icons/fa6";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { Pagination } from "swiper/modules";

const InvestCardNew = (props) => {
  const { user } = useSelector((store) => store.user);

  const navigate = useNavigate();
  console.log("propsss", props);
  const {
    title,
    location: { address },
    _id,
    image,
  } = props;

  const [isPlaying1, setIsPlaying1] = useState(false);
  const videoRef1 = useRef(null);

  // Function to handle play/pause toggle for the first video
  const togglePlay1 = () => {
    if (videoRef1.current.paused || videoRef1.current.ended) {
      videoRef1.current.play();
      setIsPlaying1(true);
    } else {
      videoRef1.current.pause();
      setIsPlaying1(false);
    }
  };

  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleDescription = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsExpanded(!isExpanded);
  };

  const truncateDescription = (text, length) => {
    if (text.length <= length) return text;
    return `${text.substring(0, length)}...`;
  };

  const descriptionLength = 100;
  const description = detectLang(props, "description", lang);

  console.log("titletitle", title);
  const handleSubmit = () => {
    // toggleModal(_id);
    if (!user) {
      return toast.info("Register yourself first to invest", { toastId: 123 });
    } else if (!props?.active || props?.totalShares === 0) {
      return toast.info("This store has funded fully", { toastId: 123 });
    } else {
      navigate(`/franchise/${_id}`);
    }
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="InvestCard">
      <div className="wrapp">
        <div className="top">
          {/* <img src={image} /> */}
          <div className="slider" style={{ position: "" }}>
            <Swiper
              pagination={true}
              modules={[Pagination]}
              className="mySwiper"
            >
              {props?.files.map((data, index) => (
                <div>
                  {data?.type === "video" ? (
                    <SwiperSlide>
                      <div className="wrap-video">
                        <video
                          ref={videoRef1}
                          src={data?.file}
                          //  controls
                        />
                        {/* <img
                                src="/assets/images/home-sec-sevenImg.png"
                                alt=""
                                className="playPause1"
                                onClick={() => togglePlay1(index)}
                              /> */}
                        <div
                          className="background"
                          style={{ opacity: isPlaying1 ? 0 : 1 }}
                        >
                          <img
                            onClick={togglePlay1}
                            // src={cmsImage(setting?.what?.image)}
                            src="/assets/images/home-sec-sevenImg.png"
                            alt=""
                          />
                          <img
                            src="/assets/images/videoPlayPause.png"
                            alt=""
                            className="playPause1"
                            onClick={() => togglePlay1(index)}
                          />
                        </div>
                      </div>
                    </SwiperSlide>
                  ) : (
                    <SwiperSlide>
                      <img src={data?.file} alt="" className="type-img" />
                    </SwiperSlide>
                  )}
                </div>
              ))}
            </Swiper>
          </div>
        </div>
        <div className="bottom">
          <div className="box1">
            {/* <img src="/assets/images/avatar.png" alt="avatar" /> */}
            {/* <img src={Logo} alt="logo" className="dashboard-logo" />{" "} */}
            {/* <h3>{detectLang(props, "title", lang)}</h3> */}

            <div
                  className="sub-heading"
                  dangerouslySetInnerHTML={{
                    __html: detectLang(props, "title", lang),
                  }}
                >
  </div>
          </div>
          <p>
            {/* {isExpanded
              ? description
              : truncateDescription(description, descriptionLength)} */}
           
           <div
                  className="sub-heading"
                  dangerouslySetInnerHTML={{
                    __html: isExpanded
                    ? description
                    : truncateDescription(description, descriptionLength),
                  }}
                >
          </div>
            {/* <span
              onClick={toggleDescription}
              style={{ color: "#e0201c", cursor: "pointer" }}
            >
              {isExpanded ? " Show Less" : " Show More"}
            </span> */}
          </p>
          <div className="box2">
            <img src="/assets/images/location.png" alt="location" />
            <p>{address}</p>
          </div>

          {!props?.active || props?.totalShares === 0 ? (
            <button
              onClick={handleSubmit}
              className="d-flex justify-content-center align-items-center mt-3 simple-square-btn d-block "
            >
              {t("buttons.funding_complete")}🎉
            </button>
          ) : (
            <button
              className="simple-square-btn d-block"
              onClick={handleSubmit}
            >
              {t("buttons.invest_now")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default InvestCardNew;
