import {createSlice} from "@reduxjs/toolkit";
import reduxApis from "../apis";

const storeSlice = createSlice({
    name: "store",
    initialState: {
        loading: false,
        store: null,
        storeAll: [],
        error: null,
        totalStore: 0,
    },

    extraReducers: {
        [reduxApis.getStore.pending]: (state, action) => {
            state.loading = true;
        },
        [reduxApis.getStore.fulfilled]: (state, action) => {
            state.loading = false;
            state.store = action.payload;
        },
        [reduxApis.getStore.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        // [getAllStore.pending]: (state, action) => {
        //   state.loading = true;
        // },
        // [getAllStore.fulfilled]: (state, action) => {
        //   state.loading = false;
        //   state.storeAll = action.payload;
        // },
        // [getAllStore.rejected]: (state, action) => {
        //   state.loading = false;
        //   state.error = action.payload;
        // },
    },
});

export default storeSlice.reducer;
