import React, { useEffect, useRef, useState } from "react";
import { Box, Container, CssBaseline, Grid } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AdminNewSidebar from "components/admin/AdminNewSidebar";
import { Header, InvestCardNew } from "components";
import SearchBar from "components/SearchBar";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import apis from "services";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const mdTheme = createTheme();

const Franchise = () => {
  const searchRef = useRef("");
  const [search, setSearch] = useState("");
  const [stores, setStores] = useState([]);
  const [store, setStore] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [investmentModal, setInvestmentModal] = useState(false);
  const [signatureModal, setSignatureModal] = useState(false);
  const [limit, setLimit] = useState(10);

  const [successModal, setSuccessModal] = useState(false);

  const handleLoadMore = () => {
    setLimit((prevLimit) => prevLimit + 10);
  };

  const { user } = useSelector((store) => store.user);

  const { t } = useTranslation();

  const searchParams = new URLSearchParams(useLocation().search);
  let query = Object.fromEntries(searchParams);

  const searchStoreHandler = () => {
    setSearch(searchRef.current.value);
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const investCardsData = [
    {
      id: 1,
      images: Array(6).fill("/assets/images/franchise.png"),
      avatar: "/assets/images/avatar.png",
      title: "Java Times Caffe #1 Saltillo 400",
      description:
        "Java Times Caffe #1 the best cafe to invest in located on a busy road",
      location:
        "Calz Saltillo 400 685, Exhacienda Los Angeles, 27260 Torreon, Coah.",
    },
    {
      id: 2,
      images: Array(6).fill("/assets/images/franchise.png"),
      avatar: "/assets/images/avatar.png",
      title: "Java Times Caffe #1 Saltillo 400",
      description:
        "Java Times Caffe #1 the best cafe to invest in located on a busy road",
      location:
        "Calz Saltillo 400 685, Exhacienda Los Angeles, 27260 Torreon, Coah.",
    },
    {
      id: 3,
      images: Array(6).fill("/assets/images/franchise.png"),
      avatar: "/assets/images/avatar.png",
      title: "Java Times Caffe #1 Saltillo 400",
      description:
        "Java Times Caffe #1 the best cafe to invest in located on a busy road",
      location:
        "Calz Saltillo 400 685, Exhacienda Los Angeles, 27260 Torreon, Coah.",
    },
    {
      id: 4,
      images: Array(6).fill("/assets/images/franchise.png"),
      avatar: "/assets/images/avatar.png",
      title: "Java Times Caffe #1 Saltillo 400",
      description:
        "Java Times Caffe #1 the best cafe to invest in located on a busy road",
      location:
        "Calz Saltillo 400 685, Exhacienda Los Angeles, 27260 Torreon, Coah.",
    },
    {
      id: 5,
      images: Array(6).fill("/assets/images/franchise.png"),
      avatar: "/assets/images/avatar.png",
      title: "Java Times Caffe #1 Saltillo 400",
      description:
        "Java Times Caffe #1 the best cafe to invest in located on a busy road",
      location:
        "Calz Saltillo 400 685, Exhacienda Los Angeles, 27260 Torreon, Coah.",
    },
    {
      id: 6,
      images: Array(6).fill("/assets/images/franchise.png"),
      avatar: "/assets/images/avatar.png",
      title: "Java Times Caffe #1 Saltillo 400",
      description:
        "Java Times Caffe #1 the best cafe to invest in located on a busy road",
      location:
        "Calz Saltillo 400 685, Exhacienda Los Angeles, 27260 Torreon, Coah.",
    },
  ];

  const fetchActiveStore = async () => {
    const response = await apis.getAllActiveStores();
    console.log("get all store data", response);
    if (response.status === 200) {
      setStores(response.data.stores);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await fetchActiveStore();
      } catch (e) {
        console.log("Error fetching Stores", e);
      }
    })();
  }, []);

  const toggleModal = (id) => {
    const selectedStore = stores.find((store) => store._id === id);
    setStore(selectedStore);
    setShowModal((prevState) => !prevState);
  };
  const toggleInvestmentModal = (type = "close") => {
    if (type === "open") {
      setSignatureModal(true);
      setInvestmentModal(false);
    } else if (type === "success") {
      setSuccessModal(true);
      setSignatureModal(false);
      setInvestmentModal(false);
    } else if (user?.isKYCCompleted === "pending") {
      setInvestmentModal((prevState) => !prevState);
      setSignatureModal(false);
    } else if (user?.isKYCCompleted === "progress") {
      setSignatureModal((prevState) => !prevState);
      setInvestmentModal(false);
    }
  };

  const stateFiltration = (store) => {
    if (query.hasOwnProperty("state")) {
      return query["state"].includes(store.location.state);
    } else {
      return store;
    }
  };
  const searchFiltration = (stores) => {
    return Object.values(stores).some((store) => {
      if (typeof store === "string")
        return store.toLocaleLowerCase().includes(search.toLocaleLowerCase());
      else return searchFiltration(store);
    });
  };

  const investCardProps = { toggleModal, toggleInvestmentModal };

  const NoStoreFound = ({ t }) => (
    <Grid item xs justifyContent="center">
      <h3 className="common-heading text-center pt-5">
        {t("messages.no_stores")}
      </h3>
    </Grid>
  );

  let searchFiltrationResult;
  const renderStores = (t) => {
    if (stores.length === 0) return <NoStoreFound t={t} />;

    const stateFiltrationResult = _.filter(stores, stateFiltration);

    if (stateFiltrationResult.length === 0) return <NoStoreFound t={t} />;

    searchFiltrationResult = stateFiltrationResult.filter(searchFiltration);

    if (searchFiltrationResult.length === 0) return <NoStoreFound t={t} />;

    console.log("searchFiltrationResult", searchFiltrationResult);
    return searchFiltrationResult
      .splice(0, limit)
      ?.map((store, index) => (
        <InvestCardNew key={index} {...investCardProps} {...store} />
      ));
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box className="dashboard-main">
        <AdminNewSidebar />
        <div className="dashboard-section-main">
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
              background: "#fff",
            }}
            className="custom-scroll"
          >
            <Header />
            <Container
              maxWidth="lg"
              sx={{ mt: 0, mb: 4 }}
              className="storeSection"
            >
              <div className="content">
                <h1 className="heading">{t("titles.franchise")}</h1>
                <p
                  className="sub-heading"
                  dangerouslySetInnerHTML={{
                    __html: t("titles.franchiseDesc"),
                  }}
                >
                  {/* Browse all current stores to find the store you'd like to
                  invest in
                  <br />
                  All companies are <span> checked and pass due diligence</span> */}
                </p>
              </div>
              <div className="search">
                <SearchBar
                  searchRef={searchRef}
                  handleSearch={searchStoreHandler}
                />
              </div>
              <div className="InvestCardCon">{renderStores(t)}</div>
              {searchFiltrationResult?.length > 0 && (
                <div className="btn-wrapper-viewMore">
                  <button onClick={handleLoadMore}>View More</button>
                </div>
              )}
            </Container>
          </Box>
        </div>
      </Box>
    </ThemeProvider>
  );
};

export default Franchise;
