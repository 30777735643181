import {configureStore} from '@reduxjs/toolkit';
import userReducer from './slices/userSlice';
import walletReducer from './slices/walletSlicer';
import investReducer from './slices/investSlice';
import storeReducer from './slices/storeSlice';
import adminReducer from './slices/adminSlice'
import languageReducer from './slices/languageSlice'

import storage from 'redux-persist/lib/storage'
import {combineReducers} from "redux";
import {persistReducer} from 'redux-persist'


const reducers = combineReducers({
    user: userReducer,
    wallet: walletReducer,
    invest: investReducer,
    store: storeReducer,
    admin: adminReducer,
    language: languageReducer,
})
const persistConfig = {
    key: 'user',
    storage
};
const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    }),
});
