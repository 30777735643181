import React from 'react';
import { Helmet } from 'react-helmet';
import detectLang from "../utils/detectLang";
 const CustomHelmet = ({seo,lang}) => (
     <div>
        <Helmet>
            <title>{detectLang(seo,'title',lang)}</title>
            <meta name="description" content={detectLang(seo,'description',lang)} />
            <meta name="keywords" content={detectLang(seo,'keywords',lang)} />
            <meta name="author" content={detectLang(seo,'author',lang)} />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </Helmet>
     </div>

    );

export default CustomHelmet;