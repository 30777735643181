import { BlogCardMain, Footer, MainHeader } from "components";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams, useSearchParams } from "react-router-dom";
import apis from "services";

const BlogPage = () => {
  const { title } = useParams();
  let [oneBlog, setOneBlog] = useState([]);

  let [allBlog, setAllBlog] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  const fetchBlog = async () => {
    const res = await apis.getOneBlog(title);
    setOneBlog(res?.data?.blog);
  };

  const fetchAlBlogs = async () => {
    const res = await apis.getAllBlogs();
    setAllBlog(res?.data?.blog);
  };

  let recentBlog = allBlog.filter((item) => {
    return item?.slug !== title;
  });


  useEffect(() => {
    (async () => {
      await fetchBlog();
      await fetchAlBlogs();
    })();
  }, [title]);

  return (
    <>
    <div onClick={()=>{ if(showDropdown) setShowDropdown(!showDropdown)}}>
      <div className="header-section" >
        <MainHeader showDropdown={showDropdown} setShowDropdown={setShowDropdown}  />
      </div>
      <Container className="mt-5">
        <article className="blog-page py-5">
          <p className="blog-title">{oneBlog?.title}</p>
          {/* <p className="blog-desc">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem cumque
            odio nemo fuga minus accusantium, libero consequatur animi deleniti
            amet adipisci magni iste facere necessitatibus molestias ducimus
            ipsa reprehenderit eius.
          </p> */}
          <div className="blog-main-img">
            <img src={oneBlog?.image} alt="" />
          </div>
          <Row className="blog-main py-5">
            <Col md={8}>
              <dl className="line-blog">
                <dl>
                  {oneBlog?.description
                    ?.split("\\n")
                    .map((text, i) => (i ? [<br />, text] : text))}
                </dl>
                {/*<dl>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Fuga, consectetur?
                </dl>
                <dl>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Sequi tempora at ea eos praesentium.
                </dl>
                <dl>
                  Reiciendis neque, deleniti cumque soluta eveniet voluptas
                  laboriosam unde atque temporibus voluptate recusandae optio!
                </dl>
                <dl>
                  Labore in cumque ad maiores nihil quos commodi delectus
                  deleniti asperiores, blanditiis dolorem deserunt.
                </dl>
                <dl>
                  Molestias nostrum odit expedita quisquam ipsa est aut,
                  corrupti autem dolorum tempore iste neque.
                </dl>
                <dl>
                  Culpa ipsa vitae adipisci voluptates sapiente? Corporis velit
                  praesentium quae aspernatur blanditiis ipsum magni?
                </dl>
                <ul>
                  <li>
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  </li>
                  <li>
                    Deleniti iusto iure nesciunt tenetur reiciendis sunt
                    explicabo!
                  </li>
                  <li>
                    Ad eveniet molestiae rem dolorem consectetur sequi
                    accusantium.
                  </li>
                </ul>
                <div className="section-img">
                  <img src={imageUrl("prb.jpg")} alt="" />
                </div>
                <dl>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Fuga, consectetur?
                </dl>
                <dl>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Sequi tempora at ea eos praesentium.
                </dl>
                <dl>
                  Reiciendis neque, deleniti cumque soluta eveniet voluptas
                  laboriosam unde atque temporibus voluptate recusandae optio!
                </dl>
                <dl>
                  Labore in cumque ad maiores nihil quos commodi delectus
                  deleniti asperiores, blanditiis dolorem deserunt.
                </dl>
                <dl>
                  Molestias nostrum odit expedita quisquam ipsa est aut,
                  corrupti autem dolorum tempore iste neque.
                </dl>
                <dt>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                </dt>
                <dl>
                  Culpa ipsa vitae adipisci voluptates sapiente? Corporis velit
                  praesentium quae aspernatur blanditiis ipsum magni?
                </dl> */}
              </dl>
            </Col>
            <Col md={4}>
              <dt>RECENT ARTICLES</dt>
              {recentBlog?.slice(0, 3).map((blog) => {
                return (
                  <>
                    <BlogCardMain
                      id={blog._id}
                      slug={blog.slug}
                      title={blog.title}
                      desc={blog.description}
                      time={blog.createdAt}
                      author={blog.author}
                      img={blog.image}
                      authorImg={blog.authorImage}
                    />
                  </>
                );
              })}
            </Col>
          </Row>
        </article>
      </Container>
      <Footer />
      </div>
    </>
  );
};

export default BlogPage;
