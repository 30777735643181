import React, {useEffect, useState} from "react";
import {Box, Container, Grid} from "@mui/material";
import apis from "services";
import {DataGrid} from "@mui/x-data-grid";
import BlogsTable from "components/datagrid/BlogsTable";
import {toast} from "react-toastify";
import Link from 'hooks/Link';
import Toolbar from "../../components/@material-extend/CustomToolbar";
import ImagePreview from "../../components/modals/ImagePreview";
import AdminNewSidebar from "components/admin/AdminNewSidebar";
import {Header} from "components";
import { useTranslation } from "react-i18next";
import useNavigate from "hooks/Navigate";
import { useSelector } from "react-redux";
const Blogs = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const {adminUser} = useSelector((store) => store.admin);
    const [image, setImage] = useState("");
    const [blog, setBlogs] = useState([]);
    const [imageModal, setImageModal] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);

    const closeImageModal = (image) => {
        if (image) setImage(image);
        setImageModal((prevState) => !prevState);
    };

    const fetchAllBlogs = async () => {
        const res = await apis.getAllActiveBlog();
        setBlogs(res?.data?.blog);
    };

    const handleDisableBlog = async (id) => {
        const res = await apis.disableBlog(id);
        if (res.data.status) {
            toast.success(res.data.message);
            await fetchAllBlogs();
        }
    };
    const handleEnableBlog = async (id) => {
        const res = await apis.enableBlog(id);
        if (res.data.status) {
            toast.success(res.data.message);
            await fetchAllBlogs();
        }
    };
    const handleDeleteBlog = async (id) => {
        const formattedId = typeof id === "string" ? [id] : id;
        const res = await apis.deleteBlogs(formattedId);
        if (res?.data?.status) {
            toast.success(res.data?.message);
            setSelectionModel([]);
            await fetchAllBlogs();
        } else toast.error(res.data?.message);
    };

    useEffect(() => {
        (async () => {
            await fetchAllBlogs();
        })();
    }, []);

    const methods = {
        handleEnableBlog,
        handleDisableBlog,
        navigate,
        handleDeleteBlog,
    };
    const {rows, columns} = BlogsTable(blog, methods, closeImageModal);

    const DeleteBlogButton = ({rows}) => {
        const ids = rows.length === 0 ? [] : [...rows.map((record) => record._id)];

        return (
            // <button className='btn danger-btn' onClick={() => handleDeleteStore(ids)}>Delete Stores</button>
            <></>
        );
    };

    const AddBlogButton = () => {
        return <></>;
    };

    const NewToolbar = () => (
        <Toolbar buttons={[<AddBlogButton/>, <DeleteBlogButton/>]}/>
    );

    return (
        <>
            <Box className="dashboard-main">
                <AdminNewSidebar/>
                <div className="dashboard-section-main">
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            height: "100vh",
                            overflow: "auto",
                        }}
                        className="custom-scroll"
                    >
                        <Header/>
                        <Container maxWidth="lg" sx={{mt: 0, mb: 4}}>
                            <div className="flex-with-title">
                                <h3 className="dashboard-title">
                                    {t("titles.blog")}
                                </h3>
                                
                                <Link to={adminUser?.type === "owner" ?  "/admin/blog/add" : "/sub-admin/blog/add"} className="lp-btn">
                                    {t("buttons.add_blog")}
                                </Link>
                            </div>
                            <Grid
                                item
                                container
                                justifyContent="flex-end"
                                className="mt-4"
                                sx={{height: "600px"}}
                            >
                                <DataGrid
                                    checkboxSelection
                                    rows={rows}
                                    columns={columns}
                                    onSelectionModelChange={(newSelectionModel) =>
                                        setSelectionModel(newSelectionModel)
                                    }
                                    selectionModel={selectionModel}
                                    components={{Toolbar: NewToolbar}}
                                    getRowClassName={(params) =>
                                        params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                                    }
                                />
                            </Grid>
                        </Container>
                    </Box>
                </div>
            </Box>
            <ImagePreview status={imageModal} close={closeImageModal} image={image}/>
        </>
    );
};

export default Blogs;
