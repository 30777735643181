import { useTranslation } from "react-i18next";
/**
 * @param users
 * @param methods
 * @return {{columns: [{headerName: string, field: string, width: number},{headerName: string, field: string, width: number},{headerName: string, field: string, width: number},{headerName: string, field: string, width: number},{headerName: string, field: string, width: number},null], rows: *[]}}
 */
const AdminUsersTable = (users = []) => {
  const { t } = useTranslation();

  const columns = [
    { field: "id",
     headerName: t("fields.id"),    
     width: 80 },
    {
      field: "fullName",
      headerName: t("fields.fullName"),
      minWidth: 150,
      flex: 1,
    },
    {
      field: "WalletId",
      headerName: t("fields.WalletID"),
      minWidth: 260,
      flex: 1,
    },

    { field: "email", headerName: t("fields.email"), minWidth: 150, flex: 1 },
    {
      field: "verified",
      headerName: t("fields.verified"),
      width: 100,
    },
    {
      field: "createdAt",
      headerName: t("fields.createdAt"),
      minWidth: 150,
      flex: 1,
    }
  ];

  const rows =
    users.length === 0
      ? []
      : [
          ...users.map((user, id) => ({
            id: id + 1,
            fullName: user.name,
            WalletId: user.wallet,
            email: user.email,
            verified: user.isVerified,
            createdAt: user.createdAt,
            _id: user._id,
          })),
        ];

  return { rows, columns };
};

export default AdminUsersTable;
