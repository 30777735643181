import AdminNewSidebar from "components/admin/AdminNewSidebar";
import React, { useState, useEffect } from "react";
import { Box, Container } from "@mui/material";
import { Header } from "components";
import { useTranslation } from "react-i18next";
import { Table, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import apis from "../../services";
import { setAdmin } from "redux/slices/adminSlice";
import { useDispatch } from "react-redux";

export default function DonationTokenTransfer() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading,setLoading]=useState(false);
  const [data, setData] = useState({
    walletId: "",
    tokens: "",
    memo: "",
    // type: "reserve",
    // id: "",
    //totalShares:''
  });

  const [stores, setStores] = useState([]);

  const onChangeHandler = (e) => {
    const { id, value } = e.currentTarget;
    setData((prevState) => ({ ...prevState, [id]: value }));
  };
  const submitHandler = async (event) => {
    event.preventDefault();
    if (data?.walletId) {
      setLoading(true)
      try {
        console.log("apishit")
        const response = await apis.transferTokens(data);
        if (response?.status) {
          toast.success(response?.data?.message);
          // await fetchAllStores();
          // dispatch(setAdmin(response?.data?.admin))
          setData({
            walletId: "",
            tokens: "",
            memo: ""
            // type: "reserve",
          })
        }

        setLoading(false)
      } catch (e) {
        setLoading(false)
        toast.error(e.response?.data?.message);
        console.log(`Error in Token Transfer`, e);
      }
    } else {
      setLoading(false)
      toast.error("Please select the store");
    }
  };

  // const fetchAllStores = async () => {
  //   const res = await apis.getAllActiveStores();
  //   setStores(res?.data?.stores);
  // };

  // useEffect(() => {
  //   (async () => {
  //     await fetchAllStores();
  //   })();
  // }, []);
  return (
    <>
      <Box className="dashboard-main">
        <AdminNewSidebar />
        <div className="dashboard-section-main">
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
            className="custom-scroll"
          >
            <Header />
            <Container maxWidth="sm" sx={{ mt: 0, mb: 4 }}>
              <div className="bg-light p-5">
                <h3 className="">JTC Token Transfer </h3>
                <Form onSubmit={submitHandler}>
                  {/* <Form.Group className="mb-3" controlId="state">
                    <Form.Label>Wallet Type</Form.Label>
                    <Form.Select id='type' onChange={onChangeHandler} required value={data.type}>
                      <option disabled>Select Wallet Type</option>
                      <option value={"reserve"}>Reserve Wallet</option>
                      <option value={"normal"}>Normal Wallet</option>
                    </Form.Select>
                  </Form.Group> */}
                  <Form.Group className="mb-3" controlId="formGroupEmail">
                    <Form.Label>Investor Wallet ID</Form.Label>
                    <Form.Control
                      type="string"
                      id="walletId"
                      value={data.walletId}
                      placeholder="Enter Investor Wallet ID"
                      onChange={onChangeHandler}
                      maxLength={24}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formGroupEmail">
                    <Form.Label>How much share you want to give?</Form.Label>
                    <Form.Control
                      type="number"
                      id="tokens"
                      value={data.tokens}
                      placeholder="Enter token"
                      onChange={onChangeHandler}
                      required
                    />
                  </Form.Group>

                  {/* <Table responsive bordered hover size="sm">
                    <thead>
                      <tr>
                        <th>Image</th>
                        <th>Name</th>
                        <th>Shares</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {stores.length > 0 ? (
                        stores.filter((item) => item.active === true).map((item, i) => {
                          return (
                            <>
                              <tr key={item.id} style={{ textAlign: 'center' }}>
                                <td> <img src={item?.image} width={"41px"} height={"41px"} alt="image" /></td>
                                <td>{item?.title}</td>
                                <td>{item?.totalShares}</td>
                                <td><input style={{ marginTop: '12px' }} type="checkbox" onClick={() => setData((prevState) => ({ ...prevState, id: item?._id, totalShares: item?.totalShares }))} checked={item?._id === data?.id} ></input></td>
                              </tr>
                            </>
                          )
                        })
                      ) : (
                        <tr style={{ textAlign: 'center' }} >
                          <td colSpan={7}>No Stores</td>
                        </tr>
                      )}

                    </tbody>
                  </Table> */}

                  <Form.Group className="mb-3" controlId="memo">
                    <Form.Label>Memo (Optional)</Form.Label>
                    <Form.Control as="textarea" id='memo' placeholder='Enter Memo'
                      onChange={onChangeHandler} value={data.memo} />
                  </Form.Group>

                  <div className="">
                    <Button
                    disabled={loading}
                      className="w-50"
                      variant="outline-danger"
                      type="submit"
                    >
                      {loading ? "Loading" : "Send"}
                      
                    </Button>
                  </div>
                </Form>
              </div>
            </Container>
          </Box>
        </div>
      </Box>
    </>
  );
}
