import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Container, Grid, Paper } from "@mui/material";
import AdminNewSidebar from "components/admin/AdminNewSidebar";
import { Header, PageLoader } from "components";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Button } from "react-bootstrap";
import useNavigate from "hooks/Navigate";
import { SUB_ADMIN_TYPES } from "assets/constants";
import DocumentCard from "components/DocumentCard";
import { useRef } from "react";
import apis from "services";


import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import DocumentVerificationCard from "components/DocumentVerficationCard";

const mdTheme = createTheme();

const AdminDocuments = () => {

  const [selectedStatusFilter, setSelectedStatusFilter] = useState("pending");
  const [matchingRecordsExist, setMatchingRecordsExist] = useState(true);

  const handleStatusFilter = (status) => {
    setSelectedStatusFilter(status);
  };


  let { id } = useParams();

  const { adminUser } = useSelector((store) => store.admin);

  const [analytics, setAnalytics] = useState('');

  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);
  const getUserDocumentData = async (id) => {
    try {
      setLoading(true)
      const response = await apis.getUserDocuments(id)
      if (response?.data?.status) {
        console.log(response, "response")
        setData(response?.data?.documents)
        setAnalytics(response?.data?.analytics)
      }
      setLoading(false)
    }
    catch (e) {
      setLoading(false)
      console.log(e?.message)
    }
  }
useEffect(()=>{
  getUserDocumentData(id)
  handleStatusFilter('pending')
},[id])

  // useEffect(() => {
  //   if (id) {
  //     getUserDocumentData(id);
  //     const matchingDocuments = data?.filter((item) => item.status === selectedStatusFilter);

  //     setMatchingRecordsExist(matchingDocuments.length > 0);
  //   }
  // }, [id, selectedStatusFilter]);


  const { t } = useTranslation();

  return (
    <ThemeProvider theme={mdTheme}>
      <Box className="dashboard-main">
        <AdminNewSidebar />
        <div className="dashboard-section-main">
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
            className="custom-scroll"
          >
            <div>
              <Header />

              <div maxWidth="lg" sx={{ mt: 0, mb: 4 }} className="container-fluid">
                <div className="doc-with-title">
                  <h3 className="doc-title">{t("titles.document")}</h3>
                </div>
                <div className="other-details mt-4">
                  {/* <h4>{t("titles.document_sub_title")}</h4>
                  <p>{t("descs.document_descs")}</p> */}
                  <div className="d-flex justify-content-center align-items-center" style={{gap:'20px'}}>
                    <Button
                      variant={selectedStatusFilter === "pending" ? "primary" : "outline-primary"}
                      onClick={() => handleStatusFilter("pending")}
                    >
                      Pending ({analytics?.pending || 0})
                    </Button>
                    <Button
                      variant={selectedStatusFilter === "approved" ? "success" : "outline-success"}
                      onClick={() => handleStatusFilter("approved")}
                    >
                      Approved ({analytics?.approved || 0})
                    </Button>
                  
                    <Button
                      variant={selectedStatusFilter === "rejected" ? "danger" : "outline-danger"}
                      onClick={() => handleStatusFilter("rejected")}
                    >
                      Rejected ({analytics?.rejected || 0})
                    </Button>
                  </div>
                </div>
                <div className="d-flex justify-content-center align-items-center m-5">
                 
                {loading ? (
                  <div className="d-flex justify-content-center align-items-center m-5">
                    <CircularProgress color="error" />
                  </div>
                ) : matchingRecordsExist ? (
                  <div className="d-flex flex-wrap">
                    {data?.map((item, index) => {
                      if (item.status === selectedStatusFilter) {
                        return (
                          <div className={`m-4`} key={index}>
                            <DocumentVerificationCard
                              value={item.type}  // Use the actual document type from your data
                              data={item}
                              type={item.type}   // Use the actual document type from your data
                              getUserDocumentData={getUserDocumentData}
                              id={id}
                           />
                          </div>
                        );
                      }
                      return null
                    })}
                  </div>
                ) : (
                  
                  <div className="d-flex justify-content-center align-items-center mt-5">
                  <p>No records exist</p>
                  
                  </div>
                )}
                </div>
              </div>
            </div>
          </Box>
        </div>
      </Box>
    </ThemeProvider>
  );
};

export default AdminDocuments;
