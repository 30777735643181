/**
 @param stores
 @param methods
 */
import {Grid, IconButton, Tooltip, Typography} from "@mui/material";
import {
    DeleteOutline,
    ImageOutlined,
    RemoveModeratorOutlined,
    VerifiedUserOutlined,
    VisibilityOutlined
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { removeHTMLTags } from "utils/removeHTMLTags";

const StoresTable = (stores = [], methods = {}, openImagePreview) => {
    const {handleEnableStore, handleDisableStore, openModal} = methods;
    const { language, setLanguage, t } = useTranslation();
const navigate=useNavigate();

    const columns = [
        {field: 'id', headerName: "#", width: 50, hide: false},
        {field: 'title', headerName: t("fields.title"), width: 220},
        {field: 'description', headerName: t("fields.description"), width: 150},
        {field: 'availableShares', headerName: t("fields.shares"), width: 150},
        {field: 'state', headerName: t("fields.state"), width: 150},
        {field: 'city', headerName: t("fields.city"), width: 150},
        {
            field: 'address', headerName: t("fields.address"), width: 150,
            renderCell: (param) => <Tooltip title={param.value}><Typography noWrap>{param.value}</Typography></Tooltip>
        }
        ,
        // {field: 'image', headerName: t("fields.image"), width: 100, renderCell: (param) => <IconButton onClick={() => openImagePreview(param.value)}><ImageOutlined/></IconButton>},
        {field: 'fundedActive', headerName: t("fields.active"), width: 100},
        {
            field: 'actions', headerName: t("fields.actions"), width: 150, renderCell: ({row}) => {
                const isActive = row.fundedActive == 'Yes';
                const id = row._id;

                return (
                    <Grid container>
                        <Grid>
                            <Tooltip title='View & Update' arrow>
                                <IconButton size='small' onClick={() => navigate(`add/${id}`)}>
                                    <VisibilityOutlined/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid>
                        {isActive ? (
                <Tooltip title="Store Funded Already Active" arrow>
                  {/* <IconButton size="small" onClick={() => toast.info("Store funded already active")}> */}
                  <IconButton size="small" onClick={() => handleEnableStore(id)}>
               
                     <VerifiedUserOutlined className="success" />
                 </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Store Funded Active" arrow>
                  <IconButton size="small" onClick={() => handleDisableStore(id)}>
                  <RemoveModeratorOutlined className="failure" />   
                  </IconButton>
                </Tooltip>
              )}

                           
                        </Grid>
                        <Grid>
                            <Tooltip title='Delete Store' arrow>
                                <IconButton size='small' onClick={() => openModal(id)}>
                                    <DeleteOutline/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                )
            }
        },
    ];


    const rows = stores.length === 0
        ? []
        : [
            ...stores.map((store, index) => ({
                id: index +1,
                title: removeHTMLTags(store.title_en),
                description: removeHTMLTags(store.description_en),
                availableShares: store.totalShares,
                state: store.location.state,
                city: store.location.city,
                address: store.location.address,
                fundedActive: store.active ? 'No' : 'Yes',
                // image: store.image,
                _id: store._id
            }))
        ];


    return {columns, rows}
}


export default StoresTable
