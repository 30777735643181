import PrimarySelector from "components/common/PrimarySelector";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AiFillCaretDown, AiOutlineSearch } from "react-icons/ai";
import {
  BsChevronLeft,
  BsChevronRight,
  BsLayoutThreeColumns,
} from "react-icons/bs";
import {
  FaChevronLeft,
  FaChevronRight,
  FaRegCalendarAlt,
} from "react-icons/fa";
import basisTable from "./../../basisTable.json";
import { ColumnSelector } from "components";
import { initial } from "lodash";
import * as XLSX from "xlsx";
// const workbook = XLSX.readFile('src/UpdateExpense.xlsx')
// const worksheet = workbook.Sheets["Sheet1"]

// const basisTable = XLSX.utils.sheet_to_json(worksheet)

const columns2 = [
  "Operating Expenses",
  "Alarms & Monitoring",
  "Fumigation",
  "Garbage Collection",
  "Operating Expenses",
  "Selling Expenses",
  "Labor Service",
];

const initialColumns = Object.keys(basisTable[0]);
const initialSelectedColumns = initialColumns.slice(0, 5);

const PitchBasis = () => {
  const [columns, setColumns] = useState();
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [filteredData, setFilteredData] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredData?.slice(indexOfFirstRow, indexOfLastRow);

  const totalPages = Math.ceil(filteredData?.length / rowsPerPage);

  const handlePrevClick = () => {
    setCurrentPage(Math.max(1, currentPage - 1));
  };

  const handleNextClick = () => {
    setCurrentPage(Math.min(totalPages, currentPage + 1));
  };

  useEffect(() => {
    setColumns(Object.keys(basisTable[0]));
    setSelectedColumns(initialSelectedColumns);

    const filteredData = basisTable.map((item) => {
      const newItem = {};
      initialSelectedColumns.forEach((column) => {
        newItem[column] = item[column];
      });
      return newItem;
    });

    setFilteredData(filteredData);
  }, []);

  useEffect(() => {
    const filteredData = basisTable.map((item) => {
      const newItem = {};
      selectedColumns.forEach((column) => {
        newItem[column] = item[column];
      });
      return newItem;
    });

    setFilteredData(filteredData);
  }, [selectedColumns]);

  const handleDownload = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Selected Fields");
    XLSX.writeFile(workbook, "operating_expense.xlsx");
  };

  const handleRowsPerPageChange = (eventKey) => {
    const parsedRowsPerPage = parseInt(eventKey, 10);
    setRowsPerPage(parsedRowsPerPage);
    setCurrentPage(1);
  };

  return (
    <>
      <section className="py-5">
        <Container>
          <p className="section-subtitle  text-start mb-2 px-3 pb-3">Basis</p>
          <Row>
            <Col md={6}>
              <div className="custom-th dark-cell justify-content-center">
                <span className="text-icon">
                  <FaRegCalendarAlt />
                </span>{" "}
                March - 2024
              </div>
              <Row className="custom-row">
                <Col md={6}>
                  <div className="light-cell-sm">Avg. Ticket Price</div>
                </Col>
                <Col md={6}>
                  <div className="light-cell-sm text-end">$ 152.00</div>
                </Col>
              </Row>
              <Row className="custom-row">
                <div className="row-badge-wrapper">
                  <div className="row-badge">26%</div>
                </div>
                <Col md={6}>
                  <div className="dark-cell-sm">
                    <span className="badge-cell">Cost of Sales</span>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="dark-cell-sm text-end"> $39.52</div>
                </Col>
              </Row>
              <Row className="custom-row">
                <Col md={6}>
                  <div className="light-cell-sm">GP Margin</div>
                </Col>
                <Col md={6}>
                  <div className="light-cell-sm text-end"> $112.48</div>
                </Col>
              </Row>
              <Row className="custom-row">
                <Col md={6}>
                  <div className="light-cell-sm">Investment / Store</div>
                </Col>
                <Col md={6}>
                  <div className="light-cell-sm text-end"> $4,000,000</div>
                </Col>
              </Row>
              <Row className="custom-row">
                <Col md={6}>
                  <div className="light-cell-sm">
                    Avg. Monthly Orders / Shop
                  </div>
                </Col>
                <Col md={6}>
                  <div className="light-cell-sm text-end">$ 480,000</div>
                </Col>
              </Row>
              <Row className="custom-row">
                <Col md={6}>
                  <div className="light-cell-sm">Operational Coffee Shops</div>
                </Col>
                <Col md={6}>
                  <div className="light-cell-sm text-end"> 300</div>
                </Col>
              </Row>
              <Row className="custom-row">
                <Col md={6}>
                  <div className="light-cell-sm">Total Investment</div>
                </Col>
                <Col md={6}>
                  <div className="light-cell-sm text-end"> $1,200,000,000</div>
                </Col>
              </Row>
              <Row className="custom-row">
                <Col md={6}>
                  <div className="light-cell-sm">Monthly Sales Revenues</div>
                </Col>
                <Col md={6}>
                  <div className="light-cell-sm text-end"> 1,824,000,000</div>
                </Col>
              </Row>
              <Row className="custom-row">
                <Col md={6}>
                  <div className="light-cell-sm">Cost of Sales</div>
                </Col>
                <Col md={6}>
                  <div className="light-cell-sm text-end"> 474,240,000</div>
                </Col>
              </Row>
              <Row className="custom-row">
                <Col md={6}>
                  <div className="light-cell-sm">Gross Profit</div>
                </Col>
                <Col md={6}>
                  <div className="light-cell-sm text-end"> $1,349,760,000</div>
                </Col>
              </Row>
              <Row className="custom-row">
                <Col md={6}>
                  <div className="light-cell-sm">Income After Tax</div>
                </Col>
                <Col md={6}>
                  <div className="light-cell-sm text-end"> 774,417,500</div>
                </Col>
              </Row>
            </Col>
            <Col md={6} className="shrink-table">
              <div className="primary-cell custom-th w-50">Tokens Summary:</div>
              <Row className="custom-row">
                <Col md={7}>
                  <div className="light-cell-sm">Project Total</div>
                </Col>
                <Col md={5}>
                  <div className="light-cell-sm text-end"> 1,200,000,000</div>
                </Col>
              </Row>
              <Row className="custom-row">
                <Col md={7}>
                  <div className="light-cell-sm">Value / Token</div>
                </Col>
                <Col md={5}>
                  <div className="light-cell-sm text-end">$ 1.00000</div>
                </Col>
              </Row>
              <Row className="custom-row">
                <Col md={7}></Col>
                <Col md={5}>
                  <div className="primary-cell-sm text-end"> 1,200,000,000</div>
                </Col>
              </Row>
              <Row className="custom-row">
                <div className="row-badge-wrapper">
                  <div className="row-badge">70%</div>
                </div>
                <Col md={7}>
                  <div className="dark-cell-sm">
                    <span className="badge-cell">Saleable Tokens</span>
                  </div>
                </Col>
                <Col md={5}>
                  <div className="dark-cell-sm text-end"> 840,000,000</div>
                </Col>
              </Row>
              <Row className="custom-row">
                <div className="row-badge-wrapper">
                  <div className="row-badge">25%</div>
                </div>
                <Col md={7}>
                  <div className="dark-cell-sm">
                    <span className="badge-cell">Founder's Tokens</span>
                  </div>
                </Col>
                <Col md={5}>
                  <div className="dark-cell-sm text-end"> 300,000,000</div>
                </Col>
              </Row>
              <Row className="custom-row">
                <div className="row-badge-wrapper">
                  <div className="row-badge">5%</div>
                </div>
                <Col md={7}>
                  <div className="dark-cell-sm">
                    <span className="badge-cell">Employee's Tokens</span>
                  </div>
                </Col>
                <Col md={5}>
                  <div className="dark-cell-sm text-end"> 60,000,000</div>
                </Col>
              </Row>
              <Row className="custom-row">
                <Col md={7}>
                  <div className="light-cell-sm">Investment / Store</div>
                </Col>
                <Col md={5}>
                  <div className="light-cell-sm text-end">$ 4,000,000</div>
                </Col>
              </Row>
              <Row className="custom-row">
                <Col md={7}>
                  <div className="light-cell-sm">Open Stores & Warehouses</div>
                </Col>
                <Col md={5}>
                  <div className="light-cell-sm text-end">54%</div>
                </Col>
              </Row>
              <Row className="custom-row">
                <Col md={7}>
                  <div className="light-cell-sm">Raw Materials</div>
                </Col>
                <Col md={5}>
                  <div className="light-cell-sm text-end">25%</div>
                </Col>
              </Row>
              <Row className="custom-row">
                <Col md={7}>
                  <div className="light-cell-sm">Other Setup Costs</div>
                </Col>
                <Col md={5}>
                  <div className="light-cell-sm text-end">21%</div>
                </Col>
              </Row>
              <Row className="custom-row">
                <Col md={7}>
                  <div className="light-cell-sm">Open Stores & Warehouses</div>
                </Col>
                <Col md={5}>
                  <div className="light-cell-sm text-end"> $648,000,000</div>
                </Col>
              </Row>
              <Row className="custom-row">
                <Col md={7}>
                  <div className="light-cell-sm">Raw Materials</div>
                </Col>
                <Col md={5}>
                  <div className="light-cell-sm text-end"> $300,000,000</div>
                </Col>
              </Row>
              <Row className="custom-row">
                <Col md={7}>
                  <div className="light-cell-sm">Other Setup Costs</div>
                </Col>
                <Col md={5}>
                  <div className="light-cell-sm text-end"> $252,000,000</div>
                </Col>
              </Row>
              <Row className="custom-row">
                <Col md={7}>
                  <div className="light-cell-sm">Total Investment</div>
                </Col>
                <Col md={5}>
                  <div className="light-cell-sm text-end"> 1,200,000,000</div>
                </Col>
              </Row>
              <Row className="custom-row">
                <Col md={7}>
                  <div className="light-cell-sm">Open Stores & Warehouses</div>
                </Col>
                <Col md={5}>
                  <div className="light-cell-sm text-end"> $2,160,000</div>
                </Col>
              </Row>
              <Row className="custom-row">
                <Col md={7}>
                  <div className="light-cell-sm">Raw Materials</div>
                </Col>
                <Col md={5}>
                  <div className="light-cell-sm text-end"> $1,000,000</div>
                </Col>
              </Row>
              <Row className="custom-row">
                <Col md={7}>
                  <div className="light-cell-sm">Other Setup Costs</div>
                </Col>
                <Col md={5}>
                  <div className="light-cell-sm text-end"> $840,000</div>
                </Col>
              </Row>
              <Row className="custom-row">
                <Col md={7}>
                  <div className="primary-cell-sm">Per Store Investment</div>
                </Col>
                <Col md={5}>
                  <div className="primary-cell-sm text-end"> $4,000,000</div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="pb-5">
        <Container>
          <div className="custom-th flex-wrap-items dark-cell justify-content-center">
            Operating Expense
          </div>
          <div className="flex-wrap-items flex-wrap py-3">
            <div className="me-auto">
              <PrimarySelector
                grayScale
                iconRight
                textOnly
                items={[10, 20, 30, 40, 50]}
                icon={<AiFillCaretDown />}
                handleChange={handleRowsPerPageChange}
                value={rowsPerPage}
              />
            </div>
            <div className=" flex-wrap-items flex-wrap mb-2">
              <ColumnSelector
                columns={columns ? columns : columns2}
                selectedColumns={selectedColumns}
                setSelectedColumns={setSelectedColumns}
              />
              <button className="simple-btn my-2 flex-wrap" onClick={handleDownload}>
                <img src="/images/excel.svg" alt="" />
              </button>
              <button className="simple-btn my-2 flex-wrap">
                <img src="/images/printer.svg" alt="" />
              </button>
              <div className="flex-input flex-wrap mb-2 lighter-bg">
                <AiOutlineSearch />
                <input
                  type="search"
                  className="simple-input"
                  placeholder="search"
                />
              </div>
            </div>
          </div>
          <Row className="bg-white p-2 rounded-3 m-1">
            <Col md={2} className="shrink-table p-4">
              <table className="custom-table plain-table square-table center-cells">
                <thead>
                  <tr>
                    <th>Months</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="light-cell-sm">Mar, 2023</td>
                  </tr>
                  <tr>
                    <td className="light-cell-sm">Apr, 2023</td>
                  </tr>
                  <tr>
                    <td className="light-cell-sm">May, 2023</td>
                  </tr>
                  <tr>
                    <td className="light-cell-sm">Jun, 2023</td>
                  </tr>
                  <tr>
                    <td className="light-cell-sm">Jul, 2023</td>
                  </tr>
                  <tr>
                    <td className="light-cell-sm">Aug, 2023</td>
                  </tr>
                  <tr>
                    <td className="light-cell-sm">Sep, 2023</td>
                  </tr>
                  <tr>
                    <td className="light-cell-sm">Oct, 2023</td>
                  </tr>
                  <tr>
                    <td className="light-cell-sm">Nov, 2023</td>
                  </tr>
                  <tr>
                    <td className="light-cell-sm">Dec, 2023</td>
                  </tr>
                 
                </tbody>
              </table>
            </Col>
            <Col md={10}>
              <div className="scroll-x">
                <table className="custom-table square-table plain-table">
                  <thead>
                    <tr>
                      {selectedColumns.map((column) => {
                        return (
                          <>
                            <th>{column}</th>
                          </>
                        );
                      })}
                      {/* <th>New Shops</th>
                    <th>Operating Shops</th>
                    <th>Monthly Sales Revenues</th>
                    <th>Cost of Sales</th>
                    <th>Gross Profit</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {currentRows?.map((row) => {
                      return (
                        <>
                          <tr>
                            {Object.values(row).map((cell) => {
                              return (
                                <>
                                  <td>{cell}</td>
                                </>
                              );
                            })}
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="flex-space  pt-3">
                <div className="muted-md">
                  {currentPage} / {totalPages}
                </div>
                <div className="pagination-buttons">
                  <button
                    className="pagination-button"
                    onClick={handlePrevClick}
                    disabled={currentPage === 1}
                  >
                    <FaChevronLeft />
                  </button>
                  <button
                    className="pagination-button"
                    onClick={handleNextClick}
                    disabled={currentPage === totalPages}
                  >
                    <FaChevronRight />
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default PitchBasis;
