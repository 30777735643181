import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Paper } from "@mui/material";
import apis from "services";
import { DataGrid } from "@mui/x-data-grid";
import WithDrawlTable from "components/datagrid/WithDrawlTable";
import { toast } from "react-toastify";
import useNavigate from "hooks/Navigate";
import AdminNewSidebar from "components/admin/AdminNewSidebar";
import { Header } from "components";
import { useTranslation } from "react-i18next";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { setWallet } from "redux/slices/walletSlicer";
import InvestOptionsModal from "components/modals/InvestOptionsModal";
import ConfirmWithDrawlModal from "components/modals/ConfirmWithDrawlModal";
import WithdrawlReceiptModal from "components/modals/WithdrawlReceiptModal";
import KYCModal from "components/modals/KYCModal";

const WithDrawl = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [investmentModal, setInvestmentModal] = useState(false);

  const toggleKYCModal =()=>{
    setKycModal((prevState)=>!prevState)
  }


  const [kycModal, setKycModal] = useState(false);


  const [modalConfirm, setModalConfirm] = useState(false);
  const [receiptmodal, setReceiptModal] = useState(false);

  const [item, setItem] = useState({});


  const { user, user_analytics } = useSelector((store) => store.user);
  const { wallet } = useSelector((store) => store.wallet);

  const [withDrawl, setWithDrawl] = useState([]);

  const [data, setData] = useState({
    userId: user?.id,
    walletId: user?.wallet,
    amount: null,
    type: "investor"
  });
  const fetchAllWithDrawlUser = async () => {
    const res = await apis.getUserWithdrawl(user?.id);
    setWithDrawl(res?.data?.withDrawl);
  };

  const handleCancelWithDrawl = async (id) => {
    const res = await apis.cancelWithdrawl(id);
    if (res?.data?.status) {
      dispatch(setWallet(res.data.wallet));
      toast.success(res.data?.message);
      await fetchAllWithDrawlUser();
    } else toast.error(res.data?.message);
  };
  const toggleReceiptModal = (dataItem) => {
    console.log(dataItem, "dataItem")
    setItem(dataItem)
    setReceiptModal((prevState) => !prevState);

  }


  useEffect(() => {
    (async () => {
      await fetchAllWithDrawlUser();
    })();
  }, []);

  const methods = {
    navigate,
    handleCancelWithDrawl,
    toggleReceiptModal
  };





  const { rows, columns } = WithDrawlTable(withDrawl, methods);
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({ ...prevState, [name]: value }));
  };

  const toggleInvestmentModal = () =>
    setInvestmentModal((prevState) => !prevState);

  const submit = async () => {
    if(parseInt(data?.amount) >= 500){

      const res = await apis.addWithdrawl(data);
      if (res?.data?.status) {
        dispatch(setWallet(res.data.wallet));
        toast.success(res.data?.message);
        setData((prev) => ({ ...prev, amount: "" }));
        await fetchAllWithDrawlUser();
        setModalConfirm((prevState) => !prevState);
      } else {
        toast.error(res.data?.message);
        setData((prev) => ({ ...prev, amount: "" }));
        setModalConfirm((prevState) => !prevState);
      }

    }
    else{
      toast.error("The minimum withdrawal amount is 500")
    }
  };



  const toggleModal = async (e) => {
    e.preventDefault();
    if (user?.isKYCCompleted === "completed") {
      setModalConfirm((prevState) => !prevState);
    }
    else if (user?.isKYCCompleted === "signatureVerified") {
      toast.error("Your contract document submission is processing. Please wait for approval.")
    }
    else if (user?.isKYCCompleted === "progress") {
      toast.error("Your document submission is processing. Please wait for approval.")

    }
    else if (user?.isKYCCompleted === "pending") {
      toggleKYCModal();
      toast.error("Please submit the required documents before submitting a withdrawal request.")
    }
  };

  return (
    <>
      <Box className="dashboard-main">
        <AdminNewSidebar />
        <div className="dashboard-section-main">
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
            className="custom-scroll"
          >
            <Header />
            <Container maxWidth="lg" sx={{ mt: 0, mb: 4 }}>
              {/* <div className="flex-with-title">
                <h3 className="dashboard-title">{t("titles.withDrawl")}</h3>
                <Grid xs={12} sm={6} md={4} lg={3}>
                  <Paper
                  style={{width:"100%"}}
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                    }}
                    className="dashboard-box"
                  >
                    <h3 className="text-center">{t("labels.balance")}</h3>
                    <h4>{wallet?.balance.toFixed(3)}</h4>
                  </Paper>
                </Grid>
              </div> */}

              <div class="container-withdrawl">
                <Grid xs={12} sm={12} md={12} lg={12}>
                  <Paper
                    style={{ width: "100%" }}
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                    }}
                    className="dashboard-box"
                  >
                    <h3 className="text-center balance-withdrawl">
                      {t("labels.balance")}
                    </h3>
                    <h4 className="text-center balance-withdrawl">
                      {wallet?.balance?.toFixed(3) || 0}
                    </h4>
                  </Paper>
                </Grid>
                <div class="content-withdrawl">
                  <h3 className="dashboard-title">{t("titles.withDrawl")}</h3>
                </div>
              </div>

              <Form onSubmit={toggleModal} className="mx-5">
                <Row className="d-flex justify-content-center align-items-center">
                  <Col xs={12} md={12} lg={6}>
                    <Form.Group className="mb-2" controlId="title">
                      <Form.Label>{t("titles.enter_amount")}</Form.Label>
                      <Form.Control
                        required
                        type="number"
                        name="amount"
                        placeholder={t("placeholders.enter_request_withdrawl_amount")}
                        onChange={onChangeHandler}
                        value={data.amount}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <Button
                      className="lp-btn mt-4"
                      variant="outline-danger"
                      type="submit"
                    >
                      {" "}
                      {t("buttons.submit")}
                    </Button>
                  </Col>
                </Row>
              </Form>
              <Grid
                item
                container
                justifyContent="flex-end"
                className="mt-4"
                sx={{ height: "600px" }}
              >
                <DataGrid
                  rows={rows}
                  columns={columns}
                  getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                  }
                />
              </Grid>
            </Container>
          </Box>
        </div>
      </Box>
      <InvestOptionsModal
        toggleModal={toggleInvestmentModal}
        status={investmentModal}
      />
      <ConfirmWithDrawlModal
        toggleModal={toggleModal}
        status={modalConfirm}
        fetchAllWithDrawlUser={fetchAllWithDrawlUser}
        submit={submit}
        amount={data?.amount}
      />

      <WithdrawlReceiptModal
        toggleModal={toggleReceiptModal}
        status={receiptmodal}
        data={item}
      />


{user?.isKYCCompleted === "pending" && (
        <KYCModal
          toggleModal={toggleKYCModal}
          status={kycModal}
          signature={true}
        />
      )}

    </>
  );
};

export default WithDrawl;
