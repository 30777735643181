
import { useTranslation } from "react-i18next";
import {
    ImageOutlined
} from "@mui/icons-material";
import {
  Delete,
  RemoveModerator,
  Send,
  VerifiedUser,
  Visibility,
} from "@mui/icons-material";

import {Grid, IconButton, Tooltip, Typography} from "@mui/material";
import moment from "moment";

import useNavigate from "hooks/Navigate";
import utils from "utils";
import { formatDate } from "utils/formatDate";
import { TOTAL_SHARES } from "assets/constants";
const UserDetailsTable = (data=[] , tab,openImagePreview) => {
    const PATH = "https://investin-api.javatimescaffe.com/upload/"

const navigate = useNavigate();
function convert(n) {
  var sign = +n < 0 ? "-" : "",
    toStr = n.toString();
  if (!/e/i.test(toStr)) {
    return n;
  }
  var [lead, decimal, pow] = n
    .toString()
    .replace(/^-/, "")
    .replace(/^([0-9]+)(e.*)/, "$1.$2")
    .split(/e|\./);
  return +pow < 0
    ? sign +
        "0." +
        "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
        lead +
        decimal
    : sign +
        lead +
        (+pow >= decimal.length
          ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
          : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
}


    const { language, setLanguage, t } = useTranslation();
   let columns , rows ;
    if(tab === "user_details")
    {
    columns = [
        {field: 'id', headerName: 'No', width: 150, hide: false,},
        {field: 'image', headerName: t("fields.image"), width: 150, renderCell: (param) => <IconButton onClick={() => openImagePreview(utils.serverImg(param.value))}><ImageOutlined/></IconButton>},
        {field: 'email', headerName: t("fields.email"), width: 150},
        {field: 'name', headerName: t("fields.name"), width: 150},
        {field: 'nickName', headerName: t("fields.nick_name"), width: 150},
        {field: 'wallet', headerName: t("fields.WalletID"), width: 150},
        {field: 'phone', headerName: t("fields.phone"), width: 150},
        {field: 'type', headerName: t("fields.type"), width: 150},
        {field: 'createdAt', headerName: t("fields.createdAt"), width: 150},
    ];
    }
    else if(tab === "kyc_details")
  {
  columns = [
        {field: 'id', headerName: 'No', width: 150, hide: false,},
        {field: 'name', headerName: t("fields.name"), width: 150}, 
        {field: 'rfcNo', headerName: t("fields.RFC"), width: 150},
        {field: 'address', headerName: t("fields.address"), width: 250},
        {field: 'isContractSign', headerName: t("fields.contract"), width: 150},
        {
            field: "file",
            headerName: t("fields.viewFile"),
            width: 150,
            renderCell: (props) => {
              const id = props.row.file;
              return (
                   <>   
                   <a href={id} target="_blank">   <Visibility />
                     </a>
                     </>
              );
            },
          }, 
    ];
  }
  else if(tab === "investor_details")
 {
   columns = [
        {field: 'id', headerName: 'No', width: 150, hide: false,},
        {field: 'firstName', headerName: t("fields.firstName"), width: 250},
        {field: 'middleName', headerName: t("fields.middles_name"), width: 250},
        {field: 'lastName', headerName: t("fields.lastName"), width: 250},
        {field: 'passport', headerName: t("fields.passport"), width: 250},
        {field: 'passportExpireDate', headerName: t("fields.passport_expire_date"), width: 250},
        {field: 'bankAccount', headerName: t("fields.bank_account"), width: 250},
        {
            field: "fundsLegalSource",
            headerName: t("fields.fund_legal_source"),
            width: 150,
            renderCell: (props) => {
              const id = props.row.fundsLegalSource;
              return (
                   <> 
                   {id ? <>
                    <a href={`${PATH}${id}`} target="_blank">   <Visibility />
                     </a>
                   </>:<></>}  
                     </>
              );
            },
          },
        {
            field: "RFC",
            headerName: t("fields.RFC"),
            width: 150,
            renderCell: (props) => {
              const id = props.row.RFC;
            
              return (
                   <>
                   {id?.includes("RFC") ? <>
                   <a href={`${PATH}${id}`} target="_blank">   <Visibility />
                    </a>
                   </> : <>
                   <p>{id}</p>
                   </>}
                  
                     </>
              );
            },
          },
          {
            field: "CURP",
            headerName: t("fields.CURP"),
            width: 150,
            renderCell: (props) => {
              const id = props.row.CURP;
              return (
                   <>   
                   {id?.includes("CURP") ? <>
                   <a href={`${PATH}${id}`} target="_blank">   <Visibility />
                    </a>
                   </> : <>
                   <p>{id}</p>
                   </>}
                     </>
              );
            },
          },
    ];
 
}
else if(tab === "wallet_details")
{
  columns = [
       {field: 'id', headerName: 'No', width: 150, hide: false,},
       {field: 'type', headerName: t("fields.type"), width: 250},
       {field: 'amount', headerName: t("labels.dashboard_1"), width: 250},
       {field: 'shares', headerName: t("labels.Shares"), width: 250},
     
    
       {field: 'fractional', headerName: t("labels.fractional"), width: 250},
       {field: 'balance', headerName: t("labels.balance"), width: 250},
       {field: 'participation', headerName: t("labels.participant"), width: 250 ,

       renderCell: (props) => {
        const participation = props.row.participation;
        return (
             <>   
             <p>{participation} %</p>
               </>
        );
      },

       },
       {field: 'token', headerName: t("labels.token"), width: 250},
    
    
       
    ];

}
else if(tab === "profit_details")
{
  columns = [
       {field: 'id', headerName: 'No', width: 150, hide: false,},
       {field: 'profitAmount', headerName: t("labels.profitAmount"), width: 250},
       {field: 'totalProfitAmount', headerName: t("labels.totalProfitAmount"), width: 250},
       {field: 'loss', headerName: t("labels.loss"), width: 250},
       {field: 'createdAt', headerName: t("fields.createdAt"), width: 250},
  
    ];

}

if(tab === "user_details")
{
rows = data.length === 0
? []
: [
    ...data?.map((item,index) => ({
        id: index+1,
        image:item['image'],
        email: item['email'],
        name: item['name'],   
        nickName: item['nickName'],
        wallet: item['wallet'],
        phone: item['phone'],
        type: item['type'],
        createdAt: formatDate(item['createdAt']),
    }))
];
}
else if(tab === "kyc_details")
{
rows = data.length === 0
? []
: [
    ...data?.map((item,index) => ({
        id: index+1,
        name: item['name'],
        rfcNo: item['rfcNo'],   
        address: item['address'],
        isContractSign: item['isContractSign'],
        file: item['file']
    }))
];
}
else if(tab === "investor_details")
{
        
rows = data.length === 0
? []
: [
    ...data?.map((item,index) => ({
        id: index+1,
        firstName: item['firstName'],
        middleName: item['middleName'],   
        lastName: item['lastName'],
        passport: item['passport'],
        passportExpireDate: moment(item['passportExpireDate']).format('MMMM Do YYYY'),
        bankAccount: item['bankAccount'],
        fundsLegalSource: item['fundsLegalSource'],
        RFC: item['RFC'],
        CURP: item['CURP']
    }))
];
}
else if(tab === "wallet_details")
{
        
rows = data.length === 0
? []
: [
    ...data?.map((item,index) => ({
        id: index+1,
        type: item['type'],
        amount: item['amount'],
        shares: item['shares'],   
        balance: item['balance'],
        token: item['jtcToken'],
        participation: convert(item['amount'] / TOTAL_SHARES),
        fractional: item['fractionalMoney']
    }))
];
}
else if(tab === "profit_details")
{
        
rows = data.length === 0
? []
: [
    ...data?.map((item,index) => ({
        id: index+1,
        profitAmount: item['profitAmount'],
        loss: item['loss'],   
        totalProfitAmount: item['totalProfitAmount'],
        createdAt: formatDate(item['createdAt']),
       
      }))
];
}
    return {rows, columns}
}

export default UserDetailsTable
