import { Button, Col, Modal, Row } from "react-bootstrap";
import useNavigate from "hooks/Navigate";
import DocumentCard from "components/DocumentCard";
import { useEffect, useState } from "react";
import apis from "services";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { setUserInvestment } from "redux/slices/userSlice";
import { useTranslation } from "react-i18next";

const INEModal = ({ status, toggleModal,getDocumentDataUser }) => {
  const { language, setLanguage, t } = useTranslation();

  const navigate = useNavigate();
  const { user } = useSelector((store) => store.user);
const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const getDocumentData = async () => {
    try {
      setLoading(true)
      const response = await apis.getUserDocuments(user?.id)
      if (response?.data?.status) {
        console.log(response, "response")
        setData(response?.data?.documents)
        dispatch(setUserInvestment(response?.data?.analytics))   
    
      }

      setLoading(false)

    }
    catch (e) {

      setLoading(false)

      console.log(e?.message)
    }

  }

  useEffect(() => {
    getDocumentData()
  }, [status])


  return (
    <Modal
      show={status}
      onHide={toggleModal}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("documents.identification_Document")}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ fontSize: '12px' }}>
        {loading ? 
        <>
            <div className="d-flex justify-content-center align-items-center m-5">
              <CircularProgress color="error" />
            </div>
          </>
         :
         <Row>
        <Col lg={6}>
          <DocumentCard getDocumentData={getDocumentDataUser}  value={"INE_FRONT"} data={data} type={"INE (Identification Document) Front"} />
        </Col>
        <Col lg={6}>
          <DocumentCard getDocumentData={getDocumentDataUser} value={"INE_BACK"} data={data} type={"INE (Identification Document) Back"} />
        </Col>
        </Row>
   
         }
           </Modal.Body>
    </Modal>
  );
};

export default INEModal;
