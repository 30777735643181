import AdminNewSidebar from "components/admin/AdminNewSidebar";
import React, { useState } from "react";
import { Box, Container } from "@mui/material";
import { Header } from "components";
import { useTranslation } from "react-i18next";
import { Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import apis from "../../services";

export default function Dividend() {
  const { t } = useTranslation();

  const [data, setData] = useState({
    startDate: "",
    endDate: "",
    dividend: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  
  const onChangeHandler = (e) => {
    const { id, value } = e.currentTarget;
    setData((prevState) => ({ ...prevState, [id]: value }));
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    try {
      setIsLoading(true)
      const response = await apis.generateDividend(data);
      if (response) {
        toast.success(response?.data);
        setData({
          startDate: "",
          endDate: "",
          dividend: ""})
    }
      
    setIsLoading(false)
    } catch (e) {
      
    setIsLoading(false)
      console.log(`Error in generateDividend`, e);
    }
    
  };

  return (
    <>
      <Box className="dashboard-main">
        <AdminNewSidebar />
        <div className="dashboard-section-main">
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
            className="custom-scroll"
          >
            <Header />
            <Container maxWidth="sm" sx={{ mt: 0, mb: 4 }}>
              <div className="bg-light p-5">
                <h3 className=""> Dividend </h3>
                <Form onSubmit={submitHandler}>
                  <Form.Group className="mb-3" controlId="formGroupEmail">
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control
                      type="date"
                      id="startDate"
                      value={data.startDate}
                      placeholder="Enter Start Date"
                      onChange={onChangeHandler}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formGroupEmail">
                    <Form.Label>End Date</Form.Label>
                    <Form.Control
                      type="date"
                      id="endDate"
                      value={data.endDate}
                      placeholder="Enter End Date"
                      onChange={onChangeHandler}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formGroupEmail">
                    <Form.Label>How much dividend you want to give?</Form.Label>
                    <Form.Control
                      type="number"
                      id="dividend"
                      value={data.dividend}
                      placeholder="Enter Dividend amount"
                      onChange={onChangeHandler}
                      required
                    />
                  </Form.Group>
                  <div className="">
                    <Button
                      className="w-50"
                      variant="outline-danger"
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading" : "Submit"}
                    </Button>
                  </div>
                </Form>
              </div>
            </Container>
          </Box>
        </div>
      </Box>
    </>
  );
}
