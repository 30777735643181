import { Fragment } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useState } from "react";
import apis from "../../services";
import { useTranslation } from "react-i18next";

const LegalEntityForm = () => {
  const { language, setLanguage, t } = useTranslation();
  const [formData, setFormData] = useState({
    articlesIncorporation: null,
    taxID: null,
    taxDomicile: null,
    powerOfAttorney: null,
    shareHolder: null,
    electronicSignature: null,
    hasFundsLegalSource: false,
    fundsLegalSource: null,
  });

  const onChangeHandler = (e) => {
    const { id, value, files } = e.currentTarget;

    if (files) setFormData((prevState) => ({ ...prevState, [id]: files[0] }));
    else setFormData((prevState) => ({ ...prevState, [id]: value }));
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    await apis.legalForm({ ...formData, type: "legalEntity" });
  };
  return (
    <>
      <Container>
        <Form
          className="my-2"
          onSubmit={submitHandler}
          enctype="multipart/form-data"
        >
          {/* <Row>
            <Col>
              <h2 className="text-center text-uppercase my-4">
                Legal Entity Form
              </h2>
            </Col>
          </Row> */}
          <Row>
            <Col md={6} lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>{t("labels.incorporation")}</Form.Label>
                <Form.Control
                  type="file"
                  id="articlesIncorporation"
                  onChange={onChangeHandler}
                  required
                />
                <Form.Text className="text-muted"> {t("labels.suggestion")} </Form.Text>
              </Form.Group>
            </Col>

            <Col md={6} lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>{t("labels.tax_id")}</Form.Label>
                <Form.Control
                  type="file"
                  id="taxID"
                  onChange={onChangeHandler}
                  required
                />
                <Form.Text className="text-muted"> {t("labels.suggestion")} </Form.Text>
              </Form.Group>
            </Col>

            <Col md={6} lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>{t("labels.proof_tax")}</Form.Label>
                <Form.Control
                  type="file"
                  id="taxDomicile"
                  onChange={onChangeHandler}
                  required
                />
                <Form.Text className="text-muted"> {t("labels.suggestion")} </Form.Text>
              </Form.Group>
            </Col>

            <Col md={6} lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>
                {t("labels.power_of_attorney")}
                </Form.Label>
                <Form.Control
                  type="file"
                  id="powerOfAttorney"
                  onChange={onChangeHandler}
                  required
                />
                <Form.Text className="text-muted"> {t("labels.suggestion")} </Form.Text>
              </Form.Group>
            </Col>

            <Col md={6} lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>
                {t("labels.shareholder_corporate")}
                </Form.Label>
                <Form.Control
                  type="file"
                  id="shareHolder"
                  onChange={onChangeHandler}
                  required
                />
                <Form.Text className="text-muted"> {t("labels.suggestion")} </Form.Text>
              </Form.Group>
            </Col>

            <Col md={6} lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>{t("labels.electronic_signature")}</Form.Label>
                <Form.Control
                  type="file"
                  id="electronicSignature"
                  onChange={onChangeHandler}
                  required
                />
                <Form.Text className="text-muted"> {t("labels.suggestion")} </Form.Text>
              </Form.Group>
            </Col>

            <Col md={12}>
              <Form.Group className="mb-3">
                <Form.Group className="mb-3">
                  <Form.Check
                    type="checkbox"
                    label={t("labels.letter_of_funds")}
                    onChange={(e) =>
                      setFormData((prevState) => ({
                        ...prevState,
                        hasFundsLegalSource: e.target.checked,
                      }))
                    }
                  />
                </Form.Group>
              </Form.Group>
            </Col>

            {formData.hasFundsLegalSource && (
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="file"
                      id="fundsLegalSource"
                      onChange={onChangeHandler}
                      required
                    />
                    <Form.Text className="text-muted">
                      {t("labels.letter_of_source")}
                    </Form.Text>
                  </Form.Group>
                </Form.Group>
              </Col>
            )}
          </Row>
          <Row>
            <Col md={4} />
            <Col md={4}>
              <Button
                className="lp-btn my-4 w-100"
                type="submit"
                variant="outlined"
                color="error"
                fullWidth
              >
                {t("buttons.submit")}
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default LegalEntityForm;
