import React from "react";
// import { Link } from "react-router-dom";
import imageUrl from "utils/imageUrl";
import Link from 'hooks/Link';
const BlogCardMain = ({ id, title, img, desc, time, author, authorImg, slug }) => {

  function timeSince(date) {
    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  }

  return (
    <>
      <div className="blog-card-wrapper" key={id}>
        <Link to={`/blog/${slug}`}>
          <div className="blog-card">
            <div className="blog-card-img">
              <div className="blog-card-img-inner w-100 h-100">
                <img src={img} alt="" />
                {/* <img src={'/images/team_5.jpg'} alt="" /> */}
              </div>
            </div>
            <div className="blog-card-body-wrapper">
              <div>
                <p className="blog-card-title line-ellipse">{title}</p>
                <p className="card-desc" id="test">
                  {desc
                    ?.split("\\n")
                    .map((text, i) => (i ? [<br />, text] : text))}
                </p>

                <div className="blog-card-footer mt-2">
                  <div className="left">
                    <div className="icon-img">
                      <img
                        src={authorImg}
                        onError={(e) =>
                          (e.currentTarget.src = imageUrl("placeholder.png"))
                        }
                        alt={title}
                      />
                    </div>
                    <div className="mt-2">
                      <p className="card-person">{author}</p>
                      <p className="card-time">{timeSince(new Date(time))} ago</p>
                    </div>
                  </div>
                  <div className="right">
                    <img src="/images/curve-arrow.png" alt="" />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default BlogCardMain;
